<div class="main-content work-q-content" [formGroup]="form">
    <app-overlay *ngIf="isOverlayLoading"></app-overlay>

    <kendo-window [minWidth]="1200" [minHeight]="725"
        title="Recipe Number : {{recipeNumber}} , Recipe Name : {{recipeName}}" *ngIf="windowOpened" (close)="close()">
        <div>
            <table class="recipe-detail-table">
                <tr>
                    <td>
                        <button kendoButton (click)="startRampActionClick('Ramping-Up')" style="float: right;" title="Start ramp-up"
                            [disabled]="!rampUpTimer" [hidden]="!enableStartRampUp" [themeColor]="'success'">
                            <i class="intelicon-start-outlined " aria-hidden="true"></i>
                            Start Ramp-Up
                            <kendo-loader *ngIf="!rampUpTimer" [themeColor]="'warning'" size="medium"></kendo-loader>
                        </button>
                    </td> <!-- (click)="startRampUpClick()" startRampActionClick('Ramping-Up') -->
                    <td>
                        <button kendoButton  (click)="startRampActionClick('Ramping-Down')"
                            style="float: left;" title="Start ramp-down" [disabled]="!rampUpTimer"
                            [hidden]="!enableStartRampDown" [themeColor]="'success'">
                            <i class="intelicon-start-outlined " aria-hidden="true"></i>
                            Start Ramp-Down
                            <kendo-loader *ngIf="!rampUpTimer" [themeColor]="'warning'" size="medium"></kendo-loader>
                        </button> <!-- (click)="startRampDownClick()" startRampActionClick('Ramping-Down') -->
                    </td>
                    <td>
                        <button kendoButton (click)="reportProblemsClick()" style="float: right;"
                            title="Report problems to recipe owner while system ramp-up" [hidden]="isExecutionComplete">
                            <i class="intelicon-email-message-outlined" aria-hidden="true"></i> Report Problem
                        </button>
                    </td>
                    <td>
                        <kendo-label text="Expert Mode" style="float: right;">
                            <kendo-switch formControlName="modelWindowswitch" style="width: 55px;" (value)="true" [(ngModel)]="isExpertMode"
                                (valueChange)="onSwitchChange($event)" [hidden]="isEmpty"></kendo-switch>
                        </kendo-label>
                    </td>
                </tr>
            </table>
        </div>
        <br>
        <div *ngIf="buildingBlockData">
            <kendo-panelbar>
                <kendo-panelbar-item *ngFor="let item of buildingBlockData" title="Building Blocks" [expanded]="false">
                    <ng-template kendoPanelBarItemTitle>
                        <div style="text-align: right;">
                            {{item.buildingBlockName}} 
                            <i *ngIf="getProgress(item.id)==-1" class="intelicon-start-solid" style="color:#d74654;" aria-hidden="true"></i>
                            <i *ngIf="getProgress(item.id)== 0" class="intelicon-in-progress-not-completed-solid" style="color:#fdb913;" aria-hidden="true"></i>
                            <i *ngIf="getProgress(item.id)== 1" class="intelicon-passed-completed-solid" style="color:#92c353;" aria-hidden="true"></i>
                        </div>
                    </ng-template>

                    <ng-template kendoPanelBarContent>
                        <kendo-floatingactionbutton
                            *ngIf="mode=='EXECUTION STARTED'"
                            title=" Save Checklist Progress"
                            iconClass="intelicon-save-outlined"
                            [offset]="offset_save"
                            [align]="position"
                            [themeColor]="'primary'"
                            style="z-index: 9999;"
                            text="Save Checklist Progress"
                            (click)="saveChecklistClick()"
                        ></kendo-floatingactionbutton>
                        <kendo-floatingactionbutton
                            *ngIf="mode=='EXECUTION STARTED'"
                            title="  Update New HSD Comment"
                            iconClass="intelicon-comment-outlined"
                            [offset]="offset"
                            [align]="position"
                            (click)="toggleComment()"
                            [themeColor]="popup_color"
                            style="z-index: 9999;"
                            text="Update HSD Comment"
                        ></kendo-floatingactionbutton>
                        <div *ngIf="mode=='EXECUTION STARTED'" class="fixed-window">
                            <table class="floating-window" *ngIf="popup_comment">
                                <tr>
                                    <td style="width:6px"></td>
                                    <td style="width:80%">
                                        <kendo-formfield class="col" orientation="horizontal">
                                            <kendo-label [for]="forComment" text="Comment"></kendo-label>
                                            <kendo-textarea #forComment formControlName="comment"
                                             resizable="vertical" [disabled]="hsdId==''"></kendo-textarea>
                                        </kendo-formfield>
                                    </td>
                                    <td style="width:20%">
                                        <button kendoButton [themeColor]="'primary'"
                                            (click)="updateCommentClick()" title="Update Comments in HSD Ticket" [disabled]="hsdId==''">
                                            <!-- <i class="intelicon-user-approved-selected-outlined"
                                                aria-hidden="true"></i>Update Comment -->
                                            <i class="intelicon-comment-outlined "
                                                aria-hidden="true"></i> Update
                                        </button>
                                    </td>


                                </tr>
                                <br>
                                <tr>
                                    <button kendoButton [themeColor]="'success'"
                                        (click)="submitAndCompleteClick()" title="Submit and Complete"
                                        [hidden]="!enableSubmitAndComplete">
                                        <i class="intelicon-passed-completed-outlined"
                                            aria-hidden="true"></i> Submit and Complete
                                    </button>
                                </tr>

                            </table>

                        </div>
                        <!-- <div *ngIf="mode=='EXECUTION STARTED'" style="height: 150px;"></div> -->
                        <div>
                            <a style="padding-left: 6px;" *ngIf="item.content" [href]="fileUrl+item.content" target="_new">Open PDF (new tab)</a>
                            <table class="recipe-detail-table">
                                <!--removed option from grid to fix grid collapsing issue
                                    (selectionChange)="checkListGridSelectionChange($event)"-->
                                <kendo-grid #gridCheckList="kendoGrid" [kendoGridBinding]="checkListGridData[item.id]"
                                    [style.height.%]="100" [resizable]="true" [filter]="filter"
                                    (filterChange)="checkListGridfilterChange($event)" [sortable]="{
                                allowUnsort: true,
                                mode: 'multiple'}" [pageable]="{buttonCount: 3, pageSizes: [20, 25, 30]}"
                                    [pageSize]="20" [reorderable]="true" [selectable]="true"
                                    style="width: 100%;"
                                    [loading]="checkListGridLoading">
                                    <kendo-grid-column field='name' title='Instruction' [width]="90"
                                        headerClass="headerStyle" class="rowStyle">
                                        <ng-template kendoGridCellTemplate let-dataItem>
                                            <span *ngIf="dataItem.type.toLowerCase()==='header'"
                                                [ngStyle]="{'font-weight':'bold'}">{{"H: " + dataItem.name}}</span>
                                            <span *ngIf="dataItem.type.toLowerCase()!='header' && dataItem.isRequired ===true"
                                                [ngStyle]="{'color':'red'}">{{"*"}}</span>
                                            <span *ngIf="dataItem.type.toLowerCase()!='header'">{{dataItem.name}}</span>
                                        </ng-template>
                                    </kendo-grid-column>
                                    <kendo-grid-column field="isRequired" title="Availability" [width]="10">
                                        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                                            <div *ngIf="dataItem.type.toLowerCase()!='header'">
                                                <strong><input type="checkbox"
                                                        (change)="areAllRequiredChecked($event)"
                                                        [(ngModel)]="dataItem.isChecked"
                                                        [ngModelOptions]="{standalone: true}"
                                                        [disabled]="isExecutionComplete||enableStartRampUp" /></strong>
                                            </div>
                                        </ng-template>
                                    </kendo-grid-column>
                                </kendo-grid>

                            </table>
                            <table id="wrapper" [style.display]="isExpertMode ? 'none' :  'block'">
                                <tr>
                                    <div>
                                        <span style="text-align: center;" *ngIf="checkListContentLoading[item.id].loaded">
                                            <div style="align-items: center;">
                                                <kendo-loader type="converging-spinner" size="medium"> </kendo-loader>
                                                <p style="font-size: 20px;" >Loading Document...</p>
                                            </div>

                                        </span>
                                        <pdf-viewer style="width: 1400px; height: 800px"
                                            [src]="{url: fileUrl+item.content}" [render-text]="true"
                                            [original-size]="false"
                                            (after-load-complete)="checkListContentLoading[item.id].loaded=false"
                                            (on-progress)="onProgress($event, item.id)">
                                        </pdf-viewer>
                                    </div>
                                </tr>
                            </table>
                        </div>
                    </ng-template>
                </kendo-panelbar-item>
            </kendo-panelbar>
        </div>
        <table>
            <tr>
                <button class="btn btn-primary btn-sm button" type="button" (click)="submitAndCompleteClick()"
                    title="Save" [hidden]="true">
                    <i class="intelicon-user-approved-selected-outlined" aria-hidden="true"></i>Save
                </button>

                <button kendoButton [themeColor]="'success'" (click)="submitAndCompleteClick()"
                    title="Submit and Complete" [hidden]="!enableSubmitAndComplete">
                    <i class="intelicon-passed-completed-outlined" aria-hidden="true"></i>Submit and Complete
                </button>
            </tr>
        </table>
    </kendo-window>
    <kendo-dialog *ngIf="alertDialogOpen" (close)="alertDialogClose()" [minWidth]="150" [width]="350" style="z-index: 99999;">
        <kendo-dialog-titlebar>
            <div>
                <i class="intelicon-passed-completed-outlined" aria-hidden="true"></i> Information: {{workqNumber}}
            </div>
        </kendo-dialog-titlebar>
        <p style="margin: 30px; text-align: center;">
            <span [innerHTML]="alertDialogMessage"></span>
        </p>
        <kendo-dialog-actions>
            <button type="button" class="k-button k-primary" (click)="alertDialogAction()">OK</button>
        </kendo-dialog-actions>
    </kendo-dialog>
    <h2>Work-Q</h2>
    <kendo-splitter orientation="vertical" [style.height.%]="100">
        <kendo-splitter-pane>
            <kendo-splitter>
                <!--This Split Pane is for the Left Side-->
                <kendo-splitter-pane [collapsible]="true">
                    <div class="pane-content">
                        <table class="search-table">
                            <tr>
                                <td class="search-table-box">
                                    <!-- <kendo-dropdownlist [data]="wordSearchFilterTypes" [value]="'Search By'"
                                        (valueChange)="wordSearchValueChange($event)" class="filter-type">
                                    </kendo-dropdownlist> -->
                                    <kendo-dropdownlist [data]="wordSearchFilterTypes" [value]="searchByValue"
                                        (valueChange)="wordSearchValueChange($event)" class="filter-type">
                                    </kendo-dropdownlist>
                                    <button *ngIf="wordSearchFilterSelected=='all' || wordSearchFilterSelected=='All'" class="btn btn-outline-primary btn-sm" type="button"
                                        (click)="toggleFilter();" Tooltip="Show/Hide Filter Field">
                                        <i class="intelicon-vertical-menu" aria-hidden="true"></i>
                                    </button>
                                    <!-- <input type="text" class="form-control form-control-sm search-box"
                                        data-toggle="tooltip"
                                        title="Provide only WorkQ# or HSD# or Recipe# when performing search"
                                        placeholder="Search By WorkQ#, HSD#, Recipe#" aria-label="Search"
                                        disabled="true" aria-describedby="basic-addon2" id="searchBox"  #searchBox/> -->
                                        <input type="text" class="form-control form-control-sm search-box"
                                        data-toggle="tooltip"
                                        title="Provide only WorkQ# or HSD# or Recipe# when performing search"
                                        placeholder="Search By WorkQ#, HSD#, Recipe#" aria-label="Search"
                                        aria-describedby="basic-addon2" id="searchBox" #searchBox [disabled]="isSearchBoxDisabled" [value]="txtSearch"/>
                                </td>
                                <td class="search-table-margin"></td>
                                <td class="search-table-button">
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-primary btn-sm search-button" type="button"
                                            kendoButton (click)="search(true, false)">

                                            <i class="intelicon-search" aria-hidden="true"></i> Search

                                        </button>
                                    </div>

                                </td>
                            </tr>
                        </table>
                        <br>
                        <table *ngIf="wordSearchFilterSelected=='all' || wordSearchFilterSelected=='All'" class="advance-search-table" [style.display]="isFilterVisible ? 'block' : 'none'">
                            <tr>
                                <td class="search-table-label-box" style="text-align:right">
                                    <kendo-label [for]="forLab" text="Site:" class="label"></kendo-label>
                                </td>
                                <td class="search-table-text-box" style="text-align:right">
                                    <kendo-dropdownlist #forLab [data]="rmLabList" id="rmLabDropdownList"
                                        [textField]="'lab'" [valueField]="'lab'" [defaultItem]="defaultLab"
                                        [value]="selectedLab" (valueChange)="lstRmLabValueChange($event)"
                                        class="filter-dropdown">
                                    </kendo-dropdownlist>
                                </td>
                                <td class="advance-search-table-label-box" style="text-align:right">
                                    <kendo-label [for]="forHsdOwner" text="HSD Owner" class="label"></kendo-label>
                                </td>
                                <td class="advance-search-table-text-box" style="text-align:left">
                                    <kendo-dropdownlist #forHsdOwner [data]="rmHsdOwnersList"
                                        [textField]="'displayName'" [valueField]="'jobTitle'"
                                        [defaultItem]="defaultOwner" (valueChange)="lstHSDOwnersValueChange($event)"
                                        class="filter-dropdown" [loading]="ownerIsLoading">
                                    </kendo-dropdownlist>
                                </td>
                            </tr>
                            <tr>
                                <td class="advance-search-table-label-box" style="text-align:right">
                                    <kendo-label [for]="forFromDate" text="From Date" class="label"></kendo-label>
                                </td>
                                <td class="advance-search-table-text-box" style="text-align:left">
                                    <kendo-datepicker #forFromDate [(value)]="fromDate" class="filter-dropdown">
                                    </kendo-datepicker>
                                </td>
                                <td class="advance-search-table-label-box" style="text-align:right">
                                    <kendo-label [for]="forToDate" text="To Date" class="label"></kendo-label>
                                </td>
                                <td class="advance-search-table-text-box" style="text-align:left">
                                    <kendo-datepicker #forToDate [(value)]="toDate" class="filter-dropdown">
                                    </kendo-datepicker>
                                </td>
                            </tr>
                        </table>
                        <div style="text-align: right; margin-bottom: 10px;">
                            <button kendoButton class="btn btn-outline-primary btn-sm" type="button"
                                (click)="exportToExcel(gridSearchWorkQ)" [hidden]="!exportData">
                                <i class="intelicon-ms-excel-outlined" aria-hidden="true"></i> Export to Excel
                            </button>

                        </div>
                        <kendo-grid #gridSearchWorkQ="kendoGrid" [kendoGridBinding]="searchGridData"
                            [style.height.%]="100" [resizable]="true" [columnMenu]="true" [filter]="filter"
                            filterable="menu" [filterable]="true" (filterChange)="searchGridfilterChange($event)"
                            [sortable]="{
                            allowUnsort: true,
                            mode: 'multiple'}" [pageable]="{buttonCount: 3, pageSizes: [20, 25, 30]}" [pageSize]="20"
                            [reorderable]="true" [selectable]="true"
                            (selectionChange)="gridWorkqSelectionChange($event)" [loading]="workqGridLoading" kendoGridSelectBy
                            [(selectedKeys)]="mySelection">
                            <ng-template kendoGridLoadingTemplate>
                                <div style="text-align: center;align-items: center; margin-top: 100px;">
                                  <kendo-loader type="converging-spinner" size="large"></kendo-loader>
                                </div>
                            </ng-template>
                            <kendo-grid-column field='workqNumber' title='WorkQ#' [width]='125'
                                headerClass="headerStyle" class="rowStyle">
                                <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter"
                                    let-filterService="filterService">
                                    <multicheck-filter [isPrimitive]="true" [field]="column.field"
                                        [filterService]="filterService" [currentFilter]="filter"
                                        [data]="distinctPrimitive(column.field)"></multicheck-filter>
                                </ng-template>
                            </kendo-grid-column>
                            <kendo-grid-column field='hsdId' title='HSD#' [width]="125" headerClass="headerStyle"
                                class="rowStyle">
                                <ng-template kendoGridCellTemplate let-dataItem>
                                    <u><a href="javascript:void(0)" (click)="hsdCellEvent(dataItem.hsdId)"
                                            style="color: blue">{{dataItem.hsdId}}</a></u>
                                </ng-template>
                                <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter"
                                    let-filterService="filterService">
                                    <multicheck-filter [isPrimitive]="true" [field]="column.field"
                                        [filterService]="filterService" [currentFilter]="filter"
                                        [data]="distinctPrimitive(column.field)"></multicheck-filter>
                                </ng-template>
                            </kendo-grid-column>
                            <kendo-grid-column field='mode' title='Mode' [width]="190" headerClass="headerStyle"
                                class="rowStyle">
                                <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter"
                                    let-filterService="filterService">
                                    <multicheck-filter [isPrimitive]="true" [field]="column.field"
                                        [filterService]="filterService" [currentFilter]="filter"
                                        [data]="distinctPrimitive(column.field)"></multicheck-filter>
                                </ng-template>
                            </kendo-grid-column>
                            <kendo-grid-column field='category' title='Category' [width]="190" headerClass="headerStyle"
                            class="rowStyle">
                                <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter"
                                    let-filterService="filterService">
                                    <multicheck-filter [isPrimitive]="true" [field]="column.field"
                                        [filterService]="filterService" [currentFilter]="filter"
                                        [data]="distinctPrimitive(column.field)"></multicheck-filter>
                                </ng-template>
                            </kendo-grid-column>
                            <kendo-grid-column field='labs' title='Sites' [width]="85" headerClass="headerStyle"
                                class="rowStyle">
                                <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter"
                                    let-filterService="filterService">
                                    <multicheck-filter [isPrimitive]="true" [field]="column.field"
                                        [filterService]="filterService" [currentFilter]="filter"
                                        [data]="distinctPrimitive(column.field)"></multicheck-filter>
                                </ng-template>
                            </kendo-grid-column>
                            <kendo-grid-column field='hsdTitle' title='HSD Title' [width]="240"
                                headerClass="headerStyle" class="rowStyle">
                                <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter"
                                    let-filterService="filterService">
                                    <multicheck-filter [isPrimitive]="true" [field]="column.field"
                                        [filterService]="filterService" [currentFilter]="filter"
                                        [data]="distinctPrimitive(column.field)"></multicheck-filter>
                                </ng-template>
                            </kendo-grid-column>
                            <kendo-grid-column field='hsdOwnerName' title='HSD Owner' [width]="240"
                                headerClass="headerStyle" class="rowStyle">
                                <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter"
                                    let-filterService="filterService">
                                    <multicheck-filter [isPrimitive]="true" [field]="column.field"
                                        [filterService]="filterService" [currentFilter]="filter"
                                        [data]="distinctPrimitive(column.field)"></multicheck-filter>
                                </ng-template>
                            </kendo-grid-column>
                            <kendo-grid-column field='hsdPriority' title='HSD Priority' [width]="145"
                                headerClass="headerStyle" class="rowStyle">
                                <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter"
                                    let-filterService="filterService">
                                    <multicheck-filter [isPrimitive]="true" [field]="column.field"
                                        [filterService]="filterService" [currentFilter]="filter"
                                        [data]="distinctPrimitive(column.field)"></multicheck-filter>
                                </ng-template>
                            </kendo-grid-column>
                            <kendo-grid-column field='hsdReason' title='HSD Reason' [width]="150"
                                headerClass="headerStyle" class="rowStyle">
                                <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter"
                                    let-filterService="filterService">
                                    <multicheck-filter [isPrimitive]="true" [field]="column.field"
                                        [filterService]="filterService" [currentFilter]="filter"
                                        [data]="distinctPrimitive(column.field)"></multicheck-filter>
                                </ng-template>
                            </kendo-grid-column>
                            <kendo-grid-column field='createdByName' title='Created By' [width]="240"
                                headerClass="headerStyle" class="rowStyle">
                                <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter"
                                    let-filterService="filterService">
                                    <multicheck-filter [isPrimitive]="true" [field]="column.field"
                                        [filterService]="filterService" [currentFilter]="filter"
                                        [data]="distinctPrimitive(column.field)"></multicheck-filter>
                                </ng-template>
                            </kendo-grid-column>
                            <kendo-grid-column field='createdDate' title='Case Creation Date' type='date' [width]="180"
                                headerClass="headerStyle" class="rowStyle">
                                <ng-template kendoGridCellTemplate let-dataItem>
                                    {{ dataItem.createdDate | date: 'MM/dd/yyyy hh:mm a'}}
                                </ng-template>
                            </kendo-grid-column>
                            <kendo-grid-column field='daysInWip' title='Days in WIP' type='text' [width]="150"
                                headerClass="headerStyle" class="rowStyle">
                                <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter"
                                    let-filterService="filterService">
                                    <multicheck-filter [isPrimitive]="true" [field]="column.field"
                                        [filterService]="filterService" [currentFilter]="filter"
                                        [data]="distinctPrimitive(column.field)"></multicheck-filter>
                                </ng-template>
                            </kendo-grid-column>
                            <kendo-grid-column field='recipeNumber' title='Recipe Number' type='text' [width]="150"
                                headerClass="headerStyle" class="rowStyle">
                                <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter"
                                    let-filterService="filterService">
                                    <multicheck-filter [isPrimitive]="true" [field]="column.field"
                                        [filterService]="filterService" [currentFilter]="filter"
                                        [data]="distinctPrimitive(column.field)"></multicheck-filter>
                                </ng-template>
                            </kendo-grid-column>
                            <kendo-grid-column field='recipeName' title='Recipe Name' type='text' [width]="150"
                                headerClass="headerStyle" class="rowStyle">
                                <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter"
                                    let-filterService="filterService">
                                    <multicheck-filter [isPrimitive]="true" [field]="column.field"
                                        [filterService]="filterService" [currentFilter]="filter"
                                        [data]="distinctPrimitive(column.field)"></multicheck-filter>
                                </ng-template>
                            </kendo-grid-column>
                            <kendo-grid-command-column [width]="80" title='Delete' type='text' [hidden]="!isUserAdmin()">
                                <ng-template kendoGridCellTemplate let-dataItem>
                                    <button kendoGridEditCommand [primary]="true" type="button"
                                        (click)="deleteWorkQ(dataItem.id, dataItem.workqNumber, dataItem.createdByWwid, dataItem.hsdId)">
                                        <i class="intelicon-trash-outlined" aria-hidden="true"></i>
                                    </button>
                                </ng-template>
                            </kendo-grid-command-column>

                            <kendo-grid-excel fileName="workq_search_results.xlsx"
                                [fetchData]="allRequestData"></kendo-grid-excel>
                        </kendo-grid>

                    </div>
                </kendo-splitter-pane>

                <!--This Split Pane is for the 3 Tabs [collapsible]="true" -->
                <kendo-splitter-pane  size="50%">
                    <kendo-tabstrip>
                        <kendo-tabstrip-tab [title]="'Update HSD'"  [selected]="!isWorkQLoaded"> <!--[selected]="!isWorkQLoaded"-->
                            <ng-template kendoTabContent>
                                <div *ngIf="isDetailsLoading" style="text-align: center;align-items: center; margin-top: 20px;">
                                    <kendo-loader type="converging-spinner" size="large"></kendo-loader>
                                </div>
                                <table class="recipe-detail-table" [style.display]="!isEmpty ? 'block' : 'none'">
                                    <tr>
                                        <td>
                                            <kendo-label style="display:inline-block" text="HSD# : {{hsdId}}"
                                                [ngbTooltip]="'HSD# of the selected Work Q'"></kendo-label>
                                        </td>

                                    </tr>
                                    <tr>
                                        <td style="width:420px">
                                            <kendo-formfield class="col" orientation="horizontal">
                                                <kendo-label [for]="forComment" text="Comment" ></kendo-label>
                                                <kendo-textarea #forComment formControlName="comment"
                                                    style="width: 100%;" resizable="vertical" [disabled]="hsdId==''"></kendo-textarea>
                                            </kendo-formfield>
                                        </td>
                                        <td>
                                            <button kendoButton [themeColor]="'primary'"
                                                (click)="updateCommentClick()" title="Update Comment" [disabled]="hsdId==''">
                                                <!-- <i class="intelicon-user-approved-selected-outlined"
                                                    aria-hidden="true"></i>Update Comment -->
                                                <i class="intelicon-comment-outlined "
                                                    aria-hidden="true"></i> Update
                                            </button>
                                        </td>

                                    </tr>
                                    <br>
                                    <tr>
                                        Assign Owner
                                    </tr>
                                    <tr>
                                        <td style="width:420px">
                                            <!-- <div class="col" style="font-variant-caps: small;">
                                                Assign owner to {{userName}}
                                            </div> -->
                                            <kendo-formfield class="col" orientation="horizontal" >
                                                <!-- <kendo-label [for]="forOwnerName" text="Assign Owner"> </kendo-label> -->
                                                <kendo-multicolumncombobox #forOwnerName [data]="allOwnerData"
                                                    [textField]="'displayName'" [(ngModel)]="selectedforHSDOwner" 
                                                    [valueField]="'id'"  (valueChange)="onOwnerValueChange($event)"
                                                    [kendoDropDownFilter]="{
                                                        operator: 'contains',
                                                        fields: ['surname', 'givenName', 'jobTitle', 'mail']
                                                      }"
                                                    [placeholder]="'Employee Last Name, First or Email or WWID...'"
                                                    formControlName="owner">
                                                    <kendo-combobox-column [field]="'surname'" [title]="'Last Name'"
                                                        [width]="200">
                                                    </kendo-combobox-column>
                                                    <kendo-combobox-column [field]="'givenName'" [title]="'First Name'"
                                                        [width]="200">
                                                    </kendo-combobox-column>
                                                    <kendo-combobox-column [field]="'jobTitle'"
                                                        [title]="'Employee WWID'" [width]="200">
                                                    </kendo-combobox-column>
                                                    <kendo-combobox-column [field]="'mail'" [title]="'Employee Email'"
                                                        [width]="400">
                                                    </kendo-combobox-column>
                                                </kendo-multicolumncombobox>
                                            </kendo-formfield>
                                        </td>

                                        <td>
                                            <button kendoButton [themeColor]="'primary'"
                                                (click)="updateOwnerClick()" title="Update Owner" [disabled]="hsdId==''||!isAssignable">
                                                <kendo-loader *ngIf="isAssignLoading" size="medium" [themeColor]="'success'" [type]="'pulsing'"> </kendo-loader>
                                                <i class="intelicon-user-approved-selected-outlined"
                                                    aria-hidden="true"></i> Assign
                                            </button>
                                        </td>
                                        <td *ngIf="!isWorkQLoaded">
                                            <i class="intelicon-alert-outlined" aria-hidden="true" style="color: red;font-size: small;">
                                                HSD Owner required.
                                            </i>
                                        </td>
                                    </tr>
                                    <tr>
                                        <br>
                                    </tr>
                                    <tr>
                                        <td>
                                            <kendo-label text="Reason" ></kendo-label>
                                            <kendo-dropdownlist class = "dropdownlist_reason" [data]="reasonList" [value]="hsdReason"  (valueChange)="hsdReasonValueChange($event)"></kendo-dropdownlist>
                                        </td>

                                        <td>
                                            <button kendoButton [themeColor]="'primary'"
                                                 (click)="updateHsdReasonClick()" style="margin-top:25px;" #reasonbutton [disabled]="isReasonButtonDisabled" >
                                                <i class="intelicon-check" aria-hidden="true"></i> Update 
                                            </button>
                                        </td>

                                    </tr>
                                    <tr>
                                        <br>
                                        <br>
                                    </tr>
                                </table>

                            </ng-template>
                        </kendo-tabstrip-tab>

                        <kendo-tabstrip-tab [title]="'Work-Q Details'" [disabled]="!isWorkQLoaded" [selected]="isWorkQLoaded"> <!--[selected]="isWorkQLoaded"-->
                            <ng-template kendoTabContent>

                                <div class="pane-content">
                                    <!-- <div *ngIf="!isWorkQLoaded">
                                        <p>Please assign a HSD owner via "<b>Update HSD</b>" for this Work-Q to proceed</p>
                                    </div> -->
                                    <div *ngIf="isWorkQLoaded">
                                        <table class="recipe-detail-table" >
                                            <tr>
                                                <td>
                                                    <button kendoButton (click)="open()" style="margin:10px;"
                                                        [hidden]="isEmpty">
                                                        <i class="intelicon-expand" aria-hidden="true"></i> Open window
                                                    </button>
                                                </td>
                                                <td>
                                                    <button kendoButton (click)="startRampActionClick('Ramping-Up')"
                                                        style="float: right;" title="Start ramp-up" [disabled]="!rampUpTimer"
                                                        [hidden]="!enableStartRampUp" [themeColor]="'success'">
                                                        <i class="intelicon-start-outlined " aria-hidden="true"></i>
                                                        Start Ramp-Up
                                                        <kendo-loader *ngIf="!rampUpTimer" [themeColor]="'warning'" size="medium"></kendo-loader>
                                                    </button> <!-- (click)="startRampUpClick()" startRampActionClick('Ramping-Up') -->
                                                </td>
                                                <td>
                                                    <button kendoButton  (click)="startRampActionClick('Ramping-Down')"
                                                        style="float: left;" title="Start ramp-down" [disabled]="!rampUpTimer"
                                                        [hidden]="!enableStartRampDown" [themeColor]="'success'">
                                                        <i class="intelicon-start-outlined " aria-hidden="true"></i>
                                                        Start Ramp-Down
                                                        <kendo-loader *ngIf="!rampUpTimer" [themeColor]="'warning'" size="medium"></kendo-loader>
                                                    </button> <!-- (click)="startRampDownClick()" startRampActionClick('Ramping-Down') -->
                                                </td>
                                                <td>
                                                    <button kendoButton (click)="reportProblemsClick()"
                                                        style="float: right;"
                                                        title="Report problems to recipe owner while system ramp-up/ramp-down"
                                                        [hidden]="isExecutionComplete">
                                                        <i class="intelicon-email-message-outlined"
                                                            aria-hidden="true"></i> Report Problem
                                                    </button>
                                                </td>
                                                <td>
                                                    <kendo-label text="Expert Mode " style="float: center;"
                                                        [hidden]="isEmpty">
                                                        <kendo-switch formControlName="switch" style="width: 55px;"
                                                            (value)="true" (valueChange)="onSwitchChange($event)" [(ngModel)]="isExpertMode"
                                                            [hidden]="isEmpty"></kendo-switch>
                                                    </kendo-label>
                                                </td>
                                            </tr>
                                            <tr>

                                                <td>
                                                    <kendo-label style="display:inline-block"
                                                        text="Recipe Number: {{recipeNumber}}"
                                                        [ngbTooltip]="'Recipe Number of the selected Work Q'"></kendo-label>
                                                </td>
                                                <td>
                                                </td>
                                                <td>
                                                    <kendo-label style="display:inline-block"
                                                        text="Recipe Name: {{recipeName}}"
                                                        [ngbTooltip]="'Recipe Name of the selected Work Q'"></kendo-label>
                                                </td>
                                                <td>
                                                </td>
                                            </tr>

                                            <tr>

                                            </tr>
                                        </table>
                                        <div [hidden]="!showLmMessage">
                                            <kendo-label
                                                text="Note: Please fill in all the mandatory fields in Lab Manager tab to complete the Consumption Case."
                                                style="color:red; font-weight: bold; font-size: 16px;">
                                        
                                            </kendo-label>
                                        </div>
                                        <!---->

                                        <div *ngIf="isDetailsLoading" style="text-align: center;align-items: center; margin-top: 20px;">
                                            <kendo-loader type="converging-spinner" size="large"></kendo-loader>
                                        </div>
                                        <!-- <div *ngIf="mode=='EXECUTION STARTED'" style="height:150px"></div> -->
                                        <kendo-panelbar>

                                            <kendo-panelbar-item *ngFor="let item of buildingBlockData"
                                                title="Building Blocks" [expanded]="false">

                                                <ng-template kendoPanelBarItemTitle>
                                                    <!-- <div>
                                                        {{item.buildingBlockName}}
                                                        <i  class="intelicon-start-solid" [ngStyle]="{'color': getProgress(item.id)===-1 ? '#d74654' : '#eff0ff'}" aria-hidden="true"></i>
                                                        <i  class="intelicon-in-progress-not-completed-solid" [ngStyle]="{'color': getProgress(item.id)===0 ? '#fdb913' : '#eff0ff'}" aria-hidden="true"></i>
                                                        <i  class="intelicon-passed-completed-solid" [ngStyle]="{'color': getProgress(item.id)===1 ? '#92c353' : '#eff0ff'}" aria-hidden="true"></i>
                                                    </div> -->
                                                    
                                                    <div style="text-align: right;">
                                                        {{item.buildingBlockName}} 
                                                        <i *ngIf="getProgress(item.id)==-1" class="intelicon-start-solid" style="color:#d74654;" aria-hidden="true"></i>
                                                        <i *ngIf="getProgress(item.id)== 0" class="intelicon-in-progress-not-completed-solid" style="color:#fdb913;" aria-hidden="true"></i>
                                                        <i *ngIf="getProgress(item.id)== 1" class="intelicon-passed-completed-solid" style="color:#92c353;" aria-hidden="true"></i>
                                                    </div>
                                                </ng-template>

                                                <ng-template kendoPanelBarContent>
                                                    
                                                    <kendo-floatingactionbutton
                                                        *ngIf="mode=='EXECUTION STARTED'"
                                                        title=" Save Checklist Progress"
                                                        iconClass="intelicon-save-outlined"
                                                        [offset]="offset_save"
                                                        [align]="position"
                                                        [themeColor]="'primary'"
                                                        style="z-index: 9999;"
                                                        text="Save Checklist Progress"
                                                        (click)="saveChecklistClick()"
                                                    ></kendo-floatingactionbutton>
                                                    <kendo-floatingactionbutton
                                                        *ngIf="mode=='EXECUTION STARTED'"
                                                        title="  Update New HSD Comment"
                                                        iconClass="intelicon-comment-outlined"
                                                        [offset]="offset"
                                                        [align]="position"
                                                        (click)="toggleComment()"
                                                        [themeColor]="popup_color"
                                                        style="z-index: 9999;"
                                                        text="Update HSD Comment"
                                                    ></kendo-floatingactionbutton>

                                                    
                                                    <div *ngIf="mode=='EXECUTION STARTED'" class="fixed-window" >

                                                        <table class="floating-window" *ngIf="popup_comment">
                                                            <tr>
                                                                <td style="width:6px"></td>
                                                                <td style="width:60%">
                                                                    <kendo-formfield class="col" orientation="horizontal">
                                                                        <kendo-label [for]="forComment" text="Comment" > </kendo-label>
                                                                        <kendo-textarea #forComment formControlName="comment"
                                                                            style="width: 100%;" resizable="vertical" [disabled]="hsdId==''"></kendo-textarea>
                                                                    </kendo-formfield>
                                                                </td>
                                                                <td style="width:40%">
                                                                    <button kendoButton [themeColor]="'primary'"
                                                                        (click)="updateCommentClick()" title="Update Comments in HSD Ticket" [disabled]="hsdId==''">
                                                                        <!-- <i class="intelicon-user-approved-selected-outlined"
                                                                            aria-hidden="true"></i>Update Comment -->
                                                                        <i class="intelicon-comment-outlined "
                                                                            aria-hidden="true"></i> Update
                                                                    </button>
                                                                </td>


                                                            </tr>
                                                            <br>
                                                            <tr>
                                                                
                                                                <td>
                                                                    <button kendoButton [themeColor]="'success'"
                                                                        (click)="submitAndCompleteClick()" title="Submit and Complete"
                                                                        [hidden]="!enableSubmitAndComplete">
                                                                        <i class="intelicon-passed-completed-outlined"
                                                                            aria-hidden="true"></i> Submit and Complete
                                                                    </button>
                                                                </td>
                                                                
                                                            </tr>

                                                        </table>

                                                    </div>
                                                    <!-- <div *ngIf="mode=='EXECUTION STARTED'" style="height: 150px;"></div> -->
                                                    <div>
                                                        <a *ngIf="item.content" [href]="fileUrl+item.content" style="padding-left: 6px;"
                                                            target="_new">Open PDF (new tab)</a>
                                                        <table class="recipe-detail-table">
                                                            <!--removed option from grid to fix grid collapsing issue
                                                                (selectionChange)="checkListGridSelectionChange($event)"-->
                                                            <kendo-grid #gridCheckList="kendoGrid"
                                                                [kendoGridBinding]="checkListGridData[item.id]"
                                                                [style.height.%]="100" [resizable]="true"
                                                                [filter]="filter"
                                                                (filterChange)="checkListGridfilterChange($event)"
                                                                [sortable]="{
                                                        allowUnsort: true,
                                                        mode: 'multiple'}"
                                                                [pageable]="{buttonCount: 3, pageSizes: [20, 25, 30]}"
                                                                [pageSize]="20" [reorderable]="true" [selectable]="true"
                                                                style="width:100%"
                                                                [loading]="checkListGridLoading">
                                                                <ng-template kendoTextBoxSuffixTemplate>
                                                                    <kendo-loader></kendo-loader>
                                                                </ng-template>
                                                                <kendo-grid-column field='name' title='Instruction'
                                                                    [width]="85" headerClass="headerStyle"
                                                                    class="rowStyle">
                                                                    <ng-template kendoGridCellTemplate let-dataItem>
                                                                        <span *ngIf="dataItem.type.toLowerCase()==='header'"
                                                                            [ngStyle]="{'font-weight':'bold'}">{{"H: " +
                                                                            dataItem.name}}</span>
                                                                        <span
                                                                            *ngIf="dataItem.type.toLowerCase()!='header' && dataItem.isRequired ===true"
                                                                            [ngStyle]="{'color':'red'}">{{"*"}}</span>
                                                                        <span
                                                                            *ngIf="dataItem.type.toLowerCase()!='header'">{{dataItem.name}}</span>
                                                                    </ng-template>
                                                                </kendo-grid-column>
                                                                <kendo-grid-column field="isRequired"  [width]="15"
                                                                    title="Availability">
                                                                    <ng-template kendoGridCellTemplate let-dataItem
                                                                        let-rowIndex="rowIndex">
                                                                        <div *ngIf="dataItem.type.toLowerCase()!='header'" >
                                                                            <strong>
                                                                                <input type="checkbox"
                                                                                    (change)="areAllRequiredChecked($event)"
                                                                                    [(ngModel)]="dataItem.isChecked"
                                                                                    [ngModelOptions]="{standalone: true}"
                                                                                    [disabled]="isExecutionComplete||enableStartRampUp" />
                                                                            </strong>
                                                                        </div>
                                                                    </ng-template>
                                                                </kendo-grid-column>
                                                            </kendo-grid>
                                                        </table>
                                                        <table id="wrapper"
                                                            [style.display]="isExpertMode ? 'none' :  'block'">
                                                            <tr>
                                                                <div>

                                                                    <span style="text-align: center;"
                                                                        *ngIf="checkListContentLoading[item.id].loaded">
                                                                        <div *ngIf="item.content!=''" style="align-items: center;">
                                                                            <kendo-loader type="converging-spinner" size="medium" > </kendo-loader>
                                                                            <p style="font-size: 20px;" >Loading Document...</p>
                                                                        </div>
                                                                        <div *ngIf="item.content==''" style="align-items: center;">
                                                                            <p style="font-size: 20px;" >No documents found for this Building Block.</p>
                                                                        </div>

                                                                    </span>
                                                                    <pdf-viewer style="width: 800px; height: 800px"
                                                                        [src]="{url: fileUrl+item.content}"
                                                                        [render-text]="true" [original-size]="false"
                                                                        (after-load-complete)="checkListContentLoading[item.id].loaded=false"
                                                                        (on-progress)="onProgress($event, item.id)">


                                                                    </pdf-viewer>
                                                                </div>
                                                            </tr>
                                                        </table>
                                                    </div>
                                                </ng-template>

                                            </kendo-panelbar-item>

                                        </kendo-panelbar>

                                        <table>
                                            <tr>
                                                <button class="btn btn-primary btn-sm button" type="button"
                                                    (click)="submitAndCompleteClick()" title="Save" [hidden]="true">
                                                    <i class="intelicon-user-approved-selected-outlined"
                                                        aria-hidden="true"></i>Save
                                                </button>

                                                <!-- <kendo-label [hidden]="!showLmMessage" text="Note: Please fill in all the required details in Lab Manager tab so that you can see the Submit and Complete! button." style="color:red; font-size: 15px;"> 
                                                     
                                                </kendo-label>  -->

                                                <br>

                                                <button kendoButton [themeColor]="'success'"
                                                    (click)="submitAndCompleteClick()" title="Submit and Complete"
                                                    [hidden]="!enableSubmitAndComplete" id = "submitAndCompleteBtn">
                                                    <i class="intelicon-passed-completed-outlined"
                                                        aria-hidden="true"></i> Submit and Complete
                                                </button>

                                                
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                            </ng-template>

                        </kendo-tabstrip-tab>

                        <kendo-tabstrip-tab [title]="'Lab Manager'" [disabled]="!isLabManagerLoaded" [selected]="!isWorkQLoaded"> <!--isLabManagerLoaded [selected]="isLabManagerSelected"-->
                            <ng-template kendoTabContent>
                                <div class="pane-content"> 
                                    <div *ngIf="isLabManagerLoaded">  
                                        <div style = "width: 100% !important;"> 
                                            <div style="padding-left: 15px; font-size:bold; color: red;">Note: * - Mandatory Fields</div>
                                            <div class="m-3">
                                                <div class="workqLmTab">                                                   
                                                    <kendo-formfield class="col-6 fieldFormat" orientation="horizontal">
                                                        <!-- <kendo-label text="Site"> </kendo-label> -->
                                                        <span [ngStyle]="{'color':'red'}">{{"*"}}<kendo-label text="Site" [ngStyle]="{'color':'black'}"> </kendo-label></span><br>
                                                        <kendo-dropdownlist class = "dropdownlist_lm" id = "lmSite" [readonly]="true" [data]="lmSiteList" [value]="selectedLMSite" (valueChange)="ddlSiteValueChange($event)"></kendo-dropdownlist>
                                                    </kendo-formfield>
                                                    <kendo-formfield class="col-6 fieldFormat" orientation="horizontal">
                                                        <!-- <kendo-label text = "Hostname"> </kendo-label> -->
                                                        <span [ngStyle]="{'color':'red'}">{{"*"}}<kendo-label text = "Hostname" [ngStyle]="{'color':'black'}"> </kendo-label></span><br>
                                                        <div style = "display:flex;"> 
                                                            <kendo-textbox class = "textbox-lm" id = "lmHostName" #txtHostName [disabled]="lmControlReadOnly" placeholder="Enter HostName..." [value]="lmHostName" (valueChange)="onHostNameChange($event)"></kendo-textbox>
                                                            <button kendoButton [themeColor]="'primary'" type="button" [disabled]="enableSaveConfig" class="k-button k-primary" (click)="getLMHostDetails(txtHostName.value)" style = "margin-left:10px;">Retrieve LM Data</button>
                                                        </div>
                                                    </kendo-formfield>
                                                    <kendo-formfield class="col-6" orientation="horizontal">
                                                        <!-- <kendo-label text="Lab"> </kendo-label> -->
                                                        <span [ngStyle]="{'color':'red'}">{{"*"}}<kendo-label text="Lab" [ngStyle]="{'color':'black'}"> </kendo-label></span><br>
                                                        <kendo-dropdownlist class = "dropdownlist_lm" id = "lmLab" [data]="lmLabList" [readonly]="true" [(value)]="lmLab" (valueChange)="ddlLabValueChange($event)" ></kendo-dropdownlist>
                                                    </kendo-formfield>
                                                    <kendo-formfield class="col-6" orientation="horizontal">
                                                        <!-- <kendo-label text="Group"> </kendo-label> -->
                                                        <span [ngStyle]="{'color':'red'}">{{"*"}}<kendo-label text="Group" [ngStyle]="{'color':'black'}"> </kendo-label></span><br>
                                                        <kendo-textbox class = "textbox-lm" id = "lmGroup" [(value)]="lmGroup" [disabled]="true" (valueChange)="onGroupChange($event)"></kendo-textbox>
                                                    </kendo-formfield>
                                                    <kendo-formfield class="col-6" orientation="horizontal">
                                                        <!-- <kendo-label text="Projects"> </kendo-label> -->
                                                        <span [ngStyle]="{'color':'red'}">{{"*"}}<kendo-label text="Projects" [ngStyle]="{'color':'black'}"> </kendo-label></span><br>
                                                        <kendo-dropdownlist class = "dropdownlist_lm" id = "lmProjects" [data]="lmProjectList" [readonly]="lmControlReadOnly" textField="name" valueField="projectID" [(value)]="lmProject" (valueChange)="ddlProjectValueChange($event)" ></kendo-dropdownlist>
                                                    </kendo-formfield>
                                                    <kendo-formfield class="col-6" orientation="horizontal">
                                                        <!-- <kendo-label text="Owner"> </kendo-label> -->
                                                        <span [ngStyle]="{'color':'red'}">{{"*"}}<kendo-label text="Owner" [ngStyle]="{'color':'black'}"> </kendo-label></span><br>
                                                        <kendo-dropdownlist class = "dropdownlist_lm" id = "lmOwner" [data]="lmOwnerList" [readonly]="lmControlReadOnly" [(value)]="lmOwner" (valueChange)="ddlOwnerValueChange($event)"></kendo-dropdownlist>
                                                    </kendo-formfield>
                                                    <kendo-formfield class="col-6" orientation="horizontal">
                                                        <!-- <kendo-label text="Activity"> </kendo-label> -->
                                                        <span [ngStyle]="{'color':'red'}">{{"*"}}<kendo-label text="Activity" [ngStyle]="{'color':'black'}"> </kendo-label></span><br>
                                                        <kendo-dropdownlist class = "dropdownlist_lm" id = "lmActivity" [data]="lmActivitiesList" [readonly]="lmControlReadOnly" [(value)]="lmActivity" (valueChange)="ddlActivityValueChange($event)"></kendo-dropdownlist>
                                                    </kendo-formfield>
                                                    <kendo-formfield class="col-6" orientation="horizontal">
                                                        <!-- <kendo-label text="Active State"> </kendo-label> -->
                                                        <span [ngStyle]="{'color':'red'}">{{"*"}}<kendo-label text="Active State" [ngStyle]="{'color':'black'}"> </kendo-label></span><br>
                                                        <kendo-dropdownlist class = "dropdownlist_lm" id = "lmActivityState" [data]="lmActiveStates" [readonly]="lmControlReadOnly" [(value)]="lmActiveState" (valueChange)="ddlActiveStateValueChange($event)"></kendo-dropdownlist>
                                                    </kendo-formfield>
                                                    <kendo-formfield class="col-6" orientation="horizontal">
                                                        <!-- <kendo-label text="Station.VT"> </kendo-label> -->
                                                        <span [ngStyle]="{'color':'red'}">{{"*"}}<kendo-label text="Station.VT" [ngStyle]="{'color':'black'}"> </kendo-label></span><br>
                                                        <kendo-dropdownlist class = "dropdownlist_lm" id="lmVT" [data]="lmVTNamesList" [(value)]="lmVTName" [readonly]="lmControlReadOnly" (valueChange)="ddlVTeamValueChange($event)" ></kendo-dropdownlist>
                                                    </kendo-formfield>
                                                    <!-- <kendo-formfield class="col-6" orientation="horizontal">
                                                        <kendo-label text="Focus"> </kendo-label>
                                                        <kendo-textbox class = "textbox-lm" id = "lmFocus" [(value)]="lmFocus" [disabled]="lmControlReadOnly" (valueChange)="onFocusChange($event)"></kendo-textbox>
                                                    </kendo-formfield> -->
                                                    <kendo-formfield class="col-6" orientation="horizontal">
                                                        <!-- <kendo-label text="Board SN"> </kendo-label> -->
                                                        <!-- <span [ngStyle]="{'color':'red', 'float':'left'}">{{"*"}}</span><kendo-label text="Board SN" [ngStyle]="{'color':'black'}"> </kendo-label><br>  -->
                                                        <div style="display: flex; align-items: center;">
                                                            <span [ngStyle]="{'color':'red'}" [hidden]="isBoardSnHidden">{{"*"}}</span>
                                                            <kendo-label text="Board SN" [ngStyle]="{'color':'black'}"> </kendo-label>
                                                        </div>
                                                        <kendo-textbox class = "textbox-lm" id = "lmBoardSn" [disabled]="true" [(value)]="lmBoardSN" (valueChange)="onBoardSNChange($event)"></kendo-textbox>
                                                    </kendo-formfield>
                                                    <kendo-formfield class="col-6" orientation="horizontal">
                                                        <!-- <kendo-label text="Location"> </kendo-label> -->
                                                        <span [ngStyle]="{'color':'red'}">{{"*"}}<kendo-label text="Location" [ngStyle]="{'color':'black'}"> </kendo-label></span><br>
                                                        <kendo-textbox class = "textbox-lm" id = "lmLocation" [disabled]="true" [(value)]="lmLocation" (valueChange)="onLocationChange($event)"></kendo-textbox>
                                                    </kendo-formfield>
                                                    <kendo-formfield class="col-6" orientation="horizontal">
                                                        <kendo-label text="Focus"> </kendo-label>
                                                        <kendo-textbox class = "textbox-lm" id = "lmFocus" [(value)]="lmFocus" [disabled]="lmControlReadOnly" (valueChange)="onFocusChange($event)"></kendo-textbox>
                                                    </kendo-formfield>
                                                    <kendo-formfield class="col-6" orientation="horizontal">
                                                        <kendo-label text="Description"> </kendo-label>
                                                        <kendo-textbox class = "textbox-lm" #txtDescription [(value)]="lmDescription" [disabled]="lmControlReadOnly" (valueChange)="onDescChange($event)"></kendo-textbox>
                                                    </kendo-formfield>
                                                    <kendo-formfield class="col-6" orientation="horizontal">
                                                        <kendo-label text="Recipe Number"> </kendo-label>
                                                        <kendo-textbox class = "textbox-lm" [(value)]="workqGridRecipeNumber" [disabled]="true"></kendo-textbox>
                                                    </kendo-formfield>
                                                </div>
                                                <div style="float:right !important; margin-top: 10px; display:flex;">
                                                    <button kendoButton [themeColor]="'primary'" type="button" class="k-button k-primary" [disabled]="enableSaveConfig" (click)="saveConfig()">Save Config</button>
                                                    <button  kendoButton [themeColor]="'primary'" type="button" class="k-button k-primary" [disabled]="enableSaveConfig" (click)="btnResetLMTab()" style = "margin-left:10px;">Reset</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                        </kendo-tabstrip-tab>

                    </kendo-tabstrip>
                </kendo-splitter-pane>
            </kendo-splitter>
        </kendo-splitter-pane>
    </kendo-splitter>
    <br>

    <!-- Footer -->
</div>
