import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, Renderer2} from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { SearchGateway } from './../../gateways/search.gateway';
import { MsalService } from '@azure/msal-angular';
import { CompositeFilterDescriptor, filterBy, distinct } from '@progress/kendo-data-query';
import { WorkQ } from '../shared/model/work-q';
import { EventEmitterService } from 'app/common/event-emitter-service';
import { appSettings } from '../shared/app-settings';
import { Subscription, lastValueFrom, Observable, takeUntil, Subject } from 'rxjs';
import { DataItem, GridComponent, GridDataResult, SelectableSettings } from '@progress/kendo-angular-grid';
import { BuildingBlock, CheckListItem } from '../shared/model/building-block';
import { FileGateway } from 'app/gateways/file.gateway';
import { PDFProgressData } from 'ng2-pdf-viewer';
import { CreateGateway } from 'app/gateways/create.gateway';
import { RmEmail } from '../shared/model/rm-email';
import { GraphUsers } from '../shared/model/graph-users';
import { GraphUsersGateway } from 'app/gateways/graph-users.gateway';
import { Person } from '../shared/model/person';
import { QueryApiService, UserSettingsApiService, UserApiCacheService, RecordApiService, CommentsApiService, LookupApiService } from '@apps-es/api';
import { environment } from 'environments/environment';
import { DatePipe } from '@angular/common';
import { take } from 'rxjs/operators';
import { ExcelExportData, ExcelExportComponent } from "@progress/kendo-angular-excel-export";
import { FabPositionMode, FabAlign, FabOffset, ButtonThemeColor } from "@progress/kendo-angular-buttons";
import { SVGIcon, chevronUpIcon, cartIcon } from "@progress/kendo-svg-icons";
import { SelectedBuildingBlock } from '../shared/model/recipe';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UserService } from 'app/common/user-service';
import { RM_NotificationService } from './../../common/notificationService';
import { ThirdPartyAppGateway } from 'app/gateways/third-party-app.gateway';
import { ToolsIntegration } from '../shared/model/tools-integration';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { Tool } from '@progress/kendo-angular-listbox/toolbar';
import { isNullOrEmptyString } from '@progress/kendo-angular-grid/utils';
import { data } from 'jquery';
import { throws } from 'assert';


@Component({
  selector: 'app-work-q',
  templateUrl: './work-q.component.html',
  styleUrls: ['./work-q.component.scss']
})

export class WorkQComponent implements OnInit {
  @ViewChild('grid', { static: false }) grid!: GridComponent;
  @ViewChild('searchBox') searchBox!: ElementRef;
  @ViewChild('reasonbutton') reasonBtn!: ElementRef;

  isSearchBoxDisabled = true;
  isReasonButtonDisabled = true;

  /* ======= LM Integration Variables ======= */
  private filterCreateToolsIntegrationSubscription: Subscription = new Subscription;
  private filterGetUsersSubscription: Subscription = new Subscription;

  public lmSiteList: string[] = [];
  public lmLabList: any[] = [];
  public lmVTList: any[] = [];
  public lmActivitiesList: any[] = [];
  public lmProjectList: any[] = [];
  public lmOwnerList: any[] = [];
  public lmVTNamesList: any[] = [];
  //public isWindowRecipeHSDCreate:boolean =false;
  public selectedLMSite: string = "";
  public lmActiveStates: Array<string> = ["Active", "Inactive", "Lab Readiness", "Ramping-Up", "Ramping-Down", "Under Repair"];

  public destroy$: Subject<boolean> = new Subject<boolean>();
  public lmIntegrationRequest = new ToolsIntegration();

  public userEmail: string = ''; //store current user email
  public userWwid: string = ''; //store current user wwid

  public lmIntegrationId: string = '';
  public lmCreatedByWwid: string = '';
  public enableSaveConfig: boolean = false;
  public workqGridRecipeNumber: string = ""; //this variable assigned in populateworkq function and then pass the value to GetToolsIntegrationData function

  //Host Details variables

  public lmActiveState: string = "";
  public lmActivity: string = "";
  public lmBoardSN: string = "";
  public lmComponents: any[] = [];
  public lmDescription: string = "";
  public lmFocus: string = "";
  public lmGroup: string = "iVE";
  public lmHostName: string = "";
  public lmLab: string = "";
  public lmLocation: string = "";
  public lmOwner: string = "";
  public lmProject: string = "";
  public lmProjectID: string = "";
  public lmSite: string = "";
  public lmVTName: string = "";

  public showLmMessage: boolean = true;
  public isLMHostAvailable: boolean = true;
  public currentUser: GraphUsers | null;
  public domain: string  = "";
  public lmIdsid: string = "";
  //public lmDefaultSite: string = "";
  public lmWorkQ: string = "";
  public lmHSDNumber: string = "";
  public revision: number = 0; //this var is used to pass to updateToolsIntegrationItem method by increasing the current revision number.
  public lmControlReadOnly: boolean = false; // used to disable LM controls readonly/disable once CC completed
  public lmRecipeURL: string = ""; // this var is used to store recipe PDF URL to be updated in Lab Manager as hyperlink when passing Recipe number

  /* ================= END ================== */

  public positionMode: FabPositionMode = "fixed";
  public chevronUpSVG: SVGIcon = chevronUpIcon;
  public position: FabAlign = { vertical: "bottom", horizontal: "end" };
  public offset: FabOffset = { x: `16px`, y: `70px` };
  public offset_save: FabOffset = { x: `16px`, y: `130px` };
  public popup_comment: boolean = false;
  public popup_color: ButtonThemeColor = "success";
  public form: FormGroup;

  public rmHsdOwnersList: string[] = [];
  // public simplifiedBBList: BuildingBlock[] = [];
  public simplifiedBBList: SelectedBuildingBlock[] = [];
  public buildingBlockData: BuildingBlock[] = [];
  public initial_buildingBlockData: BuildingBlock[] = [];
  public checkList: CheckListItem[] = [];
  public checkListGridData: any = {};
  public rmLabList: string[] = [];
  //public allRequestData: any = null;

  public fileUrl: string = "";
  public remainingChecklist: number = 0;
  public totalChecklist: number = 0;

  public isFilterVisible: boolean = true;
  public isExpertMode: boolean = false;
  public fromDate: Date = new Date();
  public toDate: Date = new Date();
  public searchGridData: any[] = [];
  public searchGridLoading: boolean = false;
  public checkListGridLoading: boolean = false;
  public filter!: CompositeFilterDescriptor;
  public workqGridLoading: boolean = false;
  public workqData: WorkQ[] = [];
  public exportData: any = null;

  public rmSelectedLab: string = "";

  public wordSearchFilterSelected: string = 'All';
  public ownerIsLoading: boolean = false;
  public coOwnerIsLoading: boolean = false;
  public coOwnerPlaceHolder = { displayName: 'All', jobTitle: null };

  public selectedHSDOwner: string = "";
  public selectedforHSDOwner: GraphUsers;

  public selectedCoOwner: string = "";
  public selectedLab: string = "";
  public selectedBoardType: string = "";
  public selectedExecutionType: string = "";
  public selectedProduct: string = "";
  public selectedValidationTeam: string = "";
  public selectedSiliconProgram: string = "";
  public gridData: any[] = [];
  public total: number = 0;
  public mySelection: any[] = []; //used to clear selection

  /* Loading spinners */
  public isSearchLoading: boolean = false;
  public isSearchDisabled: boolean = true;
  public areLabsLoading: boolean = false;
  public areBoardTypesLoading: boolean = false;
  public areProductsLoading: boolean = false;
  public isOwnerLoading: boolean = false;
  public isCoownerLoading: boolean = false;
  public areValidationTeamsLoading: boolean = false;
  public areSiliconProgramsLoading: boolean = false;
  public isEmpty: boolean = true;
  public enableSave: boolean = false;
  public enableSubmitAndComplete: boolean = false;
  public alertDialogOpen = false;
  public alertDialogMessage: string = "";
  public ownerData: GraphUsers[] = [];
  public allOwnerData: GraphUsers[] = [];
  private filterSubscription: Subscription | undefined;
  public loading: any = {};
  public isDetailsLoading: boolean = false;
  public isOverlayLoading: boolean = false;
  public isWorkQLoaded: boolean = false;
  public isLabManagerLoaded: boolean = false; //Ken Lab Manager Tab
  public isLabManagerSelected: boolean = false;
  public isAssignable: boolean = false;
  public isAssignLoading: boolean = false;
  public rampUpTimer: boolean = true;
  public hsdUpdateIndicator: boolean = true;
  public hsdUpdateStatus: boolean = true;
  public wordSearchFilterTypes: Array<string> = [
    'Search By', 'WorkQ#', 'HSD#', 'Recipe#', 'Mode', 'Completed'
  ];

  public rmExecutionTypeList: string[] = [
    'All',
    'Power-On Readiness',
    'Execution'
  ];

  public defaultLab = 'All';
  public defaultProduct: string = "All";
  public defaultBoardType: string = "All";
  public productPlaceHolder: string = 'All';

  public defaultExecutionType: string = this.rmExecutionTypeList[0];
  public defaultOwner: string = "All";
  public defaultCoOwner: string = "All";
  public defaultValidationTeam: string = "All";
  public defaultSiliconProgram: string = "All";
  public selectedDocxFile!: string;
  public isPdfLoading: boolean = false;
  public windowOpened = false;
  public tableDisplay: string = "none";
  public selectedOwnerEmailId: string = "";
  public selectedOwnerName: string = "";
  public selectedOwnerWwid: string = "";
  public recipeId: string = "";
  public workqId: string = "";
  public createdByWwid: string = "";
  public recipeNumber: string = "";
  public workqNumber: string = "";
  public recipeName: string = "";
  public recipeOwner: any[] = [];
  public recipeCoOwner: any[] = [];
  public hsdId: string = "";
  public hsdReason: string = ""; //Ken add this to populate Reason Field
  public workqCategory: string = ""; // Ken add this to populate Category Field
  public mode: string = "";
  public enableStartRampUp: boolean = false;
  public enableStartRampDown: boolean = false;
  public isExecutionComplete: boolean = true;
  public idNumber: string = '';
  public hsdRevNumber: string = '';
  public hsdOwner: string = '';
  public hsdTitle: string = ""; //Populate HSD Title
  public hsdDescription: string = "";
  public hsdTag: string = "";
  public userName: string = "";
  public selectedRowIndex: any;
  public selectedRowIndexRefresh: any; //Ken add this for selected row refresh
  public isBoardSnHidden: boolean = false;
  public selectedData: any;
  public timeNowHSDStr: string = '';
  public timeNowHSD: Date = new Date();
  public idsid: string = '';
  public totalNumberOfRequiredCheckedItems: number = 0;
  public totalNumberOfOptionalCheckedItems: number = 0;
  public progressComment: string = '';
  public workqUrlComment: string = '';
  private filterGetHsdEsArticlesSubscription: Subscription = new Subscription();
  private filterUpdateHsdEsArticlesSubscription: Subscription = new Subscription();

  public currentUrl: string = "";
  public lastSegment: string = "";
  public parts: string = "";
  public searchByValue : string = "Search By";
  public workqUrlId: string = "";
  public txtSearch: string = "";

  public defaultItem: { type: string, value: number } = { type: 'All', value: 1 };
  public checkListContentLoading: any = {};

  public reasonList: any[] = [];
  public reasonValue: string = "";
  public userConfigSite: string = "";

  constructor(public graphUsersGateway: GraphUsersGateway,
    private searchGateway: SearchGateway,
    public thirdPartyAppGateway: ThirdPartyAppGateway,
    private authService: MsalService,
    private formBuilder: FormBuilder,
    private eventEmitterService: EventEmitterService,
    public fileGateway: FileGateway,
    private createGateway: CreateGateway,
    public queryApi: QueryApiService,
    public lookupApi: LookupApiService,
    public recordApi: RecordApiService,
    public userSettingApi: UserSettingsApiService,
    public userApiCache: UserApiCacheService,
    public commentsApi: CommentsApiService,
    public datepipe: DatePipe,
    public notificationService: RM_NotificationService,
    private http: HttpClient,
    public userService: UserService,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private renderer: Renderer2
  ) {
    this.currentUser = new GraphUsers();
    this.allRequestData = this.allRequestData.bind(this);
    this.form = this.formBuilder.group({
      switch: new FormControl({ value: false, disabled: false }, Validators.required),
      modelWindowswitch: new FormControl({ value: false, disabled: false }, Validators.required),
      comment: new FormControl(null),
      owner: new FormControl(null)
    });
    this.selectedforHSDOwner = new GraphUsers();
  }


  //Remove the Splitter-Pane Dynamically added by Ken 
  // ngAfterViewInit(): void {
  //   this.removeDynamicElement();
  // }

  //Remove the Splitter-Pane Dynamically added by Ken 
  // private removeDynamicElement(): void {
  //   // Use a timeout to ensure the DOM is fully updated
  //   setTimeout(() => {
  //     const dynamicElement = document.querySelector('.k-collapse-next.ng-star-inserted');
  //     const dynamicElement2 = document.querySelector('.k-collapse-prev.ng-star-inserted');
  //     if (dynamicElement && dynamicElement.parentNode) {
  //       this.renderer.removeChild(dynamicElement.parentNode, dynamicElement);
  //     }
  //     if (dynamicElement2 && dynamicElement2.parentNode) {
  //       this.renderer.removeChild(dynamicElement2.parentNode, dynamicElement2);
  //     }
  //   }, 0);
  // }

  public async setSearchFilters(): Promise<void> {
    this.isOwnerLoading = true;
    this.areLabsLoading = true;
    var defaultInput = "All";

    /* Get Sites */
    this.rmLabList = await lastValueFrom(this.searchGateway.getLabsFromWorkQs());
    //this.rmLabList = this.rmLabList.map(site => site.toUpperCase()); // WH 04SEP2023 - uppercase site
    this.rmLabList.sort();
    localStorage.setItem("RmWorkqSiteList", JSON.stringify(this.rmLabList));
    this.loadLabs();
    this.areLabsLoading = false;

    /* Get Execution Types */

    this.selectedExecutionType = this.existInSessionStorage("workqSelectedExecutionType") && this.rmExecutionTypeList.includes(this.getStringValueFromSessionStorage("workqSelectedExecutionType")) ? this.getStringValueFromSessionStorage("workqSelectedExecutionType") : this.rmExecutionTypeList[0];

    /* Get Owners */

    this.rmHsdOwnersList = await lastValueFrom(this.searchGateway.getHsdOwnersFromWorkQs());
    this.rmHsdOwnersList.push(defaultInput);
    this.rmHsdOwnersList.sort();
    this.setOwnerPlaceHolder();
    this.isOwnerLoading = false;
  }

  public setOwnerPlaceHolderFromMSAL(): void {
    var account = this.authService.instance.getActiveAccount();
    if (account != undefined) {
      var userName = account.name;
      if (userName != undefined) {
        this.defaultOwner = this.rmHsdOwnersList.includes(userName) ? userName : this.rmHsdOwnersList[0];
        this.selectedHSDOwner = this.defaultCoOwner;
      }
    }
  }

  public setOwnerPlaceHolder(): void {
    if ("workqSelectedHSDOwner" in sessionStorage) {
      this.selectedHSDOwner = this.existInSessionStorage("workqSelectedHSDOwner") && this.rmHsdOwnersList.includes(this.getStringValueFromSessionStorage("workqSelectedHSDOwner")) ? this.getStringValueFromSessionStorage("workqSelectedHSDOwner") : this.rmHsdOwnersList[0];
    }
    if (this.rmHsdOwnersList.length > 0 && (this.selectedHSDOwner === this.rmHsdOwnersList[0] || this.selectedHSDOwner === '')) {
      if ("appSettings.appUserFullname" in sessionStorage) {

        var appUserFullName = sessionStorage.getItem("appSettings.appUserFullname");

        if (appUserFullName != undefined) {
          appSettings.appUserFullname = appUserFullName;
          this.defaultOwner = this.rmHsdOwnersList.includes(appUserFullName) ? appUserFullName : this.rmHsdOwnersList[0];
          this.selectedHSDOwner = this.defaultOwner;

        }
        else {
          this.setOwnerPlaceHolderFromMSAL();
        }
      }
      else {
        this.setOwnerPlaceHolderFromMSAL();
      }
    }
  }

  public loadOwners() {
    this.filterSubscription = this.graphUsersGateway.getGroupMembers(['RM_Admins', 'RM_L1_Technicians', 'RM_L2_Technicians'])
      .subscribe(ownerData => {
        if (ownerData.length > 0) {
          this.allOwnerData = ownerData;
          sessionStorage.setItem('Owner', JSON.stringify(ownerData));
        }
      })
      ;
  }

  public graphOwnerUsers(requesterInput: string): void {

    if (requesterInput.length >= 3) {
      const validEmailItems = [];
      this.loading['owner'] = true;

      if (requesterInput.indexOf(' ') > 0) {
        const copiedValues = requesterInput.split(' ');

        for (const copiedValue of copiedValues) {
          if (copiedValue.indexOf('@') > 0) {
            const validEmail = copiedValue.replace(/[&\/\\#,+()$~%;'':*?<>{}]/g, '')
            validEmailItems.push(validEmail);
          }
        }

      }
      if (validEmailItems.length > 0) {
        validEmailItems.forEach(email =>
          this.filterSubscription = this.graphUsersGateway.getUsers(email, 10, '', true)
            .subscribe(ownerData => {
              if (ownerData.length > 0) {
                this.ownerData = ownerData;
                this.selectedforHSDOwner = ownerData[0];

                sessionStorage.setItem('Owner', JSON.stringify(ownerData));
              }
            })
        );

      }
      else {
        this.filterSubscription = this.graphUsersGateway.getUsers(requesterInput, 10, '', true)
          .subscribe(ownerData => {
            if (ownerData.length > 0) {
              this.ownerData = ownerData;
              this.selectedforHSDOwner = ownerData[0];

              sessionStorage.setItem('Owner', JSON.stringify(ownerData));
            }
          });
      }
    }
  }

  //this function is created to retrieve user's idsid via email using hsdes - TJ, WH
  public onOwnerValueChange(value: GraphUsers): void {
    this.isAssignLoading = true;
    this.isAssignable = false;
    if (!this.selectedforHSDOwner) {
      this.isAssignLoading = false;
      this.isAssignable = false
    }
    this.userService.getUserByEmailId(this.selectedforHSDOwner.mail).subscribe({
      next: (result: any) => {
        this.idsid = result.data[0]['sys_user.idsid']

      },
      error: (e) => {
        this.isAssignLoading = false;
        alert('Error retrieving selected user IDSID\n\n' + e.error.toString())
      },
      complete: () => {
        this.isAssignLoading = false;
        if (!this.selectedforHSDOwner) {
          this.isAssignable = false
        }
        else {
          this.isAssignable = true;
        }


      }
    })

  }
  //
  public loadLabs(): void {

    const labList = localStorage.getItem("RmWorkqSiteList");

    if (labList != null && labList.length > 0) {
      const allLabs = labList != null ? JSON.parse(labList) : [];
      this.rmLabList = allLabs;
    }
    // WH 27Sep2024 - comment out set site to cache value
    // if ("workqSelectedLab" in sessionStorage != undefined) {
    //   var labValue = sessionStorage.getItem("workqSelectedLab");
    //   if (labValue != undefined) {
    //     this.selectedLab = JSON.parse(labValue) as string;
    //   }
    //   else {
    //     this.selectedLab = this.rmLabList[0];
    //   }
    //   this.isSearchDisabled = false;
    // }

    // WH 27Sep2024 - set site to user's default site
    if (this.userConfigSite != "") {
      this.selectedLab = this.userConfigSite;
    } else {
      this.selectedLab = this.rmLabList[0];
    }
    this.isSearchDisabled = false;
  }


  public alertDialogClose() {
    this.alertDialogOpen = false;
  }

  public alertDialogAction() {
    this.alertDialogOpen = false;
  }


  public reportProblemsClick(): void {
    this.isOverlayLoading = true;
    let params: any = {};
    params['recipeId'] = this.recipeId;
    params['isArchived'] = false;
    params['isTemplate'] = false;
    params['isDraft'] = undefined;

    this.searchGateway.getRecipes(params).subscribe((result) => {
      this.recipeNumber = result[0].recipeNumber;
      let emailItem: RmEmail = {
        body: 'Hello ' + this.recipeOwner[0].name + ',<br/><br/><br/>'
          + 'You being owner of the recipe#  ' + this.recipeNumber + ' ,I (' + appSettings.appUserEmailId
          + ') want to let you know found error in the process while ramping up system'
          + '<br/><br/><br/>'
          + 'For futher information, please feel free to contact ' + appSettings.appUserEmailId
          + ' and take corrective actions for the recipe# ' + this.recipeNumber,
        ccList: [appSettings.appUserEmailId],
        toList: [this.recipeOwner[0].email, appSettings.appUserEmailId, this.recipeCoOwner[0].email],
        subject: 'Issue with Recipe# - ' + this.recipeNumber
      };

      this.createGateway.sendEmail(emailItem).subscribe({
        next: (result) => {
          this.isOverlayLoading = false;
          this.alertDialogMessage = `Email sent to recipe owner:<br><b>${this.recipeOwner[0].email}</b>`;
          this.alertDialogOpen = true;
        },
        error: (e) => {
          this.isOverlayLoading = false;
          alert('Problem Sending email to the Recipient Error\n\n' + e.error.toString());
        }
      });
    }, ((error => {
      this.isOverlayLoading = false;
      alert("An error was encountered while attempting to pull recipe information: " + error.error);
    })));


  }
  //
  public open(): void {

    this.windowOpened = true;
    //var myWindow = window.open("work-q.component.html", "newWindow", "width=500,height=700");

  }

  public close(): void {

    this.windowOpened = false;

  }

  public getStringValueFromSessionStorage(variable: string): string {
    var value = "";
    if (sessionStorage.getItem(variable) != null) {
      var sessionStorageValue = sessionStorage.getItem(variable);
      if (sessionStorageValue != null) {
        value = JSON.parse(sessionStorageValue);
      }
    }
    return value;
  }

  public existInSessionStorage(variable: string): boolean {
    return sessionStorage.getItem(variable) != null;
  }
  //
  ngOnInit(): void {

    var account = this.authService.instance.getActiveAccount();
    if (account != undefined) {
      var accountName = account.name;
      if (accountName != undefined) {
        this.userName = accountName;
        this.userEmail = account.username;
        this.setUserWwid();
      }
    }

    this.getUserConfig();

    //Get User Domain and IDSID
    const userEmail = sessionStorage.getItem('appSettings.appUserEmailId');
    if (userEmail) {
      this.graphUsersGateway.getUsers(userEmail, 10, '', true)
        .subscribe(userData => {
          if (userData.length === 1) {
            this.currentUser = userData[0];
            var idsid = this.currentUser.onPremisesSamAccountName;
            var domain = this.currentUser.onPremisesDomainName; //gar.corp.intel.com

            //Call formatDomain function
            this.formatDomain(domain);
            this.lmIdsid = idsid;

          }
        })
    }

    //Get Reason Field for HSD Update - Ken Latest:2/7/2024
    this.getReasonHsd();

    // var defaultInput = "All";
    // this.fileUrl = this.fileGateway.getUrl('', '');
    // this.eventEmitterService.refreshSearchVar = this.eventEmitterService.refreshSearchFunction.subscribe((name: string) => {
    //   this.search(false);
    // });
    this.fromDate = new Date();
    this.fromDate.setDate(this.fromDate.getDate() - 30);

    this.toDate = new Date();
    this.toDate.setDate(this.toDate.getDate() + 1);

    this.setSearchFilters();

    //this.search(false);
    this.userName = appSettings.appUserFullname;

    this.loadOwners();

    //Start of Code Auto Search Work-Q from HSDES - Ken Latest:24/5/2024
    //this.currentUrl = this.router.url;
    //this.currentUrl = "http://localhost:4300/#/work-q/WQ-24-20-2";

    this.route.params.subscribe(params => {
      this.workqUrlId = params['id'];
    });

    this.currentUrl = window.location.href;
    this.lastSegment = this.currentUrl.split('/').pop() || '';

    if(this.lastSegment.startsWith('WQ'))
    {
      this.searchByValue = 'WorkQ#';
      this.wordSearchFilterSelected = 'workq#';
      this.fileUrl = this.fileGateway.getUrl('', '');
      this.search(true, true);
    }
    else
    {
      var defaultInput = "All";
      this.fileUrl = this.fileGateway.getUrl('', '');
      this.eventEmitterService.refreshSearchVar = this.eventEmitterService.refreshSearchFunction.subscribe((name: string) => {
        this.search(false);
      });

      this.search(false);
      console.log("URL is not from HSDES");
    }
    //End of Code Auto Search Work-Q from HSDES

  }

  toggleFilter() {
    this.isFilterVisible = !this.isFilterVisible;
  }

  toggleComment() {
    this.popup_comment = !this.popup_comment
    if (this.popup_comment) {
      this.popup_color = 'warning'
    } else {
      this.popup_color = 'success'
    }
  }

  //Populate Reason field for HSD Update
  public getReasonHsd(){

    var field = "reason";
    var tenant = "services_sys_val";
    var subject = "support";

    this.filterGetHsdEsArticlesSubscription = this.lookupApi.get(field, tenant, subject).subscribe((result: any) => {
      console.log(result);

      const validValues = ['acknowledged', 'awaiting_3rd_party', 'awaiting_user', 'committed', 'development required', 'new', 'prerelease', 'root_caused', 'verify_failed', 'wip'];

      result.data.forEach((reason: any) => {
        if (validValues.includes(reason['sys_lookup.value'])) {
          this.reasonList.push(reason['sys_lookup.value']);
        }
      });


    }, (error => {
      console.log(error.toString());
    }));

  }


  //#region Click Functions
  public async saveChecklistClick() {
    try {
      this.updateBuildingBlocksProgress(this.simplifiedBBList);
      this.isOverlayLoading = true;

      // Create the WorkQ object
      let item: WorkQ = new WorkQ();
      item.id = this.workqId.toLocaleLowerCase();
      item.createdBy.wwid = this.createdByWwid;

      item.updatedBy = new Person();

      item.updatedBy.wwid = appSettings.appUserWwid;
      item.updatedBy.name = appSettings.appUserFullname;
      item.updatedBy.email = appSettings.appUserEmailId;
      item.buildingBlocks = this.simplifiedBBList;

      // Define changes
      const dataChanges = [
        { value: item.updatedBy, path: 'updatedBy', op: 'replace' },
        { value: item.buildingBlocks, path: 'buildingBlocks', op: 'replace' },
      ];

      // Update WorkQ
      let alertMessage = `Checklist progress saved successfully!`;
      await this.updateWorkQ(item, dataChanges, this.progressComment, alertMessage);
    }
    catch (error) {
      this.isOverlayLoading = false;
      alert(error);
    }
  }

  public async startRampUpClick() {

    //Check Hostname for updating ActiveState to "Ramping-Up" in Lab Manager
    if (this.lmHostName != null && this.lmHostName != "") {
      await this.updateLMHostActiveState(this.lmHostName.trim(), "Ramping-Up");
      this.getToolsIntegrationData(this.workqNumber, this.workqGridRecipeNumber);
    }
    else {
      alert("HostName not found. To update ActiveState to Ramping-Up in LM, need HostName. so please enter HostName in Lab Manager tab and save.\n\n")
    }

    try {
      this.isOverlayLoading = true;
      await this.queryHsdData();
      //await this.updateHsdRecords([{ 'reason': 'wip' }]); //Rajesh has commented this line to update the below codes.
      //===============================================================================
      //Author: Rajesh
      //Purpose: Update HSD tag field with current DateTime, Complete status.
      //Date: 22 May'24
      //Ticket Ref: https://hsdes.intel.com/appstore/article/#/13011750430
      console.log("Start Rampup: " + this.hsdTag);
      if(this.hsdTag.trim() == "" && this.hsdTag.trim().length == 0){
        var updateTag = this.workqGridRecipeNumber + ', '+ this.datepipe.transform((new Date), 'MM-dd-yyyy h:mm:ss');
        await this.updateHsdRecords([{ 'reason': 'wip' }, { 'tag': updateTag }]);
      }
      else{
        await this.updateHsdRecords([{ 'reason': 'wip' }]);
      }
      //================================================================================

    }
    catch (error) {
      this.isOverlayLoading = false;
      alert(error)
      return;
    }
    finally {
      await this.waitForHsdUpdate();

      if (this.hsdUpdateStatus) {
        let rampUpStartDate = new Date().toISOString();
        let item: WorkQ = new WorkQ();
        item.id = this.workqId.toLocaleLowerCase();
        item.createdBy.wwid = this.createdByWwid;

        item.updatedBy = new Person();

        item.updatedBy.wwid = appSettings.appUserWwid;
        item.updatedBy.name = appSettings.appUserFullname;
        item.updatedBy.email = appSettings.appUserEmailId;
        const dataChanges = [
          { value: 'execution started', path: 'mode', op: 'replace' },
          { value: rampUpStartDate, path: 'rampUpStartDate', op: 'replace' },
          { value: item.updatedBy, path: 'updatedBy', op: 'replace' }
        ]
        let comments = `Execution started for ${this.workqNumber}`
        let alertMessage = `Work-Q ramp-up action started successfully!`;
        await this.updateWorkQ(item, dataChanges, comments, alertMessage);
        this.exportData[this.selectedRowIndex].mode = 'EXECUTION STARTED';
        this.mode = 'EXECUTION STARTED';
        this.enableStartRampUp = false;

      }
      else {
        this.isOverlayLoading = false;
        this.alertDialogMessage = "Unable to update HSD-ES. Please refresh RM and try again. If issue persists, please contact us"
        this.alertDialogOpen = true;
      }
    }


  }

  //Ken add this to Start Ramp-Down
  public async startRampDownClick() {

    //Check Hostname for updating ActiveState to "Ramping-Up" in Lab Manager
    if (this.lmHostName != null && this.lmHostName != "") {
      await this.updateLMHostActiveState(this.lmHostName.trim(), "Ramping-Down");
      this.getToolsIntegrationData(this.workqNumber, this.workqGridRecipeNumber);
    }
    else {
      alert("HostName not found. To update ActiveState to Ramping-Down in LM, need HostName. so please enter HostName in Lab Manager tab and save.\n\n")
    }

    try {
      this.isOverlayLoading = true;
      await this.queryHsdData();
      //await this.updateHsdRecords([{ 'reason': 'wip' }]); //Rajesh has commented this line to update the below codes.
      //===============================================================================
      //Author: Rajesh
      //Purpose: Update HSD tag field with current DateTime, Complete status.
      //Date: 22 May'24
      //Ticket Ref: https://hsdes.intel.com/appstore/article/#/13011750430
      console.log("Start RampDown: " + this.hsdTag);
      if(this.hsdTag.trim() == "" && this.hsdTag.trim().length == 0){
        var updateTag = this.workqGridRecipeNumber + ', '+ this.datepipe.transform((new Date), 'MM-dd-yyyy h:mm:ss');
        await this.updateHsdRecords([{ 'reason': 'wip' }, { 'tag': updateTag }]);
      }
      else{
        await this.updateHsdRecords([{ 'reason': 'wip' }]);
      }
      //================================================================================

    }
    catch (error) {
      this.isOverlayLoading = false;
      alert(error)
      return;
    }
    finally {
      await this.waitForHsdUpdate();

      if (this.hsdUpdateStatus) {
        let rampUpStartDate = new Date().toISOString();
        let item: WorkQ = new WorkQ();
        item.id = this.workqId.toLocaleLowerCase();
        item.createdBy.wwid = this.createdByWwid;

        item.updatedBy = new Person();

        item.updatedBy.wwid = appSettings.appUserWwid;
        item.updatedBy.name = appSettings.appUserFullname;
        item.updatedBy.email = appSettings.appUserEmailId;
        const dataChanges = [
          { value: 'execution started', path: 'mode', op: 'replace' },
          { value: rampUpStartDate, path: 'rampUpStartDate', op: 'replace' },
          { value: item.updatedBy, path: 'updatedBy', op: 'replace' }
        ]
        let comments = `Execution started for ${this.workqNumber}`
        let alertMessage = `Work-Q ramp-down action started successfully!`;
        await this.updateWorkQ(item, dataChanges, comments, alertMessage);
        this.exportData[this.selectedRowIndex].mode = 'EXECUTION STARTED';
        this.mode = 'EXECUTION STARTED';
        this.enableStartRampDown = false;

      }
      else {
        this.isOverlayLoading = false;
        this.alertDialogMessage = "Unable to update HSD-ES. Please refresh RM and try again. If issue persists, please contact us"
        this.alertDialogOpen = true;
      }
    }


  }

  //Combination of Ramping-Up and Ramping-Down Actions
  public async startRampActionClick(action: 'Ramping-Up' | 'Ramping-Down') {
    // Check Hostname for updating ActiveState in Lab Manager
    if (this.lmHostName != null && this.lmHostName != "") {
      await this.updateLMHostActiveState(this.lmHostName.trim(), action);
      this.getToolsIntegrationData(this.workqNumber, this.workqGridRecipeNumber);
    } else {
      alert(`HostName not found. To update ActiveState to ${action} in LM, need HostName. so please enter HostName in Lab Manager tab and save.\n\n`);
    }

    try {
      this.isOverlayLoading = true;
      await this.queryHsdData();
      //await this.updateHsdRecords([{ 'reason': 'wip' }]); //Rajesh has commented this line to update the below codes.
      //===============================================================================
      //Author: Rajesh
      //Purpose: Update HSD tag field with current DateTime, Complete status.
      //Date: 22 May'24
      //Ticket Ref: https://hsdes.intel.com/appstore/article/#/13011750430
      console.log(`Start ${action}: ` + this.hsdTag);
      if (this.hsdTag.trim() == "" && this.hsdTag.trim().length == 0) {
        var updateTag = this.workqGridRecipeNumber + ', ' + this.datepipe.transform((new Date), 'MM-dd-yyyy h:mm:ss');
        await this.updateHsdRecords([{ 'reason': 'wip' }, { 'tag': updateTag }]);
      } else {
        await this.updateHsdRecords([{ 'reason': 'wip' }]);
      }
    } catch (error) {
      this.isOverlayLoading = false;
      alert(error);
      return;
    } finally {
      await this.waitForHsdUpdate();

      if (this.hsdUpdateStatus) {
        let rampActionStartDate = new Date().toISOString();
        let item: WorkQ = new WorkQ();
        item.id = this.workqId.toLocaleLowerCase();
        item.createdBy.wwid = this.createdByWwid;

        item.updatedBy = new Person();
        item.updatedBy.wwid = appSettings.appUserWwid;
        item.updatedBy.name = appSettings.appUserFullname;
        item.updatedBy.email = appSettings.appUserEmailId;

        const dataChanges = [
          { value: 'execution started', path: 'mode', op: 'replace' },
          { value: rampActionStartDate, path: 'rampUpStartDate', op: 'replace' },
          { value: item.updatedBy, path: 'updatedBy', op: 'replace' }
        ];

        let comments = `Execution started for ${this.workqNumber}`;
        let alertMessage = `Work-Q ${action.toLowerCase()} action started successfully!`;
        await this.updateWorkQ(item, dataChanges, comments, alertMessage);

        this.exportData[this.selectedRowIndex].mode = 'EXECUTION STARTED';
        this.mode = 'EXECUTION STARTED';
        if (action === 'Ramping-Up') {
          this.enableStartRampUp = false;
        } else {
          this.enableStartRampDown = false;
        }
      } else {
        this.isOverlayLoading = false;
        this.alertDialogMessage = "Unable to update HSD-ES. Please refresh RM and try again. If issue persists, please contact us";
        this.alertDialogOpen = true;
      }
    }
  }

  //Compare HSD-ES Reason and Work-Q Reason and update HSD-ES Reason if different
  public compareReasonTitleClick(hsdEsId: string, reason: string, workqId: string, hsdEsTitle: string) {
    console.log("HSD-ES ID: " + hsdEsId);
    console.log("HSD-ES Title: " + hsdEsTitle);

    const getTCsQuery = `SELECT support.site, services_sys_val.support.site_code, title, id, rev, owner, description, priority, reason, submitted_by, submitted_date, updated_by, updated_date WHERE id = '${hsdEsId}' `;

    this.filterGetHsdEsArticlesSubscription = this.queryApi.getRecordsByEql(getTCsQuery).subscribe((result: any) => {
      console.log(result);
      console.log("HSDES Reason: " + result[0].reason);
      console.log("HSDES Title: " + result[0].title);

      if (result.length > 0) {
        var hsdEsReason = result[0].reason;
        var hsdEsTitleQuery = result[0].title;
        console.log("HSD Reason: " + hsdEsReason);


        if (hsdEsReason != reason || hsdEsTitleQuery.toLocaleLowerCase() != hsdEsTitle) {
          console.log("Reason/Title is different. Update HSD-ES Reason in Database");
          let item: WorkQ = new WorkQ();
          item.id = workqId.toLocaleLowerCase();
          item.createdBy.wwid = this.createdByWwid;
          item.updatedBy = new Person();
          item.updatedBy.wwid = appSettings.appUserWwid;
          item.updatedBy.name = appSettings.appUserFullname;
          item.updatedBy.email = appSettings.appUserEmailId;

          const dataChanges = [
            { value: hsdEsReason, path: 'hsdReason', op: 'replace' },
            { value: hsdEsTitleQuery, path: 'hsdTitle', op: 'replace' },
            { value: item.updatedBy, path: 'updatedBy', op: 'replace' }
          ]

          //Update WorkQ and display pop up
          this.updateWorkQReason(item, dataChanges).then(() => {
            this.refreshSelectedRow();
          });
          this.hsdReason = hsdEsReason;

          //this.gridWorkqSelectionChange(this.selectedRowIndexRefresh);
          //this.grid.rowSelected(this.selectedRowIndex);
          //this.search();

        }
        else {
          console.log("Reason is same. No need to update HSD-ES Reason in Database");
        }
      }
    }, ((error => {
      console.log(error.toString());
    })));
  }

  public refreshSelectedRow(){
    //Call the function to refresh the selected row
    if (this.selectedRowIndex !== undefined) {
      const selectedRowData = this.searchGridData[this.selectedRowIndex];
      const workqNumber = selectedRowData.workqNumber; // Assuming 'workqNumber' is the unique identifier
      const gridIndexNum = this.selectedRowIndexRefresh.selectedRows[0].index;

      console.log("WorkQ Number: " + workqNumber);
      console.log("Selected Row: " + this.selectedRowIndexRefresh.selectedRows[0].index);

      this.searchGateway.getWorkQRefreshSingleRow(workqNumber).subscribe(async result => {
        const results = result;
        console.log("Refreshed Row: " + results.map(result => result.hsdReason));
        console.log("Refreshed Row Title: " + results.map(result => result.hsdTitle));
        console.log("Rows for SearchGridData: " + this.searchGridData[gridIndexNum].hsdReason);

        //Based on Index refresh the result
        this.searchGridData[this.selectedRowIndex].hsdReason = results.map(result => result.hsdReason.toUpperCase());
        this.searchGridData[this.selectedRowIndex].hsdTitle = results.map(result => result.hsdTitle.toUpperCase());

      },((error => {
        alert("An error was encountered while attempting to pull workq information: " + error.error);
      })));
    }
  }



  public hsdReasonValueChange(value: any) {
    this.reasonValue = "";
    this.reasonValue = value;
    console.log("Reason Value: " + this.reasonValue);
  }

  public async updateHsdReasonClick() {
    try {
      console.log("Update Reason: " + this.reasonValue);
      this.isOverlayLoading = true;
      await this.queryHsdData();
      await this.updateHsdRecords([{ 'reason': this.reasonValue }]);
    }
    catch (error) {
      this.isOverlayLoading = false;
      alert(error);
    }
    finally {
      await this.waitForHsdUpdate();
      if (this.hsdUpdateStatus) {
        let item: WorkQ = new WorkQ();
        item.id = this.workqId.toLocaleLowerCase();
        item.createdBy.wwid = this.createdByWwid;
        item.updatedBy = new Person();
        item.updatedBy.wwid = appSettings.appUserWwid;
        item.updatedBy.name = appSettings.appUserFullname;
        item.updatedBy.email = appSettings.appUserEmailId;
        const dataChanges = [
          { value: this.reasonValue, path: 'hsdReason', op: 'replace' },
          { value: item.updatedBy, path: 'updatedBy', op: 'replace' }
        ]
        let comments = `<p>Reason updated to ${this.reasonValue}</p>`
        let alertMessage = `HSD# : ${this.hsdId} reason has been updated to <br> <b>${this.reasonValue}</b>!`;

        //Update WorkQ and display pop up
        await this.updateWorkQReasonUI(item, dataChanges, comments, alertMessage).then(() => {
          this.refreshSelectedRow();
        });

      }
      else {
        this.isOverlayLoading = false;
        this.alertDialogMessage = "Unable to update HSD-ES. Please refresh RM and try again. If issue persists, please contact us"
        this.alertDialogOpen = true;
      }
    }
  }

  public async updateOwnerClick() {
    try {
      this.isOverlayLoading = true;
      this.rampUpTimer = false;
      await this.queryHsdData()
      await this.updateHsdRecords([{ 'owner': this.idsid }])
    }
    catch (error) {
      this.isOverlayLoading = false;
      alert(error)
    }
    finally {
      await this.waitForHsdUpdate();

      if (this.hsdUpdateStatus) {
        let item: WorkQ = new WorkQ();
        item.id = this.workqId.toLocaleLowerCase();
        item.createdBy.wwid = this.createdByWwid;
        item.updatedBy = new Person();
        item.hsdReason = 'released';
        item.updatedBy.wwid = this.selectedforHSDOwner.jobTitle
        item.updatedBy.name = this.selectedforHSDOwner.displayName
        item.updatedBy.email = this.selectedforHSDOwner.mail
        const dataChanges = [
          { value: item.updatedBy, path: 'hsdOwner', op: 'replace' },
          { value: item.updatedBy, path: 'updatedBy', op: 'replace' }
        ]
        let comments = `<p>Owner updated to ${this.selectedforHSDOwner.displayName}</p>`
        let alertMessage = `HSD# : ${this.hsdId}<br> <b>${this.selectedforHSDOwner.displayName}</b> has been asssigned as new owner!`;
        await this.updateWorkQ(item, dataChanges, comments, alertMessage);
        this.exportData[this.selectedRowIndex].hsdOwnerName = this.selectedforHSDOwner.displayName.toUpperCase();
        this.isWorkQLoaded = true;
        this.isLabManagerLoaded = true;
        this.isLabManagerSelected = false;
        this.isReasonButtonDisabled = false;
        this.sleepAndUpdateRampUp();
      }
      else {
        this.isOverlayLoading = false;
        this.alertDialogMessage = "Unable to update HSD-ES. Please refresh RM and try again. If issue persists, please contact us"
        this.alertDialogOpen = true;
      }

    }
  }

  public async submitAndCompleteClick() {
    console.log("WorkQ Completed: " + this.workqCategory);

    if (confirm('Please make sure to check all the values are correct in Lab Manager tab before compete the WorkQ/CC. Are you sure to proceed?') == true) {
      try {
        this.updateBuildingBlocksProgress(this.simplifiedBBList);
        this.isOverlayLoading = true;
        await this.queryHsdData();

        //await this.updateHsdRecords([{ 'status': 'complete' }, { 'reason': 'released' }, { 'tag': tagUpdate }]); // Rajesh has commented the line to add the below codes.

        //===================================================================================
        //Author: Rajesh
        //Purpose: Update HSD tag field with current DateTime, Complete status.
        //Date: 22 May'24
        //Ticket Ref: https://hsdes.intel.com/appstore/article/#/13011750430
        console.log("Complete " + this.hsdTag);
        var tagUpdate = this.hsdTag + ','+ 'Complete'+','+ this.datepipe.transform((new Date), 'MM-dd-yyyy h:mm:ss');
        await this.updateHsdRecords([{ 'status': 'complete' }, { 'reason': 'released' }, { 'tag': tagUpdate }]);
        //====================================================================================
        if (this.workqCategory.toLowerCase() === 'ramp down')
        {
          await this.updateLMHostActiveState(this.lmHostName.trim(), "Ramping-Down"); // Update Lab Manager Host Active State once done CC complete
          this.getToolsIntegrationData(this.workqNumber, this.workqGridRecipeNumber);
          this.lmControlReadOnly = true; //Make readonly for LM site, host, project, owner, activity, focus, description and activestate fields once Execution Complete.
        }
        else if (this.workqCategory.toLowerCase() === 'power-on readiness')
        {
          await this.updateLMHostActiveState(this.lmHostName.trim(), "Lab Readiness"); // Update Lab Manager Host Active State once done CC complete
          this.getToolsIntegrationData(this.workqNumber, this.workqGridRecipeNumber);
          this.lmControlReadOnly = true; //Make readonly for LM site, host, project, owner, activity, focus, description and activestate fields once Execution
        }
        else
        {
          await this.updateLMHostActiveState(this.lmHostName.trim(), "Active"); // Update Lab Manager Host Active State once done CC complete
          this.getToolsIntegrationData(this.workqNumber, this.workqGridRecipeNumber);
          this.lmControlReadOnly = true; //Make readonly for LM site, host, project, owner, activity, focus, description and activestate fields once Execution Complete.
        }

      }
      catch (error) {
        this.isOverlayLoading = false;
        alert(error)
      }
      finally {
        await this.waitForHsdUpdate();
        if (this.hsdUpdateIndicator) {
          let rampUpEndDate = new Date().toISOString();
          let item: WorkQ = new WorkQ();
          item.id = this.workqId.toLocaleLowerCase();
          item.createdBy.wwid = this.createdByWwid;

          item.updatedBy = new Person();
          item.hsdReason = 'released';

          item.updatedBy.wwid = appSettings.appUserWwid;
          item.updatedBy.name = appSettings.appUserFullname;
          item.updatedBy.email = appSettings.appUserEmailId;
          item.buildingBlocks = this.simplifiedBBList;

          const dataChanges = [
            { value: 'execution complete', path: 'mode', op: 'replace' },
            { value: rampUpEndDate, path: 'rampUpEndDate', op: 'replace' },
            { value: item.updatedBy, path: 'updatedBy', op: 'replace' },
            { value: item.hsdReason, path: 'hsdReason', op: 'replace' },
            { value: item.buildingBlocks, path: 'buildingBlocks', op: 'replace' }
          ]
          let comments = `<p>Exection Complete for ${this.workqNumber}</p><br>${this.progressComment}`
          let alertMessage = `HSD# : ${this.hsdId} <br> <ul><li>Work-Q execution completed!</li> <li>HSD ticket status has been updated to 'complete'</li></ul>`;
          await this.updateWorkQ(item, dataChanges, comments, alertMessage);

          this.exportData[this.selectedRowIndex].mode = 'EXECUTION COMPLETE';
          this.mode = 'EXECUTION COMPLETE';
          this.enableSubmitAndComplete = false;
          this.showLmMessage = false; //Ken add this LM error message
          this.isReasonButtonDisabled = true;
          this.isExecutionComplete = true;
          this.enableSaveConfig = true;
          this.isReasonButtonDisabled = true;

          if (this.windowOpened) {
            this.windowOpened = false;
          }
        }
        else {
          this.isOverlayLoading = false;
          this.alertDialogMessage = "Unable to update HSD-ES. Please refresh RM and try again. If issue persists, please contact us"
          this.alertDialogOpen = true;
        }

        //Clear Session value of LM Integration ID
        if (sessionStorage.getItem("IntegrationID") != null) {
          sessionStorage.removeItem("IntegrationID");
        }

      }
    }
    else {

    }

  }

  public updateCommentClick() {
    var hsdComments: string = "";
    hsdComments = this.form.controls['comment'].value;

    if (hsdComments.replace(/\s/g, "") != '') {
      this.isOverlayLoading = true;
      this.commentsApi.insertComment({ tenant: 'services_sys_val', parent_id: this.hsdId, fieldValueMap: { title: 'new Comment from RM', description: this.form.controls['comment'].value, send_mail: 'true' } }).subscribe(result => {
        this.form.reset();
        this.isOverlayLoading = false;
        this.alertDialogMessage = `HSD# : ${this.hsdId}<br> Comment has been updated successfully!`;
        this.alertDialogOpen = true;
      }), ((error: { error: Error; }) => {
        this.isOverlayLoading = false;
        alert("An error was encountered while attempting to inserting comment fields: " + error.error.message);
        console.log(error.error);
      });
    }
  }

  //#endregion


  //#region HSD functions
  private async queryHsdData() {
    const getTCsQuery = `SELECT support.site, title, tag, id, eta, eta_request, rev, owner, description, comments, priority, status, reason, submitted_by, submitted_date, updated_by, updated_date WHERE id = '${this.hsdId}'`;

    if (this.filterGetHsdEsArticlesSubscription) {
      this.filterGetHsdEsArticlesSubscription.unsubscribe();
    }

    const hsdUpdateDate = new Date().toISOString();
    var hsdArticle: any;
    await new Promise<void>((resolve, reject) => {
      this.filterGetHsdEsArticlesSubscription = this.queryApi.getRecordsByEql(getTCsQuery).subscribe((result: any) => {
        hsdArticle = result[0];
        this.hsdRevNumber = hsdArticle.rev;
        this.hsdTag = "";
        this.hsdTag = hsdArticle.tag;
        result.forEach((hsdEsTicket: any) => {
          this.hsdDescription = hsdEsTicket.description;
          this.hsdOwner = hsdEsTicket.owner;

          if (hsdEsTicket.eta !== "" && hsdUpdateDate > hsdEsTicket.eta) {
            this.alertDialogMessage = `ETA date dued for HSD <b>#${this.hsdId}</b>., Please update ETA date to 'future' or 'null' on HSD-ES website.`;
            this.alertDialogOpen = true;
            reject(new Error("ETA date dued"));
          }
        });
        resolve();
      });
    });
  }

  private async insertComment(comments: string) {
    //this.workqUrlComment = `<br><p>To view <b>${this.workqNumber}</b>: <a href="${environment.urlBase}#/work-q" target="_blank">Click Here</a></p>`;
    this.workqUrlComment = `<br><p>To view <b>${this.workqNumber}</b>: <a href="${environment.urlBase}#/work-q/${this.workqNumber}" target="_blank">Click Here</a></p>`;
    this.commentsApi.insertComment({
      tenant: 'services_sys_val',
      parent_id: this.hsdId,
      fieldValueMap: {
        title: 'WorkQ Progress Updated',
        description: comments + this.workqUrlComment,
        send_mail: 'true',
      },
    }).subscribe({
      next: (result) => {

      },
      error: (e) => {
        alert(`Error updating progress in HSDES\n\n${e.error.toString()}`)
        this.notificationService.addErrorNotification('HSD Ticket Failed to update', `Unable to update HSD ${this.hsdId}`);
      },
      complete: () => {
        this.notificationService.addInfoNotification('HSD Comment Updated Successfully', `HSD ${this.hsdId} has been updated.`);
      }
    });
  }
  private async updateHsdRecords(records: any) {
    this.hsdUpdateIndicator = false;
    new Promise<void>((resolve, reject) => {
      let attempts = 0;
      const maxAttempts = 3;

      const update = () => {
        console.log(attempts);
        this.recordApi.updateRecordWithFetch(this.hsdId, "services_sys_val", "support", this.hsdRevNumber, records).subscribe(result => {
          if (result) {
            this.notificationService.addInfoNotification('HSD Records Updated Successfully', `HSD ${this.hsdId} has been updated.`);
            this.hsdUpdateStatus = true;
            this.hsdUpdateIndicator = true;
            resolve();
          }
          else {
            if (attempts < maxAttempts) {
              attempts++;
              update();
            } else {
              this.notificationService.addErrorNotification('Something is wrong', `Unable to update HSD ${this.hsdId}`);
              reject();
            }
          }
        }, ((error: { error: Error; }) => {
          if (attempts < maxAttempts) {
            attempts++;
            update();
          } else {
            this.hsdUpdateStatus = false;
            this.hsdUpdateIndicator = true;
            alert(`${JSON.stringify(error)}`)
            this.notificationService.addErrorNotification('HSD Ticket Records failed to update', `Unable to update HSD ${this.hsdId}`);
            reject();
          }
        }))
      };

      update();
    })
  }
  // private async updateHsdRecords(records: any){
  //   new Promise<void>((resolve, reject)=>{
  //     this.recordApi.updateRecordWithFetch(this.hsdId, "services_sys_val", "support", this.hsdRevNumber, records).subscribe( result=>{
  //       if (result){
  //         this.notificationService.addInfoNotification('HSD Records Updated Successfully', `HSD ${this.hsdId} has been updated.`);
  //         this.hsdUpdateIndicator = true;
  //       }
  //       else{
  //         this.notificationService.addErrorNotification('Something is wrong', `Unable to update HSD ${this.hsdId}`);
  //       }
  //     },((error: { error: Error; }) =>{
  //       this.hsdUpdateIndicator = false;
  //       console.log(this.hsdUpdateIndicator)
  //       alert(`${JSON.stringify(error)}`)
  //       this.notificationService.addErrorNotification('HSD Ticket Records failed to update', `Unable to update HSD ${this.hsdId}`);
  //       //throw new Error("Unable to update HSDES\n"+error);
  //     }))
  //     resolve();
  //   })

  // }

  //#endregion


  private async updateWorkQ(item: WorkQ, dataChanges: any[], comments: any, alertMessage: string) {
    this.createGateway.updateWorkQ(item, dataChanges).subscribe({
      next: (result) => {
        // this.queryHsdData()
      },
      error: (e) => {
        this.isOverlayLoading = false;
        alert('Unable to update Work-Q\n\n' + e.error.toString());
      },
      complete: () => {
        this.isOverlayLoading = false;
        this.insertComment(comments);
        this.alertDialogMessage = alertMessage;
        this.alertDialogOpen = true;
        this.enableSave = false
      }
    });
  }

private updateWorkQReasonUI(item: WorkQ, dataChanges: any[], comments: any, alertMessage: string): Promise<void> {
  return new Promise((resolve, reject) => {
    this.createGateway.updateWorkQ(item, dataChanges).subscribe({
      next: (result) => {
        // Optionally handle result
      },
      error: (e) => {
        this.isOverlayLoading = false;
        alert('Unable to update Work-Q\n\n' + e.error.toString());
        reject(e); // Reject the promise with the error
      },
      complete: () => {
        this.isOverlayLoading = false;
        this.insertComment(comments);
        this.alertDialogMessage = alertMessage;
        this.alertDialogOpen = true;
        this.enableSave = false;
        resolve(); // Resolve the promise when the operation is complete
      }
    });
  });
}

  private updateWorkQReason(item: WorkQ, dataChanges: any[]): Promise<any> {
    return new Promise((resolve, reject) => {
      this.createGateway.updateWorkQ(item, dataChanges).subscribe({
        next: (result) => {
          // Optionally handle result
          resolve(result); // Resolve the promise with the result
        },
        error: (e) => {
          this.isOverlayLoading = false;
          alert('Unable to update Work-Q\n\n' + e.error.toString());
          reject(e); // Reject the promise with the error
        },
        complete: () => {
          // If you need to do something on completion regardless of success/error,
          // but don't want to resolve until here, you can call resolve() here without arguments.
          // Otherwise, you might not need this block if you're resolving in `next`.
        }
      });
    });
  }

  private async waitForHsdUpdate() {
    while (!this.hsdUpdateIndicator) {
      await new Promise((resolve) => setTimeout(resolve, 500));
    }
  }

  public async sleepAndUpdateRampUp(): Promise<void> {
    // Sleep for 10 seconds (10000 milliseconds)
    await new Promise((resolve) => setTimeout(resolve, 10000));

    // Update the boolean to true
    this.rampUpTimer = true;

    console.log("Boolean is now true after a 10-second sleep:", this.rampUpTimer);
  }

  public lstRmLabValueChange(value: string): void {
    //this.isEmpty = true;
    this.rmSelectedLab = value;
    //this.isSearchDisabled = false;
    // sessionStorage.setItem("workqSelectedLab", JSON.stringify(this.rmSelectedLab)); // WH 27Sep2024 - Commented this line to avoid saving the selected lab in session storage
  }

  public searchGridfilterChange(filter: CompositeFilterDescriptor): void {
    // Clear selection
    this.mySelection = []
    this.buildingBlockData = [];
    this.recipeNumber = "";
    this.recipeName = "";
    this.enableSubmitAndComplete = false;
    this.showLmMessage = false; //Ken add this LM error message
    this.hsdId = "";
    this.isExecutionComplete = true;
    this.enableStartRampUp = false;
    this.enableStartRampDown = false; //Ken add this for Ramp Down
    //this.exportData = this.searchGridData
    this.exportData = filterBy(this.searchGridData, filter);

  }

  public checkListGridfilterChange(filter: CompositeFilterDescriptor): void {
    //Place holder for functional implementation
  }

  public lstRmExecutionTypeValueChange(value: any): void {
    this.selectedExecutionType = value;
    sessionStorage.setItem("workqSelectedExecutionType", JSON.stringify(this.selectedExecutionType));
  }

  public lstHSDOwnersValueChange(value: string): void {
    this.selectedHSDOwner = value;
    sessionStorage.setItem("workqSelectedHSDOwner", JSON.stringify(this.selectedHSDOwner));
  }
  public lstCoownersValueChange(value: string): void {
    this.selectedCoOwner = value;
    sessionStorage.setItem("workqSelectedCoOwner", JSON.stringify(this.selectedCoOwner));
  }

  public onSwitchChange(value: any): void {

    this.isExpertMode = value;
  }

  public wordSearchValueChange(value: any): void {

    // (<HTMLInputElement>document.getElementById('searchBox')).readOnly = false;
    if (value == 'WorkQ#') {
      this.wordSearchFilterSelected = 'workq#';
      (<HTMLInputElement>document.getElementById('searchBox')).value = "";
      (<HTMLInputElement>document.getElementById('searchBox')).placeholder = "WQ-23-25-2";
      this.isSearchBoxDisabled = false;
      //(<HTMLInputElement>document.getElementById('searchBox')).disabled = false;
    }
    else if (value == 'HSD#') {
      this.wordSearchFilterSelected = 'hsd#';
      (<HTMLInputElement>document.getElementById('searchBox')).value = "";
      (<HTMLInputElement>document.getElementById('searchBox')).placeholder = "1606112951";
      this.isSearchBoxDisabled = false;
      //(<HTMLInputElement>document.getElementById('searchBox')).disabled = false;
    }
    else if (value == 'Recipe#') {
      this.wordSearchFilterSelected = 'recipe#';
      (<HTMLInputElement>document.getElementById('searchBox')).value = "";
      (<HTMLInputElement>document.getElementById('searchBox')).placeholder = "RC-23-24-7";
      this.isSearchBoxDisabled = false;
      //(<HTMLInputElement>document.getElementById('searchBox')).disabled = false;
    }
    else if (value == 'Mode') {
      this.wordSearchFilterSelected = 'mode';
      (<HTMLInputElement>document.getElementById('searchBox')).value = "";

      // Author: Rajesh 
      // Purpose: commented the below code as Execution Complete status moved as separate search option called COMPLETED in ddl
      // Date: 30 Sep'24

      //(<HTMLInputElement>document.getElementById('searchBox')).placeholder = "NOT STARTED/EXECUTION STARTED/EXECUTION COMPLETE"; 
      (<HTMLInputElement>document.getElementById('searchBox')).placeholder = "NOT STARTED/EXECUTION STARTED";
      this.isSearchBoxDisabled = false;
      //(<HTMLInputElement>document.getElementById('searchBox')).disabled = false;
    }
    else if (value == 'Completed') {
      this.wordSearchFilterSelected = 'completed';
      (<HTMLInputElement>document.getElementById('searchBox')).value = "";
      (<HTMLInputElement>document.getElementById('searchBox')).placeholder = "Completed WorkQ(s)";
      this.isSearchBoxDisabled = true;
      //(<HTMLInputElement>document.getElementById('searchBox')).disabled = true;
    }
    else {
      this.wordSearchFilterSelected = 'all';
      this.isFilterVisible = true;
      (<HTMLInputElement>document.getElementById('searchBox')).value = "";
      (<HTMLInputElement>document.getElementById('searchBox')).placeholder = "Search By 'WorkQ#','HSD#','Recipe#', 'Mode'";
      this.isSearchBoxDisabled = true;
      // (<HTMLInputElement>document.getElementById('searchBox')).readOnly = true;
      //(<HTMLInputElement>document.getElementById('searchBox')).disabled = true;
    }
  }

 public async fillLMTab(){
   if (this.workqNumber != '' && this.workqGridRecipeNumber != '') {
     this.resetLMValues();
     await this.getToolsIntegrationData(this.workqNumber, this.workqGridRecipeNumber);
     await this.delay(1500);
   }
   else {
     this.btnResetLMTab();
   }
 }

  //On Selected row of WorkQ Grid, populate Building Block details on right hand side
  gridWorkqSelectionChange(selection: any) {
    this.selectedRowIndexRefresh = selection; //Ken add this to refresh the selected row index
    this.selectedRowIndex = selection.selectedRows[0].index //retrieve index of the selected row
    const selectedData = selection.selectedRows[0].dataItem;
    this.form.reset(); //clear any text in the hsd comments box
    this.populateWorkQDetails(selectedData);
  }

  checkListGridSelectionChange(selection: any) {
    const selectedData = selection.selectedRows[0].dataItem;
    this.populateWorkQDetails(selectedData);
  }
  public getProgress(BBId: string) {
    const foundBB = this.simplifiedBBList.find(x => BBId === x.buildingBlockId.toLowerCase());
    if (foundBB?.progress == 'NOT STARTED') {
      return -1;
    }
    else if (foundBB?.progress == 'IN PROGRESS') {
      return 0;
    }
    else if (foundBB?.progress == 'COMPLETED') {
      return 1;
    }
    else {
      return 2;
    }
  }
  public async populateWorkQDetails(rmDataItem: DataItem) {
    this.checkListGridData = [];
    this.idsid = '';

    try {
      const selectedData: DataItem = rmDataItem;
      this.simplifiedBBList = selectedData['buildingBlocks' as keyof DataItem] != undefined ? selectedData['buildingBlocks' as keyof DataItem] as SelectedBuildingBlock[] : [];
      this.selectedOwnerName = selectedData['hsdOwnerName' as keyof DataItem] != undefined ? selectedData['hsdOwnerName' as keyof DataItem].toString() : "";
      this.selectedOwnerEmailId = selectedData['hsdOwnerEmail' as keyof DataItem] != undefined ? selectedData['hsdOwnerEmail' as keyof DataItem].toString() : "";
      this.selectedOwnerWwid = selectedData['hsdOwnerWwid' as keyof DataItem] != undefined ? selectedData['hsdOwnerWwid' as keyof DataItem].toString() : "";
      this.recipeId = selectedData['recipeId' as keyof DataItem] != undefined ? selectedData['recipeId' as keyof DataItem].toString() : "";
      this.workqGridRecipeNumber = selectedData['recipeNumber' as keyof DataItem] != undefined ? selectedData['recipeNumber' as keyof DataItem].toString() : "";
      this.hsdId = selectedData['hsdId' as keyof DataItem] != undefined ? selectedData['hsdId' as keyof DataItem].toString() : "";
      this.hsdTitle = selectedData['hsdTitle' as keyof DataItem] != undefined ? selectedData['hsdTitle' as keyof DataItem].toString().toLocaleLowerCase() : "";
      this.hsdReason = selectedData['hsdReason' as keyof DataItem] != undefined ? selectedData['hsdReason' as keyof DataItem].toString().toLocaleLowerCase() : ""; //Ken add this to populate HSD Reason field
      this.workqCategory = selectedData['category' as keyof DataItem] != undefined ? selectedData['category' as keyof DataItem].toString().toLocaleLowerCase() : ""; //Ken add this to populate Category field
      this.workqId = selectedData['id' as keyof DataItem] != undefined ? selectedData['id' as keyof DataItem].toString() : "";
      this.workqNumber = selectedData['workqNumber' as keyof DataItem] != undefined ? selectedData['workqNumber' as keyof DataItem].toString() : "";
      this.createdByWwid = selectedData['createdByWwid' as keyof DataItem] != undefined ? selectedData['createdByWwid' as keyof DataItem].toString() : "";
      this.mode = selectedData['mode' as keyof DataItem] != undefined ? selectedData['mode' as keyof DataItem].toString() : "";

      this.graphOwnerUsers(this.selectedOwnerEmailId)
      // console.log(JSON.stringify(this.simplifiedBBList, null, 4))
      this.simplifiedBBList.forEach(x => {
        this.getProgress(x.buildingBlockId)
        this.checkListGridData[x.buildingBlockId.toLowerCase()] = x.checkList

      })

      this.compareReasonTitleClick(this.hsdId, this.hsdReason, this.workqId, this.hsdTitle); //Ken add this to compare HSD Reason and WorkQ Reason
      console.log("WorkQ Category: " + this.workqCategory);

     await this.fillLMTab();
     //await this.delay(2000);

     if(this.workqCategory.toLowerCase() === 'ramp down' || this.workqCategory.toLowerCase() === 'power-on readiness')
      {
        this.isBoardSnHidden = true; //Ken add this to hide Board SN asterisk to become optional for Ramp Down
      }
      else
      {
        this.isBoardSnHidden = false; //Ken add this to show Board SN asterisk to become mandatory for Ramp Up
      }

      if (this.selectedOwnerName !== '') {
        this.isWorkQLoaded = true;
        this.isLabManagerLoaded = true;
        this.isLabManagerSelected = false;
        this.isAssignable = false;
        this.isReasonButtonDisabled = false;
      } else {
        this.selectedforHSDOwner = {} as GraphUsers; // Initialize with the correct type
        this.isWorkQLoaded = false;
        this.isLabManagerLoaded = false;
        this.isLabManagerSelected = false;
        this.isAssignable = false;
        this.isReasonButtonDisabled = true;
      }

      if (this.mode.toLowerCase() === 'not started') {
        if(this.workqCategory.toLowerCase() === 'ramp down')
        {
          this.enableStartRampUp = false;
          this.enableStartRampDown = true;
        }
        else
        {
          this.enableStartRampUp = true;
          this.enableStartRampDown = false;
        }
        //this.enableStartRampUp = true;
        this.isExecutionComplete = false;
        this.enableSaveConfig = false;
        //this.isReasonButtonDisabled = false;
      }
      else if (this.mode.toLowerCase() === 'execution complete') {
        this.enableStartRampUp = false;
        this.enableStartRampDown = false;
        this.isExecutionComplete = true;
        this.enableSave = false;
        this.enableSubmitAndComplete = false;
        this.showLmMessage = false; //Ken add this LM error message
        this.enableSaveConfig = true;
        this.isReasonButtonDisabled = true;
        this.lmControlReadOnly = true; //Make readonly for LM site, host, project, owner, activity, focus, description and activestate fields once Execution Complete.
      }
      else if (this.mode.toLowerCase() === 'execution started') {
        this.enableStartRampUp = false;
        this.enableStartRampDown = false;
        this.isExecutionComplete = false;
        this.enableSaveConfig = false;
        this.isReasonButtonDisabled = false;
        this.lmControlReadOnly = false;
      }



      let params: any = {};
      params['recipeId'] = this.recipeId;
      params['isArchived'] = false;
      params['isTemplate'] = false;
      params['isDraft'] = undefined;

      this.searchGateway.getRecipes(params).subscribe((result) => {
        this.recipeNumber = result[0].recipeNumber;
        this.recipeName = result[0].recipeName;
        this.recipeOwner = [{ name: result[0].ownerName, email: result[0].ownerEmail, wwid: result[0].ownerWwid }];
        this.recipeCoOwner = result[0].coOwner
      }, ((error => {
        alert("An error was encountered while attempting to pull recipe information: " + error.error);
      })));


    }
    catch (Error) {
      console.log(Error);
      alert(Error);
    }

    this.buildingBlockData = [];
    this.totalChecklist = this.remainingChecklist = 0;

    if (this.simplifiedBBList.length > 0) {
      var BBListIds = this.simplifiedBBList.map(x => x.buildingBlockId);
      this.isEmpty = false
      this.populateWorkQDetailPerSelectedRow(BBListIds);
    }



  }

  public exportToExcel(grid: GridComponent): void {
    grid.saveAsExcel();
  }

  public allRequestData(): ExcelExportData {
    const result: ExcelExportData = {
      data: this.exportData,

    };
    return result;
  }

  //Rajesh commented the below code as it is no longed needed.
  //Commented On: 31 March 2024

  /*public confirmDeleteWorkQ(): void {
    if (confirm('Are you sure to delete workq?') == true) {

    } else {

    }
  }*/


  public deleteWorkQ(workqId: string, workqNumber: string, createdByWwid: string, hsdId: string): void {
    if (confirm('Are you sure to delete workq ' + workqNumber + '?') == true) { //Confirmation to delete workq
      let item: WorkQ = new WorkQ();
      item.id = workqId;
      item.createdBy.wwid = createdByWwid;
      item.updatedBy.wwid = appSettings.appUserWwid;
      item.updatedBy.name = appSettings.appUserFullname;
      item.updatedBy.email = appSettings.appUserEmailId;
      this.isOverlayLoading = true; // Rajesh added
      this.createGateway.deleteWorkQ(item).subscribe({
        next: (result) => {
          this.commentsApi.insertComment({ tenant: 'services_sys_val', parent_id: hsdId, fieldValueMap: { title: 'new Comment from RM', description: this.workqNumber + ' is archived by ' + item.updatedBy.name, send_mail: 'true' } }).subscribe(result => {
            this.alertDialogMessage = `WorkQ ` + workqNumber + ` successfully archived!`
            this.alertDialogOpen = true;
            this.search(); // Rajesh added to refresh the grid data automatically once workq has been archived.
            this.isOverlayLoading = false; // Rajesh added
          }), ((error: { error: Error; }) => {
            this.isOverlayLoading = false; // Rajesh added
            alert("An error was encountered while attempting to inserting comment fields: " + error.error.message);
            console.log(error.error);
          });
        },
        error: (e) => {
          this.isOverlayLoading = false; // Rajesh added
          alert('Delete WorkQ Module Error\n\n' + e.error.toString());
        },
        complete: () => {
        }
      });
    }
    else {

    }
  }

  areAllRequiredChecked(event: any): void {

    this.totalNumberOfRequiredCheckedItems = 0;
    this.totalNumberOfOptionalCheckedItems = 0;
    this.totalChecklist = 0;

    this.simplifiedBBList.forEach(x => {
      if (x.checkList) { //this is required since checklist is optional for BB creations
        // Get the count of required items that are checked
        let numberOfRequiredCheckedItems = x.checkList.filter(item => item.isRequired && !item.isChecked && item.type.toLocaleLowerCase() != 'header').length;
        let numberOfOptionalCheckedItems = x.checkList.filter(item => !item.isRequired && !item.isChecked && item.type.toLocaleLowerCase() != 'header').length;
        // Get total checklists
        let checklistnum = x.checkList.filter(item => item.type.toLocaleLowerCase() != 'header').length;

        this.totalNumberOfRequiredCheckedItems += numberOfRequiredCheckedItems
        this.totalNumberOfOptionalCheckedItems += numberOfOptionalCheckedItems
        this.totalChecklist += checklistnum
      }
    })

    //if (this.totalNumberOfRequiredCheckedItems == 0 && this.mode.toLocaleLowerCase() != 'execution complete')
    if (this.totalNumberOfRequiredCheckedItems == 0 && this.mode.toLocaleLowerCase() != 'execution complete')
    {
      this.displayWorkqWarningMessage();
      this.enableSubmitAndComplete = this.showLmMessage == false? true : false;
    }
    else if(this.totalNumberOfRequiredCheckedItems == 0 && this.mode.toLocaleLowerCase() == 'execution complete'){
      this.enableSubmitAndComplete = false;
      this.showLmMessage = false;

    }
    else{
      if(this.totalNumberOfRequiredCheckedItems == 0){
        this.displayWorkqWarningMessage();
        this.enableSubmitAndComplete = this.showLmMessage == true? false : true;
      }
      else{
        this.enableSubmitAndComplete = false;
      }
    }
  }

  updateBuildingBlocksProgress(buildingBlocks: SelectedBuildingBlock[]): void {
    this.progressComment = buildingBlocks
      .map((block) => {
        const allRequiredItemsChecked = block.checkList.filter(item => item.type.toLocaleLowerCase() != 'header' && item.isRequired).every(
          (item) => item.isRequired && item.isChecked
        )

        if (allRequiredItemsChecked) {
          block.progress = "COMPLETED";
        }
        else if (block.checkList.some((item) => item.isChecked)) {
          block.progress = "IN PROGRESS";
        }
        else {
          block.progress = "NOT STARTED";
        }

        const matchingBuildingBlock = this.buildingBlockData.find(
          (x) => x.id.toLowerCase() === block.buildingBlockId.toLowerCase()
        );

        if (matchingBuildingBlock) {
          return `<p>[${matchingBuildingBlock.buildingBlockNumber}] ${matchingBuildingBlock.buildingBlockName} - [<b>${block.progress}</b>]</p>`;
        }
        return null; // Handle cases where there is no match
      })
      .filter((progressComment) => progressComment !== null)
      .join('\n');
  }


  public async populateWorkQDetailPerSelectedRow(bbListIds: string[]) {
    this.isDetailsLoading = true;
    this.initial_buildingBlockData = [];
    this.buildingBlockData = [];
    this.totalChecklist = this.remainingChecklist = 0;
    this.checkListGridLoading = true;

    bbListIds = bbListIds.filter((value, index)=>bbListIds.indexOf(value) === index); // Remove duplicates

    this.searchGateway.getBuildingBlockDetailsForBBList(bbListIds).subscribe(result => {
      this.displayWorkqWarningMessage();
      this.areAllRequiredChecked(false);
      //this is to map the query BB items to follow the order from the recipe
      this.initial_buildingBlockData = result; //results returned from BB container query
      this.buildingBlockData = this.simplifiedBBList //this.simplifiedBBList contains the correct order BB from workq container
        .map(x => x.buildingBlockId.toLowerCase())
        .map(id => this.initial_buildingBlockData.find(y => y.id.toLowerCase() === id))
        .filter((x: BuildingBlock | undefined): x is BuildingBlock => x !== undefined);

      this.buildingBlockData.forEach(y => {
        this.checkList = y.checkList;
        if (this.checkList) {
          this.checkListContentLoading[y.id] = { loaded: false, progress: 0 };

        }
        this.checkListGridLoading = false;
        this.isDetailsLoading = false;
        //this.isLabManagerLoaded = false;

      })

      //============================================================================================
      // Author: Rajesh
      // Purpose: To get the combined PDF recipe URL to be updated in Lab Manager
      // Date: 16 August
      if(this.buildingBlockData != null){
        this.buildingBlockData = this.buildingBlockData.filter((value, index)=>this.buildingBlockData.indexOf(value) === index);
        var contents: string[] = [];
        let bbNumbers: string[] = [];
        this.buildingBlockData.forEach(x => {
          contents.push(x.content);
          bbNumbers.push(x.buildingBlockNumber);
        })
        this.getCombinedPdf(contents, bbNumbers);
      }

    });
  }

  private getCombinedPdf(contents: string[], bbNumbers: string[]) {

    if (contents.length > 0) {
      this.lmRecipeURL = this.fileGateway.getUrlforFiles('combinedPdfs', contents, bbNumbers, this.recipeId);
      }
    }

       //=============================================================================================


  private padTo2Digits(num: Number): string {
    return num.toString().padStart(2, '0');
  }

  private formatDate(date: Date): string {
    return (
      [
        date.getFullYear(),
        this.padTo2Digits(date.getMonth() + 1),
        this.padTo2Digits(date.getDate()),
      ].join('-') +
      ' ' +
      [
        this.padTo2Digits(date.getHours()),
        this.padTo2Digits(date.getMinutes()),
        this.padTo2Digits(date.getSeconds()),
      ].join(':')
    );
  }


  //click event of search button, gets data from db and populates workq grid depending on serach filter criteria
  public search(btnClicked: boolean = false, hsdUrl: boolean = false): void {
    this.isSearchLoading = true;
    let searchValue: string = "";
    let searchType: string = "";
    this.searchGridLoading = true;
    //this.checkListGridLoading = true;
    this.workqGridLoading = true;

    this.buildingBlockData = [];
    this.recipeNumber = "";
    this.recipeName = "";
    this.enableSubmitAndComplete = false;
    this.hsdId = "";
    this.isEmpty = true;
    this.isExecutionComplete = true;
    this.enableStartRampUp = false;
    this.enableStartRampDown = false;
    this.searchGridData = [];
    // Clear grid selection
    this.mySelection = [];
    this.isWorkQLoaded = false;
    this.isLabManagerLoaded = false;
    this.isLabManagerSelected = false;
    this.idsid = '';
    this.isAssignable = false;
    this.isEmpty = true;

    if (btnClicked) {
      //Id WorkQId starts with WQ-##-#-##
      if(hsdUrl)
      {
        searchValue = this.lastSegment;
        this.txtSearch = this.lastSegment;
        this.isSearchBoxDisabled = false;
      }
      else
      {
        searchValue = (<HTMLInputElement>document.getElementById('searchBox')).value;
      }
    }


    searchType = this.wordSearchFilterSelected.toLowerCase().replace(' ', '');

    if (searchType === 'all') {

      //with advanced filter search option.
      this.searchGateway.getWorkQSearch(this.rmSelectedLab.toLowerCase() === 'all' ? '' : this.rmSelectedLab.toLowerCase(), '', '', '', '', '', this.selectedHSDOwner.toLowerCase() === 'all' ? '' : this.selectedHSDOwner.toLowerCase(), this.formatDate(this.fromDate), this.formatDate(this.toDate)).subscribe(result => {
        if (result.length > 0) {
          //this.isEmpty = false;
          result = (result != null || result != "") ? result.filter(a => a.isArchived != true) : result; //Rajesh added to filter active records only to display in grid
          this.workqData = result;
          this.searchGridData = this.gridRowToUpper(this.handleComplexColumns(this.workqData));
          this.workqGridLoading = false;
        }
        else {
          this.searchGridData = [];
          //this.isEmpty = true;
          this.workqGridLoading = false;
        }
        this.isSearchLoading = false;
        this.exportData = this.searchGridData;

      }, ((error => {
        alert("An error was encountered while attempting to pull workq information: " + error.error);
        this.searchGridLoading = false;
        this.checkListGridLoading = false;
      })));


    }
    else {
      //with out advanced filter search option
      this.searchGateway.getWorkQSearch('', searchType === 'workq#' ? searchValue : '', searchType === 'hsd#' ? searchValue : '', searchType === 'mode' ? searchValue : '', searchType === 'category' ? searchValue : '', searchType === 'recipe#' ? searchValue : '', '', '', '', searchType === 'completed' ? true : false).subscribe(result => {
        if (result.length > 0) {
          //this.isEmpty = false;
          result = (result != null || result != "") ? result.filter(a => a.isArchived != true) : result; //Rajesh added to filter active records only to display in grid
          this.workqData = result;
          this.searchGridData = this.gridRowToUpper(this.handleComplexColumns(this.workqData));
          this.workqGridLoading = false;

        }
        else {
          this.searchGridData = [];
          //this.isEmpty = true;
          this.workqGridLoading = false;
        }
        this.isSearchLoading = false;
        this.exportData = this.searchGridData;


      }, ((error => {
        alert("An error was encountered while attempting to pull workq information: " + error.error);
        this.searchGridLoading = false;
        this.checkListGridLoading = false;
      })));

    }

  }

  //Below method converts grid row value to upper case to all columns except comments and email columns
  gridRowToUpper(obj: any) {
    for (var prop in obj) {
      if (typeof obj[prop] === 'string') {
        if ([prop].toString() != "comments" && [prop].toString() != "email" && [prop].toString() != "icNotes") {
          obj[prop] = obj[prop].toUpperCase();
        }
      }

      //Commented the below line to escape CheckList items from being converted to UpperCase as customer wants the original format
      //Ticket Ref: https://hsdes.intel.com/appstore/article/#/22020022831
      //Date: 06 May'24
      //Author: Rajesh

      //if (typeof obj[prop] === 'object') {

      if (typeof obj[prop] === 'object' && prop.toString().toLowerCase() != "checklist") {
      //if (typeof obj[prop] === 'object') {
        this.gridRowToUpper(obj[prop]);
      }
    }
    return obj;
  }

  workqNumberCellEvent(workqNumber: number) {
    //Place holder to be implemented later
  }

  hsdCellEvent(hsdId: string) {
    var url = environment.services.HsdEsWebUrl;
    url += "article/#/" + hsdId;
    window.open(url);
  }

  public distinctPrimitive(fieldName: string): any {
    return distinct(this.searchGridData, fieldName).map(item => item[fieldName]).sort();
  }

  private handleComplexColumns(data: any[]): any[] {
    let currentDate = new Date();

    var formattedData: any[] = [];
    data.forEach((wq: any) => {
      wq.labs = this.getJoinedValues(wq.labs);
      switch (wq.mode.toLowerCase()) {
        case 'not started':
          wq.daysInWip = 0;
          break;
        case 'execution started':
          wq.daysInWip = Math.floor((currentDate.getTime() - new Date(wq.rampUpStartDate).getTime()) / 1000.0 / 60.0 / 60.0 / 24.0);
          break;
        case 'execution complete':
          wq.daysInWip = Math.floor((new Date(wq.rampUpEndDate).getTime() - new Date(wq.rampUpStartDate).getTime()) / 1000.0 / 60.0 / 60.0 / 24.0);
          break;
        default:
          break;
      }
      formattedData.push(wq);
    });
    return formattedData;
  }

  public getJoinedValues(values: any, isEmail?: boolean): string {
    var value: string = "";
    if (isEmail != undefined && isEmail == true) {
      value = values.filter((item: any) => item != null).map((filtered: any) => filtered).join(', ');
    }
    else {
      value = values.filter((item: any) => item.name != null).map((filtered: any) => filtered.name).join(', ');
    }
    return value;
  }

  public onProgress(progressData: PDFProgressData, id: any) {
    this.checkListContentLoading[id].loaded = progressData.loaded;
    this.checkListContentLoading[id].progress = progressData.total;
  }

  public isUserAdmin(): boolean {
    return appSettings.isUserAMemberOf("RM_Admins");

  }

    //============ Lab Manager Code =============


  public getLMSites(): void {
      this.thirdPartyAppGateway.getLMSites(true).pipe(takeUntil(this.destroy$)).subscribe({
        next: (result) => {
          let sites: any[] = [];
          let sitesList: any[] = [];
          sitesList = JSON.parse(JSON.stringify(result));
          sitesList.forEach(item => {
            sites.push(item.name);
          });
          this.lmSiteList = sites.filter((n, i) => sites.indexOf(n) === i).sort();
          //console.log("==== Site =====");
          //console.log(this.lmSiteList);
        },
        error: (e) => {
          alert('Sites Search Error\n\n' + e.error?.toString());
        },
        complete: () => {

        }
      });
  }

  public getLMActivities(): void {
      this.thirdPartyAppGateway.getLMActivities(true).pipe(takeUntil(this.destroy$)).subscribe({
        next: (result) => {
          let activities: any[] = [];
          let activitiesList: any[] = [];
          activitiesList = JSON.parse(JSON.stringify(result));
          activitiesList.forEach(item => {
            activities.push(item.name);
          });

          this.lmActivitiesList = activities.filter((n, i) => activities.indexOf(n) === i).sort();
          //console.log("==== Activities =====");
          //console.log(this.lmActivitiesList);
        },
        error: (e) => {
          alert('Activities Search Error\n\n' + e.error?.toString());
        },
        complete: () => {

        }
      });
  }

  public getLMProjects(site: string): void {
      this.thirdPartyAppGateway.getLMProjects(site,true).pipe(takeUntil(this.destroy$)).subscribe({
        next: (result) => {
          let projects: any[] = [];
          //Enable the below codes (Line#: 3869 - 3873) to bind only the project names
          //Disable the line 3875 if decided to bind the project names
          /*let projectList: any[] = [];
          projectList = JSON.parse(JSON.stringify(result));
          projectList.forEach(item => {
            projects.push(item.name);
          });*/
          projects = JSON.parse(JSON.stringify(result));
          this.lmProjectList = projects.filter((n, i) => projects.indexOf(n) === i).sort();
          //console.log("==== Projects =====");
          //console.log(this.lmProjectList);
        },
        error: (e) => {
          alert('Projects Search Error\n\n' + e.error?.toString());
        },
        complete: () => {

        }
      });
  }

  public getLMLabs(site: string): void {
      this.thirdPartyAppGateway.getLMLabs(site,true).pipe(takeUntil(this.destroy$)).subscribe({
        next: (result) => {
          let labs: any[] = [];
          let labList: any[] = [];
          labList = JSON.parse(JSON.stringify(result));
          labList.forEach(item => {
            labs.push(item.lab);
          });
          this.lmLabList = labs.filter((n, i) => labs.indexOf(n) === i).sort();
          //console.log("==== Labs =====");
          //console.log(this,this.lmLabList);
        },
        error: (e) => {
          alert('Lab Search Error\n\n' + e.error?.toString());
        },
        complete: () => {

        }
      });
  }

  public getLMValidationTeams(site: string): void {
      this.thirdPartyAppGateway.getLMValidationTeams(site,true).pipe(takeUntil(this.destroy$)).subscribe({
        next: (result) => {
          let vtNames: any[] = [];
          let vtNamesList: any[] = [];
          vtNamesList = JSON.parse(JSON.stringify(result));
          vtNamesList.forEach(item => {
            vtNames.push(item.name);
          });
          this.lmVTNamesList = vtNames.filter((n, i) => vtNames.indexOf(n) === i).sort();
          //console.log("==== VTNames =====");
          //console.log(this.lmVTNamesList);
        },
        error: (e) => {
          alert('Validation Team Search Error\n\n' + e.error?.toString());
        },
        complete: () => {

        }
      });
  }

  public getLMOwners(site: string): void {
      this.thirdPartyAppGateway.getLMOwners(site,true).pipe(takeUntil(this.destroy$)).subscribe({
        next: (result) => {
          let owners: any[] = [];
          let ownerList: any[] = [];
          ownerList = JSON.parse(JSON.stringify(result));
          ownerList.forEach(item => {
            owners.push(item.fullName);
          });
          this.lmOwnerList = owners.filter((n, i) => owners.indexOf(n) === i).sort();
          //console.log("==== Owners =====");
          //console.log(this.lmOwnerList);
        },
        error: (e) => {
          alert('Owner Search Error\n\n' + e.error?.toString());
        },
        complete: () => {

        }
      });
  }

  public async checkLMHostAvailability(hostName: string){
    if(hostName!=null && hostName !=''){
      await this.thirdPartyAppGateway.getLMHostDetails(hostName.trim(), true).pipe(takeUntil(this.destroy$)).subscribe({
        next: (result) => {
          console.log("Host Found\n\n");
          this.isLMHostAvailable = true;

        },
        error: (e) => {
          var name = this.lmHostName;
          this.lmHostName = "";
          this.isLMHostAvailable = false;
          alert("Hostname: "+name+" not found. Please check the name and try again.\n\n");
          console.log("Host not found error:" + e.message?.toString());

        },
        complete: () => {

        }
      });
      }

  }



  public async getLMHostDetails(hostName: string) {
    if (hostName != null && hostName != ''){
      await this.checkLMHostAvailability(this.lmHostName.trim());
      if (this.isLMHostAvailable) {
        this.thirdPartyAppGateway.getLMHostDetails(hostName.trim(), true).pipe(takeUntil(this.destroy$)).subscribe({
          next: (result) => {
            this.lmActiveState = result.activeState;
            this.lmActivity = result.activity;
            this.lmDescription = result.description;
            this.lmFocus = result.focus;
            this.lmGroup = result.group.trim().length > 0 ? result.group : "iVE";
            this.lmHostName = result.name;
            this.lmLab = result.lab;
            this.lmLocation = result.location;
            this.lmOwner = result.owner;
            this.lmProject = result.project;
            this.lmProjectID = result.projectID;
            //this.lmSite = result.site;
            this.selectedLMSite = result.site;
            this.lmVTName = result.vtName;
            if(result.components.length > 0){
            this.lmComponents = result.components;
            this.lmBoardSN = this.lmComponents[0]["serialNumber"];
            }
            else{
              this.lmBoardSN = "";
            }
          },
          error: (e) => {
            //alert('Host name not found. Please try again with different host.\n\n');
            //console.log("Host not found: " + e.message?.toString());
            //this.lmHostName = "";
            console.log("Hostname not found:" + e.message?.toString());
          },
          complete: () => {
            this.getLMProjects(this.selectedLMSite);
            this.getLMLabs(this.selectedLMSite);
            this.getLMValidationTeams(this.selectedLMSite);
            this.getLMOwners(this.selectedLMSite);

          }
        });
    }
    else{

    }

  }
  else{
    alert("Enter Hostname \n\n");
  }
}


  //This function is to update only Host's ActiveState value in Lab Manager
  //This function will be triggered when Ramping-Up and Closing the CC
  public async updateLMHostActiveState(hostName: string, state: string) {
    const updateLMHost: any = {};
    updateLMHost.ActiveState = state.trim(); //hard code for auto update the host activestate in Lab Manager
    updateLMHost.RecipeID = this.workqGridRecipeNumber.trim();
    updateLMHost.RecipeURL = this.lmRecipeURL.trim();
    updateLMHost.LastModifiedBy = this.domain + "\\" + this.lmIdsid; // Format: Domain/IDSID (Eg: GAR/myidsid)
    const id = sessionStorage.getItem("IntegrationID");
    await this.thirdPartyAppGateway.putLMHostDetails(hostName.trim(), updateLMHost, true).pipe(takeUntil(this.destroy$)).subscribe({
      next: (result) => {
        alert("Lab Manager: Host ActiveState Updated to "+state+"\n\n");
        let item: ToolsIntegration = new ToolsIntegration();
        //Update Tools Integration data in RM Database to refresh the latest value to UI

        //Start Ramp-Up - Update ActiveState value to Ramping-Up in RM container (toolsintegration)
        if(state.trim().toLocaleLowerCase() == "ramping-up")
        {
          this.lmActiveState = result.activeState;
          if (id != null && id != "") {
            item.activeState = result.activeState;
            item.updatedBy = new Person();
            item.updatedBy.name = this.userName;
            item.updatedBy.email = this.userEmail;
            item.updatedBy.wwid = this.userWwid;
            item.id = id;

            const dataChanges = [
              { value: item.activeState, path: 'activeState', op: 'replace' },
              { value: item.updatedBy, path: 'updatedBy', op: 'replace' },

            ];
            this.updateToolsIntegrationItem(item, dataChanges, true);

          }
          else{
            console.log("Update Tools Integration Failed due to Integration ID is null or empty\n\n");
          }

        }
        // SubmitAndComplete - Update the latest values to RM container (toolsintegration)
        else {

          this.lmActiveState = result.activeState;

          if (id != null && id != "") {

            item.site = this.selectedLMSite.trim();
            item.lab = this.lmLab.trim();
            item.project = this.lmProject.trim();
            item.group = this.lmGroup.trim();
            item.hostName = this.lmHostName.trim();
            item.description = this.lmDescription.trim();
            item.owner = this.lmOwner.trim();
            item.activity = this.lmActivity.trim();
            item.activeState = this.lmActiveState.trim();
            item.validationTeam = this.lmVTName.trim();
            item.focus = this.lmFocus.trim();
            item.boardSN = this.lmBoardSN.trim();
            item.location = this.lmLocation.trim();

            item.updatedBy = new Person();
            item.updatedBy.name = this.userName;
            item.updatedBy.email = this.userEmail;
            item.updatedBy.wwid = this.userWwid;

            item.id = id;

            const dataChanges = [
              { value: item.site, path: 'site', op: 'replace' },
              { value: item.lab, path: 'lab', op: 'replace' },
              { value: item.project, path: 'project', op: 'replace' },
              { value: item.group, path: 'group', op: 'replace' },
              { value: item.hostName, path: 'hostName', op: 'replace' },
              { value: item.description, path: 'description', op: 'replace' },
              { value: item.owner, path: 'owner', op: 'replace' },
              { value: item.activity, path: 'activity', op: 'replace' },
              { value: item.activeState, path: 'activeState', op: 'replace' },
              { value: item.validationTeam, path: 'validationTeam', op: 'replace' },
              { value: item.focus, path: 'focus', op: 'replace' },
              { value: item.boardSN, path: 'boardSN', op: 'replace' },
              { value: item.location, path: 'location', op: 'replace' },
              { value: item.updatedBy, path: 'updatedBy', op: 'replace' },

            ];
            this.updateToolsIntegrationItem(item, dataChanges, true);
            //this.getToolsIntegrationData(this.workqNumber, this.workqGridRecipeNumber);

          }
        else{
          console.log("Update Tools Integration Failed due to Integration ID is null or empty\n\n");
        }
      }

      },
      error: (e) => {
        alert('Lab Manager: ActiveState Update Failed\n\n' + e.error?.toString());
      },
      complete: () => {

      }
    });

  }

  public async updateLMHostDetails(hostName: string, updates: any): Promise<void> {
    try {
      await this.thirdPartyAppGateway.putLMHostDetails(hostName.trim(), updates, true).pipe(takeUntil(this.destroy$)).subscribe({
        next: (result) => {
          this.getLMHostDetails(hostName.trim());
          this.displayWorkqWarningMessage();
          alert("Lab Manager: Host Details Updated Successfully\n\n");

        },
        error: (e) => {
          this.displayWorkqWarningMessage();
          alert('Lab Manager: Host Details Update Failed\n\n' + e.error?.toString());
        },
        complete: () => {

        }
      });

    }
    catch{
      alert("Error occurred while updating host details..\n\n");
      console.log("Error occured while updating host details.");
      this.displayWorkqWarningMessage();
    }

  }

  public prepareCreateIntegrationRequest(): void {
    this.lmIntegrationRequest.site = this.selectedLMSite;
    this.lmIntegrationRequest.lab = this.lmLab;
    this.lmIntegrationRequest.project = this.lmProject;
    this.lmIntegrationRequest.group = "IVE";
    this.lmIntegrationRequest.hostName = this.lmHostName;
    this.lmIntegrationRequest.description = this.lmDescription;
    this.lmIntegrationRequest.owner = this.lmOwner;
    this.lmIntegrationRequest.activity = this.lmActivity;
    this.lmIntegrationRequest.activeState = this.lmActiveState;
    this.lmIntegrationRequest.validationTeam = this.lmVTName;
    this.lmIntegrationRequest.focus = this.lmFocus;
    this.lmIntegrationRequest.boardSN = this.lmBoardSN;
    this.lmIntegrationRequest.location = this.lmLocation;
    this.lmIntegrationRequest.workq = this.workqNumber;
    this.lmIntegrationRequest.hsdNumber = this.hsdId;
    this.lmIntegrationRequest.recipeNumber = this.recipeNumber;
    this.lmIntegrationRequest.recipeName = this.recipeName;

    //CreateBy
    this.lmIntegrationRequest.createdBy = new Person();
    this.lmIntegrationRequest.createdBy.name = this.userName;
    this.lmIntegrationRequest.createdBy.email = this.userEmail;
    this.lmIntegrationRequest.createdBy.wwid = this.userWwid;

    //updatedBy
    this.lmIntegrationRequest.updatedBy = new Person();
    this.lmIntegrationRequest.updatedBy.name = this.userName;
    this.lmIntegrationRequest.updatedBy.email = this.userEmail;
    this.lmIntegrationRequest.updatedBy.wwid = this.userWwid;
    //this.getUserIdsid(this.lmOwner);
  }

  public prepareUpdateIntegrationRequest(item: any){
    const dataChanges = [
      { value: item.site, path: 'site', op: 'replace' },
      { value: item.lab, path: 'lab', op: 'replace' },
      { value: item.project, path: 'project', op: 'replace' },
      { value: item.group, path: 'group', op: 'replace' },
      { value: item.hostName, path: 'hostName', op: 'replace' },
      { value: item.description, path: 'description', op: 'replace' },
      { value: item.owner, path: 'owner', op: 'replace' },
      { value: item.activity, path: 'activity', op: 'replace' },
      { value: item.activeState, path: 'activeState', op: 'replace' },
      { value: item.validationTeam, path: 'validationTeam', op: 'replace' },
      { value: item.focus, path: 'focus', op: 'replace' },
      { value: item.boardSN, path: 'boardSN', op: 'replace' },
      { value: item.location, path: 'location', op: 'replace' },
      { value: item.workq, path: 'workq', op: 'replace' },
      { value: item.hsdNumber, path: 'hsdNumber', op: 'replace' },
      { value: item.recipeNumber, path: 'recipeNumber', op: 'replace' },
      { value: item.recipeName, path: 'recipeName', op: 'replace' },
      { value: item.updatedBy, path: 'updatedBy', op: 'replace' },

    ];
    return dataChanges;
  }

  //Ken created this function for Save Config
  public async saveConfig(): Promise<void> {
    try {
      if (this.lmHostName.trim() != "") {
        await this.checkLMHostAvailability(this.lmHostName.trim());
        await this.delay(3000);
      }
      else {
        this.isLMHostAvailable = true;
      }

      if (this.isLMHostAvailable) {
        this.prepareCreateIntegrationRequest();

          if (this.checkLMFieldsEmptyOrFilled()) {
          if (sessionStorage.getItem("IntegrationID") != null && sessionStorage.getItem("IntegrationID") != undefined) {
            //If checkLMFieldsEmptyOrFilled is true and Id is not empty - go to update else go to create new Integration Item
            //let id: string | null;
            const id = sessionStorage.getItem("IntegrationID");
            this.lmIntegrationRequest.id = id != null ? id : "";
            const dataChanges = this.prepareUpdateIntegrationRequest(this.lmIntegrationRequest);
            let alertMsg: boolean = this.validateLMRequiredFieldsAreFilled() == true ? true : false;
            await this.updateToolsIntegrationItem(this.lmIntegrationRequest, dataChanges, alertMsg);
            this.areAllRequiredChecked(false);
          }
          else {
            this.lmIntegrationRequest.revision = 0;
            this.lmIntegrationRequest.isArchived = false;
            this.lmIntegrationRequest.application = "Recipe Management";
            await this.createNewIntegrationItem(this.lmIntegrationRequest);
            this.areAllRequiredChecked(false);

          }
          //Update Lab Manager Host details if all fields already filled
          if (this.validateLMRequiredFieldsAreFilled()) {
            if (this.lmHostName != "" && this.lmHostName != null && this.lmHostName != undefined) {
              const updateLMHost: any = {};
              updateLMHost.ActiveState = this.lmActiveState;
              updateLMHost.Activity = this.lmActivity;
              //========================================================================================================================================
              // After customers requested to make Focus & Description fields are optional.
              // Modified Date: 19/04/2024
              // Author: Rajesh
              if(this.lmDescription.trim().length > 0){
                updateLMHost.Description = this.lmDescription;
              }
              else{
                updateLMHost.Description = "";
              }

              if(this.lmFocus.trim().length > 0){
                updateLMHost.Focus = this.lmFocus;
              }
              else{
                updateLMHost.Focus = "";
              }

              //==========================================================================================================================================
              updateLMHost.Owner = this.lmOwner;
              updateLMHost.Project = this.lmProject;
              updateLMHost.ProjectID = this.lmProjectID;
              updateLMHost.VTName = this.lmVTName;
              // Rajesh added below two lines of code to update Recipe Number and PDF URL in Lab Manager.
              updateLMHost.RecipeID = this.workqGridRecipeNumber.trim();
              updateLMHost.RecipeURL = this.lmRecipeURL.trim();
              updateLMHost.LastModifiedBy = this.domain + "\\" + this.lmIdsid; // Format: Domain/IDSID (Eg: GAR/myidsid)
              await this.updateLMHostDetails(this.lmHostName.trim(), updateLMHost);
              this.areAllRequiredChecked(false);
            }
          }
        }
        else {
          alert("Need minimum one input to save data except Site field, please enter value and then try again.\n");
        }

      }
      else{
        alert("Host not found in Lab Manager. Please check the name and try again.\n\n");
      }

    } catch (e) {
      var error = e as Error;
      alert("An error occurred while submitting your request: " + error.message.toString());
    }


  }

  public async updateToolsIntegrationItem(loadeditem: ToolsIntegration, dataChanges: any,  msgAlert: boolean): Promise<void> {
    try {

      await this.createGateway.updateToolsIntegration(loadeditem, dataChanges, true).pipe(takeUntil(this.destroy$)).subscribe({
        next: (result) => {
          this.displayWorkqWarningMessage();
          //alert("Recipe Management: Data Updated Successfully\n\n");
          if(msgAlert){
            alert("Recipe Management: Data Updated Successfully\n\n");
            }
            else{
            alert("Found LM required field(s) empty. Please fill all the required fields to save details in Lab Manager. Data is temporarily stored in RM database.");
            }
        },
        error: (e) => {
          console.log('RM Data Update Failed\n\n' + e.error?.toString());
          this.displayWorkqWarningMessage();
        },
        complete: () => {

        }
      });

    }
    catch{
      console.log("Error occured while updating host details.")
    }

  }

  public checkLMFieldsEmptyOrFilled(): boolean {
    if (this.lmLab.trim().length > 0 || this.lmProject.trim().length > 0 || this.lmHostName.trim().length > 0 || this.lmDescription.trim().length > 0 || this.lmOwner.trim().length > 0 || this.lmActivity.trim().length > 0 || this.lmActiveState.trim().length > 0 || this.lmVTName.trim().length > 0 || this.lmFocus.trim().length > 0 || this.lmBoardSN.trim().length > 0 || this.lmLocation.trim().length > 0){
      return true;
    }
    return false;
  }

  public validateLMRequiredFieldsAreFilled(): boolean {
    // Focus and Description fields are made optional per customers request in both GGR/AMR RM WG meeting on Tuesday (WW16.2)
    //if (this.selectedLMSite.trim().length > 0 && this.lmLab.trim().length > 0 && this.lmProject.trim().length > 0 && this.lmHostName.trim().length > 0 && this.lmDescription.trim().length > 0 && this.lmOwner.trim().length > 0 && this.lmActivity.trim().length > 0 && this.lmActiveState.trim().length > 0 && this.lmVTName.trim().length > 0 && this.lmFocus.trim().length > 0 && this.lmBoardSN.trim().length > 0 && this.lmLocation.trim().length > 0){
      /*if (this.selectedLMSite.trim().length > 0 && this.lmLab.trim().length > 0 && this.lmProject.trim().length > 0 && this.lmHostName.trim().length > 0 && this.lmOwner.trim().length > 0 && this.lmActivity.trim().length > 0 && this.lmActiveState.trim().length > 0 && this.lmVTName.trim().length > 0 && this.lmBoardSN.trim().length > 0 && this.lmLocation.trim().length > 0){
    return true;
    }
    return false;*/
    if(this.workqCategory.toLowerCase() === 'ramp down' || this.workqCategory.toLowerCase() === 'power-on readiness')
      {
        //Remove Board S/N
        if (this.selectedLMSite.trim().length > 0 && this.lmLab.trim().length > 0 && this.lmProject.trim().length > 0 && this.lmHostName.trim().length > 0 && this.lmOwner.trim().length > 0 && this.lmActivity.trim().length > 0 && this.lmActiveState.trim().length > 0 && this.lmVTName.trim().length > 0 && this.lmLocation.trim().length > 0)
          {
            return true;
          }
      }
      else
      {
        if (this.selectedLMSite.trim().length > 0 && this.lmLab.trim().length > 0 && this.lmProject.trim().length > 0 && this.lmHostName.trim().length > 0 && this.lmOwner.trim().length > 0 && this.lmActivity.trim().length > 0 && this.lmActiveState.trim().length > 0 && this.lmVTName.trim().length > 0 && this.lmBoardSN.trim().length > 0 && this.lmLocation.trim().length > 0)
          {
            return true;
          }
      }

        return false;

  }


    public ddlSiteValueChange(value: any) {
      this.selectedLMSite = value;
      this.getLMProjects(this.selectedLMSite);
      this.getLMLabs(this.selectedLMSite);
      this.getLMValidationTeams(this.selectedLMSite);
      this.getLMOwners(this.selectedLMSite);
    }

    public ddlProjectValueChange(value: any) {
      this.lmProject = value.name;
      this.lmProjectID = value.projectID;
      console.log(this.lmProject);
      console.log(this.lmProjectID);
    }

    public ddlLabValueChange(value: any) {
      this.lmLab = "";
      this.lmLab = value;
    }

    public ddlVTeamValueChange(value: any) {
      this.lmVTName = "";
      this.lmVTName = value;
    }

    public ddlOwnerValueChange(value: any) {
      this.lmOwner = "";
      this.lmOwner = value;
    }

    public ddlActivityValueChange(value: any) {
      this.lmActivity = "";
      this.lmActivity = value;
    }

    public ddlActiveStateValueChange(value: any) {
      this.lmActiveState = "";
      this.lmActiveState = value;
    }

    //TextBox Event

    public onDescChange(value: string): void {
      this.lmDescription = "";
      this.lmDescription = value;
    }

    public onHostNameChange(value: string): void {
      this.lmHostName = "";
      this.lmHostName = value;
      //this.checkLMHostAvailability(this.lmHostName.trim());
    }

    public onGroupChange(value: string): void {
      this.lmGroup = value;
    }

    public onFocusChange(value: string): void {
      this.lmFocus = "";
      this.lmFocus = value;
    }

    public onLocationChange(value: string): void {
      this.lmLocation = "";
      this.lmLocation = value;
    }

    public onBoardSNChange(value: string): void {
      this.lmBoardSN = "";
      this.lmBoardSN = value;
    }

    public async createNewIntegrationItem(item: ToolsIntegration): Promise<void> {

      if (this.filterCreateToolsIntegrationSubscription){
        this.filterCreateToolsIntegrationSubscription.unsubscribe();
      }
      try
      {
        await this.createGateway.createToolsIntegration(item).subscribe({
              next: (result) => {
                if(result != null){
                  var newItemID = result.id;
                  var createdByWwid = result.createdBy.wwid;
                  if (sessionStorage.getItem("IntegrationID") != null) {
                    sessionStorage.removeItem("IntegrationID");
                  }
                  if (sessionStorage.getItem("CreatedByWwid") != null) {
                    sessionStorage.removeItem("CreatedByWwid");
                  }
                  sessionStorage.setItem("IntegrationID", newItemID);
                  sessionStorage.setItem("CreatedByWwid", createdByWwid);
                  alert("Recipe Management: New Integration Data Created Successfully..\n\n");
                  this.displayWorkqWarningMessage();
                }
              },
              error: (e) => {
                alert('Recipe Management: Integration Data Creation Failed\n\n' + e.message.toString());
                this.displayWorkqWarningMessage();
              }
            });
      }
    catch
     (error)
            {
                var e = error as Error;
                console.log(e.message);
                this.displayWorkqWarningMessage();
            };
    }

    public getUserConfig(): void {
      // Fetch user's default site from config page to populate Lab/Owner/Project/Station VT values
      var userWWID = sessionStorage.getItem("appSettings.appUserWwid");
      if (userWWID) {
        this.createGateway.getConfiguration(userWWID).subscribe({
            next: (result) => {
            if (result) {
              this.userConfigSite = result.labs[0].name;
              this.selectedLMSite = "";
              this.selectedLMSite = result.defaultSite;
              //this.lmDefaultSite = result.defaultSite; //Ken Add this for Reset Button
              if(sessionStorage.getItem("defaultSite") != null || sessionStorage.getItem("defaultSite") != ""){
                sessionStorage.removeItem("defaultSite");
              }
              sessionStorage.setItem("defaultSite", result.defaultSite);
              //==========LM Integration Method Calls========
              if (this.selectedLMSite != "") {
                this.getLMSites();
                this.getLMActivities();
                this.getLMProjects(this.selectedLMSite);
                this.getLMLabs(this.selectedLMSite);
                this.getLMValidationTeams(this.selectedLMSite);
                this.getLMOwners(this.selectedLMSite);
              }
              else {
                this.getLMSites();
                this.getLMActivities();
              }

              //================== END =======================
            }
          },
          error: (e) => {
            //alert('Get Configuration Error\n\n' + e.error?.toString());
            this.getLMSites();
            this.getLMActivities();
          },
          complete: () => {
          }

        });
      }
    }

    public setUserWwid(): void {

      if (this.filterGetUsersSubscription){
        this.filterGetUsersSubscription.unsubscribe();
      }

      if (this.userEmail != '') {
        this.filterGetUsersSubscription = this.graphUsersGateway.getUsers(this.userEmail, 1, '', true).subscribe({
          next: (result) => {
            if (result[0].jobTitle != null) {
              this.userWwid = result[0].jobTitle;
            }
          },
          error: (e) => {
            alert('Get User Info Error\n\n' + e.error.toString());
          }
        });
      }
    }

    //Ken add this for a reset button 20/3/2024
  public resetLMValues(): void {
    this.selectedLMSite = "";
    this.lmOwner = "";
    this.lmLab = "";
    this.lmVTName = "";
    this.lmProject = "";
    this.lmFocus = "";
    this.lmActivity = "";
    this.lmActiveState = "";
    this.lmBoardSN = "";
    this.lmLocation = "";
    this.lmDescription = "";
    this.lmHostName = "";
    this.lmIntegrationId = "";
    this.lmCreatedByWwid = "";

  }

  public btnResetLMTab(): void {
    this.resetLMValues();
    this.getUserConfig();
  }


      //Formatting Domain for Lab Manager
  public formatDomain(name: string): void {

    var a = name != "" ? name : "";
    var domain = a.trim().split('.')[0]; //returns gar/amr/ger
    if(a!=""){

    }

    switch(domain.toLowerCase()){
      case "gar":
        this.domain = "GAR";
        break;
      case "amr":
        this.domain = "AMR";
        break;
      default:
        this.domain = "GER";
        break;
    }

    //console.log("This Domain: " + this.domain);
  }

  public async delay(ms: number): Promise<void> {
    await new Promise( resolve => setTimeout(resolve, ms) );
  }

  public  displayWorkqWarningMessage(): void {
    //if (this.selectedLMSite != "" && this.lmHostName != "" && this.lmLab != "" && this.lmGroup != "" && this.lmProject != "" && this.lmOwner != "" && this.lmActivity != "" && this.lmActiveState != "" && this.lmVTName != "" && this.lmFocus != ""
     // && this.lmBoardSN != "" && this.lmLocation != "" && this.lmDescription != ""){

    // if (this.selectedLMSite != "" && this.lmHostName != "" && this.lmLab != "" && this.lmGroup != "" && this.lmProject != "" && this.lmOwner != ""
    //   && this.lmActivity != "" && this.lmActiveState != "" && this.lmVTName != "" && this.lmBoardSN != "" && this.lmLocation != "") {
    //   this.showLmMessage = false;
    // }
    // else {
    //   this.showLmMessage = true;
    // }

    if (this.workqCategory.toLowerCase() === 'ramp down' || this.workqCategory.toLowerCase() === 'power-on readiness')
    {
      console.log("Checking for Ramp Down and Power-On Readiness");
      console.log("Location Display Warning Message: " + this.lmLocation);
      //Board SN is optional hence we do not need to check for this field if is empty
      if(this.selectedLMSite != "" && this.lmHostName != "" && this.lmLab != "" && this.lmGroup != "" && this.lmProject != "" && this.lmOwner != ""
        && this.lmActivity != "" && this.lmActiveState != "" && this.lmVTName != "" && this.lmLocation != "" && this.lmLocation != null)
      {
        this.showLmMessage = false;
      }
      else
      {
        this.showLmMessage = true;
      }
    }
    else
    {
      console.log("Checking for Execution");
      //Board SN is mandatory hence we need to check for this field if is empty
      if (this.selectedLMSite != "" && this.lmHostName != "" && this.lmLab != "" && this.lmGroup != "" && this.lmProject != "" && this.lmOwner != ""
        && this.lmActivity != "" && this.lmActiveState != "" && this.lmVTName != "" && this.lmBoardSN != "" && this.lmBoardSN != null && this.lmLocation != "" && this.lmLocation != null)
      {
        this.showLmMessage = false;
      }
      else
      {
        this.showLmMessage = true;
      }
    }

  }

  public getToolsIntegrationData(workq: string, recipeNumber: string): void{
    this.createGateway.getToolsIntegrationByWorkq(workq, recipeNumber, true).pipe(takeUntil(this.destroy$)).subscribe({
      next: (result) => {

        // Auto populate Lab Manager data in LM tab when load page based on WorkQ and HSD numbers.

        if (result != null) {
          this.selectedLMSite = result.site;
          this.lmHostName = result.hostName;
          this.lmLab = result.lab;
          this.lmProject = result.project;
          this.lmOwner = result.owner;
          this.lmActivity = result.activity;
          this.lmGroup = result.group;
          this.lmActiveState = result.activeState;
          this.lmVTName = result.validationTeam;
          this.lmFocus = result.focus;
          this.lmBoardSN = result.boardSN;
          this.lmLocation = result.location;
          this.lmDescription = result.description;
          this.revision = result.revision;
          //need this values to update the lab manager details in saveconfig function.
          this.lmIntegrationId = result.id;
          this.lmCreatedByWwid = result.createdBy.wwid;

          if (sessionStorage.getItem("IntegrationID") != null) {
            sessionStorage.removeItem("IntegrationID");
          }
          sessionStorage.setItem("IntegrationID", result.id); // use this value in UpdateLMActiveState function.

          //==========Populate LM Data ====================
          if (this.selectedLMSite != "") {
            this.getLMSites();
            this.getLMActivities();
            this.getLMProjects(this.selectedLMSite);
            this.getLMLabs(this.selectedLMSite);
            this.getLMValidationTeams(this.selectedLMSite);
            this.getLMOwners(this.selectedLMSite);
          }
          else {
            this.getLMSites();
            this.getLMActivities();
            this.displayWorkqWarningMessage();

          }
        }
        else {
          if (sessionStorage.getItem("IntegrationID") != null) {
            sessionStorage.removeItem("IntegrationID");
          }
          this.getLMSites();
          this.getLMActivities();
          this.displayWorkqWarningMessage();
        }

        //================== END ========================


      },
      error: (e) => {
        //alert('Data not found for workq.\n\n' + e.error?.toString());
        if (sessionStorage.getItem("IntegrationID") != null) {
          sessionStorage.removeItem("IntegrationID");
        }
        this.getLMSites();
        this.getLMActivities();
        this.getUserConfig();
        this.displayWorkqWarningMessage();
      },
      complete: () => {
        this.displayWorkqWarningMessage();

      }
    });
  }

    //================== End ====================

}




