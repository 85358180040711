<div class="main-content view-content">
    <kendo-loader [size]="'large'" type="converging-spinner" *ngIf="showHsdEsWaitIndicator"></kendo-loader>
    <kendo-window [width]=1200 [height]=600 title="{{windowOpenedTitle}}" *ngIf="isWindowHsdEsArticlesOpened" (close)="closePopupWindow('hsdesarticles')">
        <div class="pane-content">
            <table class="search-table">
                <tr>

                <td class="search-table-box">
                    <kendo-dropdownlist [data]="hsdesAssignWordSearchFilterTypes" [textField]="'label'" [valueField]="'value'" id="hsdesWordSearchOptionsList" [value]="selectedHSDESSearch" (valueChange)="lstWordSearchValueChangeHsdes($event)" class="filter-type" width="Auto" minWidth="350">
                    </kendo-dropdownlist>
                    <input type="text" class="form-control form-control-sm search-box" data-toggle="tooltip" title="Search HSD-ES by HSD-ES ID" placeholder="Search HSD-ES by HSD-ES ID." [ngbTooltip]="'For searching multiple HSD-ES articles, please separate with commas ONLY. To go back to ALL your tickets, leave input box EMPTY and then click on Search.'" aria-label="Search" aria-describedby="basic-addon2" id="searchHsdEsBox" (keyup.enter) = "searchHsdEsArticles()" />
                </td>
                <td class="search-table-margin"></td>
                <td class="search-table-button">
                    <div class="input-group-append">
                        <button class="btn btn-outline-primary btn-sm search-button" type="button" (click)="searchHsdEsArticles()" [disabled]='isSearchHsdEsDisabled'>
                            <i class="intelicon-search" aria-hidden="true" ></i> Search
                        </button>
                    </div>
                </td>
                <td class="search-table-button">
                    <div class="input-group-append">
                        <button class="btn btn-primary btn-sm button" type="button" type="button" (click)="btnAssignHsdEsArticles()" [disabled]="this.selectedHsdEsTickets.length <= 0">
                            <i class="intelicon-search" aria-hidden="true"></i> Assign HSD-ES
                        </button>
                    </div>
                </td>
                </tr>
            </table>
        </div>
        
            <kendo-grid #hsdEsGrid="kendoGrid" [data]="articleGridData" [style.height.%]="90"
                        [resizable]="true" filterable="menu"
                        (dataStateChange)="dataStateChange($event,'hsdesarticles')"
                        [pageSize]="gridSettings.state.take"
                        [skip]="gridSettings.state.skip"
                        [sort]="gridSettings.state.sort"
                        [loading]="isHsdEsGridLoading"
                        [filter]="gridSettings.state.filter"
                        [sortable]="true"
                        [pageable]="true"
                        [reorderable]="true"
                        [selectable]="selectableSettings"
                        (filterChange)="searchGridFilterChange($event, 'hsdes')"
                        (pageChange)="pageChange($event)"
                        kendoGridSelectBy
                        [navigable]="true"
                        [loading]="isHsdEsGridLoading"
                        >
                <kendo-grid-column field="isSelected" title="Selected" [width]="120" [filterable]="false">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <input type="checkbox" (click)="toggle(dataItem, 'Selected', 'selectedhsdesarticles')" [checked]="dataItem.isSelected" [ngbTooltip]="'Click on check box to select an HSD-ES article to create a WorkQ (consumption case). Then, click on `Assign HSD-ES` button to complete this task.'"/>
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column field='support_site' title='Site' [width]="100" headerClass="headerStyle" class="rowStyle">{{articleGridData[0]}}
                    <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter" let-filterService="filterService">
                        <multicheck-filter [isPrimitive]="true" [field]="column.field" [filterService]="filterService" [currentFilter]="filter" [data]="distinctPrimitive(column.field, 'hsdes')"></multicheck-filter>
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column field='title' title='Title' [width]="250" headerClass="headerStyle" class="rowStyle">
                    <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter" let-filterService="filterService">
                        <multicheck-filter [isPrimitive]="true" [field]="column.field" [filterService]="filterService" [currentFilter]="filter" [data]="distinctPrimitive(column.field, 'hsdes')"></multicheck-filter>
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column field='id' title='HSD-ES ID' [width]="160" headerClass="headerStyle" class="rowStyle">
                    <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter" let-filterService="filterService">
                        <multicheck-filter [isPrimitive]="true" [field]="column.field" [filterService]="filterService" [currentFilter]="filter" [data]="distinctPrimitive(column.field, 'hsdes')"></multicheck-filter>
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column field='rev' title='Revision' [width]="130" headerClass="headerStyle" class="rowStyle">
                    <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter" let-filterService="filterService">
                        <multicheck-filter [isPrimitive]="true" [field]="column.field" [filterService]="filterService" [currentFilter]="filter" [data]="distinctPrimitive(column.field, 'hsdes')"></multicheck-filter>
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column field='owner' title='Owner' [width]="250" headerClass="headerStyle" class="rowStyle">
                    <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter" let-filterService="filterService">
                        <multicheck-filter [isPrimitive]="true" [field]="column.field" [filterService]="filterService" [currentFilter]="filter" [data]="distinctPrimitive(column.field, 'hsdes')"></multicheck-filter>
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column field='description' title='Description' [width]="250" headerClass="headerStyle" class="rowStyle">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <div [innerHTML]="dataItem.description"></div>
                    </ng-template>
                    <!-- <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter" let-filterService="filterService">
                        <multicheck-filter [isPrimitive]="true" [field]="column.field" [filterService]="filterService" [currentFilter]="filter" [data]="distinctPrimitive(column.field, 'hsdes')"></multicheck-filter>
                    </ng-template> -->
                </kendo-grid-column>
                <kendo-grid-column field='priority' title='Priority' [width]="150" headerClass="headerStyle" class="rowStyle">
                    <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter" let-filterService="filterService">
                        <multicheck-filter [isPrimitive]="true" [field]="column.field" [filterService]="filterService" [currentFilter]="filter" [data]="distinctPrimitive(column.field, 'hsdes')"></multicheck-filter>
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column field='reason' title='Reason' [width]="150" headerClass="headerStyle" class="rowStyle">
                    <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter" let-filterService="filterService">
                        <multicheck-filter [isPrimitive]="true" [field]="column.field" [filterService]="filterService" [currentFilter]="filter" [data]="distinctPrimitive(column.field, 'hsdes')"></multicheck-filter>
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column field='submitted_by' title='Submitted By' [width]="150" headerClass="headerStyle" class="rowStyle">
                    <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter" let-filterService="filterService">
                        <multicheck-filter [isPrimitive]="true" [field]="column.field" [filterService]="filterService" [currentFilter]="filter" [data]="distinctPrimitive(column.field, 'hsdes')"></multicheck-filter>
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column field='submitted_date' title='Submitted Date' [width]="180" headerClass="headerStyle" class="rowStyle">
                    <!-- <ng-template kendoGridCellTemplate let-dataItem>
                        {{ dataItem.updatedDate | date: 'MM/dd/yyyy hh:mm a'}}
                    </ng-template> -->
                    <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter" let-filterService="filterService">
                        <multicheck-filter [isPrimitive]="true" [field]="column.field" [filterService]="filterService" [currentFilter]="filter" [data]="distinctPrimitive(column.field, 'hsdes')"></multicheck-filter>
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column field='updated_by' title='Updated By' [width]="150" headerClass="headerStyle" class="rowStyle">
                    <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter" let-filterService="filterService">
                        <multicheck-filter [isPrimitive]="true" [field]="column.field" [filterService]="filterService" [currentFilter]="filter" [data]="distinctPrimitive(column.field, 'hsdes')"></multicheck-filter>
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column field='updated_date' title='Updated Date' type='date' [width]="180" headerClass="headerStyle" class="rowStyle">
                    <!-- <ng-template kendoGridCellTemplate let-dataItem>
                        {{ dataItem.updatedDate | date: 'MM/dd/yyyy hh:mm a'}}
                    </ng-template> -->
                    <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter" let-filterService="filterService">
                        <multicheck-filter [isPrimitive]="true" [field]="column.field" [filterService]="filterService" [currentFilter]="filter" [data]="distinctPrimitive(column.field, 'hsdes')"></multicheck-filter>
                    </ng-template>
                </kendo-grid-column>
            </kendo-grid>

    </kendo-window>
    <kendo-window [minWidth]="1200" [minHeight]="725" title="{{windowOpenedTitle}}" *ngIf="isWindowBuildingBlockPdfsOpened" (close)="closePopupWindow('buildingblockpdf')">
        <div>
            <kendo-panelbar>
                <kendo-panelbar-item *ngFor="let item of buildingBlockDetailsGrid" title="Building Blocks" [expanded]="false">
                    <ng-template kendoPanelBarItemTitle>
                        {{item.buildingBlockName}}
                    </ng-template>
                    <ng-template kendoPanelBarContent>
                        <div>
                            <a *ngIf="item.content" [href]="fileUrl + encodeURLContent(item.content)" target="_new">Open PDF (new tab)</a>
                            <div [style.display]="isPdfOptionDisabled ? 'none':'block'">
                                <span *ngIf="checkListContentLoading[item.id].loaded">
                                    <kendo-loader type="converging-spinner" size="medium"> </kendo-loader>
                                </span>
                                <pdf-viewer style="width: 100%; height: 800px" [src]="{url: fileUrl+encodeURLContent(item.content)}" [render-text]="true" [original-size]="false"
                                (after-load-complete)="checkListContentLoading[item.id].loaded=false" (on-progress)="onloadingPdfProgress($event, item.id)" >
                                </pdf-viewer>
                            </div>
                        </div>
                    </ng-template>
                </kendo-panelbar-item>
            </kendo-panelbar>
        </div>
    </kendo-window>
    <kendo-window [minWidth]="1200" [minHeight]="725" title="{{windowOpenedTitle}}" *ngIf="isWindowRecipePdfsOpened" (close)="closePopupWindow('recipepdf')">
        <div>
            <a *ngIf="recipeDocBinary" [href]="recipeDoc.url" target="_new">Open PDF (new tab)</a>
            <kendo-panelbar *ngIf="!isPdfOptionDisabled">
                <kendo-panelbar-item title="Building Blocks" [expanded]="false">
                    <ng-template kendoPanelBarItemTitle>
                        {{combinedPDFDocument}}
                    </ng-template>
                    <ng-template kendoPanelBarContent>
                        <div>
                            <div [style.display]="isPdfOptionDisabled ? 'none':'block'">
                                <span *ngIf="isCombinedPdfLoading">
                                    <kendo-loader type="converging-spinner" size="medium"> </kendo-loader>
                                </span>
                                <pdf-viewer style="width: 100%; height: 600px" [src]="{url: recipeDocBinary}" [render-text]="true" [original-size]="false"
                                (after-load-complete)="checkListContentLoading[recipeId].loaded=false" (on-progress)="onloadingPdfProgress($event, recipeId)" >
                                </pdf-viewer>
                            </div>
                        </div>
                    </ng-template>
                </kendo-panelbar-item>
            </kendo-panelbar>
        </div>
    </kendo-window>
    <kendo-dialog [animation]="animation" *ngIf="errorDialogOpened" (close)="dialogWindowClose('error')" [minWidth]="250" [width]="450" (close)="errorDialogAction('exit')">
        <kendo-dialog-titlebar>
            <div style="font-size: 18px; line-height: 1.3em;">
              <span class="k-icon k-i-error"></span> {{messageTitle}}
            </div>
          </kendo-dialog-titlebar>
        <i aria-hidden="true"></i> {{messageDescription}}
        <kendo-dialog-actions>
            <button type="button" class="k-button" (click)="copyText()" >Copy Message & Close</button>
            <button type="button" class="k-button k-primary" (click)="errorDialogAction('exit')" primary="true">Close</button>
        </kendo-dialog-actions>
    </kendo-dialog>
    <kendo-dialog [animation]="animation" *ngIf="promptDialogOpened" (close)="dialogWindowClose('prompt')" [minWidth]="250" [width]="450" (close)="promptDialogAction('exit')">
        <kendo-dialog-titlebar>
            <div style="font-size: 18px; line-height: 1.3em;">
              <span class="k-icon k-i-information"></span> {{messageTitle}}
            </div>
          </kendo-dialog-titlebar>
        <i aria-hidden="true"></i> {{messageDescription}}
        <kendo-dialog-actions>
            <button type="button" class="k-button" (click)="promptDialogAction('cancel')" >Cancel</button>
            <button type="button" class="k-button k-primary" (click)="promptDialogAction('confirm')" primary="true">Confirm</button>
        </kendo-dialog-actions>
    </kendo-dialog>
    <form [formGroup]="form" style="height: 94%;">
    <kendo-splitter orientation="vertical" [style.height.%]="100" >
        <kendo-splitter-pane>
            <kendo-splitter>
                <kendo-splitter-pane (scroll)="onScroll($event)">
                    <div class="pane-content">
                        <table class="search-table">
                            <tr>
                            <td class="search-table-box">
                                <kendo-dropdownlist [data]="wordSearchFilterTypes" [textField]="'label'" [valueField]="'value'" id="rmWordSearchOptionsList" [value]="selectedWordSearch" (valueChange)="lstWordSearchValueChange($event)" class="filter-type" width="Auto" minWidth="350">
                                </kendo-dropdownlist>
                                <button class="btn btn-outline-primary btn-sm" type="button" (click)="toggleFilter();" title="Show/Hide Filter Field" id="auto_trigger_filter">
                                    <i class="intelicon-vertical-menu" aria-hidden="true"></i>
                                </button>
                                <!-- ly btn disabled  -->
                                <input type="text" class="form-control form-control-sm search-box" data-toggle="tooltip" title="Search Criteria" placeholder="Search Criteria" aria-label="Search" aria-describedby="basic-addon2" id="searchBox" (keyup.enter) = "search(true)" disabled="true"  />
                            </td>
                            <td class="search-table-margin"></td>
                            <td class="search-table-button">
                                <div class="input-group-append">
                                    <button class="btn btn-outline-primary btn-sm search-button" type="button" (click)="search(true)" [disabled]='isSearchDisabled' id="auto_trigger">
                                        <i class="intelicon-search" aria-hidden="true" ></i> Search
                                    </button>
                                </div>
                            </td>
                            </tr>
                        </table>
                        <br>
                        <table class="advance-search-table" [style.display]="'block'">
                            <tr>
                                <td>
                                    <h2>View </h2>
                                </td>
                                <td class="search-table-label-box" style="text-align:left;">
                                    <h2 style = "padding-left:5px;">{{createMode}}</h2>
                                </td>
                            </tr>
                            </table>
                        <table class="advance-search-table" [style.display]="isFilterVisible ? 'none' : 'block'" > <!--[style.display]="isFilterVisible ? 'block' : 'inline'"-->
                            <tr>
                                <td class="search-table-label-box" style="text-align:right">
                                    <kendo-label [for]="forLab" text="Site(s):" class="label"></kendo-label>
                                </td>
                                <td class="search-table-text-box" style="text-align:right">
                                    <kendo-multiselect #forLab
                                        [data]="rmLabList"
                                        [placeholder]="placeholderValue"
                                        [value]="selectedLabs"
                                        [loading]="areLabsLoading"
                                        (valueChange)="lstOnListValueChange($event, 'labs')">
                                    </kendo-multiselect>
                                </td>
                                <td class="advance-search-table-label-box" style="text-align:right">
                                    <kendo-label [for]="forBoardType" text="Board Type(s):" class="label"></kendo-label>
                                </td>
                                <td class="advance-search-table-text-box" style="text-align:left">
                                    <kendo-multiselect #forBoardType
                                    [data]="rmBoardTypeList"
                                    [placeholder]="placeholderValue"
                                    [value]="selectedBoardTypes"
                                    [loading]="areBoardTypesLoading"
                                    (valueChange)="lstOnListValueChange($event, 'boardtypes')">
                                </kendo-multiselect>
                                </td>
                            </tr>
                            <tr>
                                <td class="advance-search-table-label-box" style="text-align:right">
                                    <kendo-label [for]="forExecutionType" text="Execution Type:" class="label"></kendo-label>
                                </td>
                                <td class="advance-search-table-text-box" style="text-align:left">
                                    <kendo-dropdownlist #forExecutionType [data]="rmExecutionTypeList" [defaultItem]="placeholderValue" id="rmExecutionTypesDropdownList" formControlName="rmExecutionTypesDropdownList" [value]="selectedExecutionType" (valueChange)="lstOnListValueChange($event, 'executiontype')" class="filter-dropdown">
                                    </kendo-dropdownlist>
                                </td>
                                <td class="advance-search-table-label-box" style="text-align:right">
                                    <kendo-label [for]="forProduct" text="Product(s):" class="label"></kendo-label>
                                </td>
                                <td class="advance-search-table-text-box" style="text-align:left">
                                    <kendo-multiselect #forProduct
                                    [data]="rmProductsList"
                                    [placeholder]="placeholderValue"
                                    [value]="selectedProducts"
                                    [loading]="areProductsLoading"
                                    (valueChange)="lstOnListValueChange($event, 'products')"></kendo-multiselect>

                                </td>
                            </tr>
                            <tr>
                                <td class="advance-search-table-label-box" style="text-align:right">
                                    <kendo-label [for]="forOwner" text="Owner/Creator:" class="label"></kendo-label>
                                </td>
                                <td class="advance-search-table-text-box" style="text-align:left">
                                    <kendo-dropdownlist #forOwner [data]="rmOwnersList" [defaultItem]="defaultOwner" [value]="selectedOwner" (valueChange)="lstOnListValueChange($event, 'owners')" class="filter-dropdown" [loading]="isOwnerLoading">
                                    </kendo-dropdownlist>
                                </td>
                                <td class="advance-search-table-label-box" style="text-align:right">
                                    <kendo-label [for]="forCoowner" text="Co-owner(s):" class="label"></kendo-label>
                                </td>
                                <td class="advance-search-table-text-box" style="text-align:left">
                                    <kendo-multiselect #forCoowner
                                    [data]="rmCoOwnersList"
                                    [value]="selectedCoOwners"
                                    [loading]="isCoownerLoading"
                                    [placeholder]="placeholderValue"
                                    (valueChange)="lstOnListValueChange($event, 'coowners')"></kendo-multiselect>
                                </td>
                            </tr>
                            <tr>
                                <td class="advance-search-table-label-box" style="text-align:right">
                                    <kendo-label [for]="forSiliconProgram" text="Silicon Program:" class="label"></kendo-label>
                                </td>
                                <td class="advance-search-table-text-box" style="text-align:left">
                                    <kendo-dropdownlist #forSiliconProgram [data]="rmSiliconProgramsList" [defaultItem]="placeholderValue" [value]="selectedSiliconProgram" (valueChange)="lstOnListValueChange($event, 'siliconprograms')" [loading]="areSiliconProgramsLoading">
                                    </kendo-dropdownlist>
                                </td>
                                <td class="advance-search-table-label-box" style="text-align:right">
                                    <kendo-label [for]="forValidationTeam" text="Validation Team(s):" class="label"></kendo-label>
                                </td>
                                <td class="advance-search-table-text-box" style="text-align:left">
                                    <kendo-multiselect #forValidationTeam
                                    [data]="rmValidationTeamsList"
                                    [value]="selectedValidationTeams"
                                    [loading]="areValidationTeamsLoading"
                                    [placeholder]="placeholderValue"
                                    (valueChange)="lstOnListValueChange($event, 'validationteams')"></kendo-multiselect>
                                </td>
                            </tr>
                            <tr>
                                <td class="advance-search-table-label-box" style="text-align:right">
                                    <kendo-label [for]="forFromDate" text="From Date:" class="label"></kendo-label>
                                </td>
                                <td class="advance-search-table-text-box" style="text-align:left">
                                    <kendo-datepicker #forFromDate [(value)]="fromDate" class="filter-dropdown">
                                    </kendo-datepicker>
                                </td>
                                <td class="advance-search-table-label-box" style="text-align:right">
                                    <kendo-label [for]="forToDate" text="To Date:" class="label"></kendo-label>
                                </td>
                                <td class="advance-search-table-text-box" style="text-align:left">
                                    <kendo-datepicker #forToDate [(value)]="toDate" class="filter-dropdown">
                                    </kendo-datepicker>
                                </td>
                            </tr>
                        </table>
                        <!-- <div style="text-align: right; margin-bottom: 10px;" [hidden]="isBB">
                            <button kendoButton class="btn btn-outline-primary btn-sm" type="button" [hidden]="isEmpty">
                                <i class="intelicon-ms-excel" aria-hidden="true"></i> Export to Excel
                            </button>
                        </div> -->
                        <div [hidden]="isEmpty">
                            <kendo-grid id="recipeGrid" #recipeGrid="kendoGrid" [data]="gridData" [hidden]="isBB"
                                        [style.height.%]="100"
                                        [resizable]="true" filterable="menu" [rowClass]="rowCallback"
                                        (dataStateChange)="dataStateChange($event)" [pageSize]="gridSettings.state.take"
                                        [skip]="gridSettings.state.skip" [sort]="gridSettings.state.sort" [loading]="requestGridLoading"
                                        [filter]="gridSettings.state.filter" [sortable]="true" 
                                        [pageable]="true" 
                                        [reorderable]="true"
                                        (selectionChange)="gridRecipeSelectionChange($event)" [selectable]="selectableSettings"
                                        (filterChange)="searchGridFilterChange($event)"
                                        (pageChange)="pageChange($event)"
                                        (cellClick)="onCellClick($event)"
                                        [navigable]="true">
                                <ng-template kendoGridToolbarTemplate>
                                    <button kendoGridExcelCommand icon="file-excel" [hidden]="isBB">Export to Excel</button>
                                </ng-template>
                                <kendo-grid-column field='labs' title='Sites' [width]="180" headerClass="headerStyle" class="rowStyle">
                                    <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter" let-filterService="filterService">
                                        <multicheck-filter [isPrimitive]="true" [field]="column.field" [filterService]="filterService" [currentFilter]="filter" [data]="distinctPrimitive(column.field)"></multicheck-filter>
                                    </ng-template>
                                </kendo-grid-column>
                                <kendo-grid-column field='recipeNumber' title='Recipe Number' [width]="190" headerClass="headerStyle" class="rowStyle">
                                    <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter" let-filterService="filterService">
                                        <multicheck-filter [isPrimitive]="true" [field]="column.field" [filterService]="filterService" [currentFilter]="filter" [data]="distinctPrimitive(column.field)"></multicheck-filter>
                                    </ng-template>
                                </kendo-grid-column>
                                <kendo-grid-column field='recipeName' title='Recipe Name' [width]="160" headerClass="headerStyle" class="rowStyle">
                                    <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter" let-filterService="filterService">
                                        <multicheck-filter [isPrimitive]="true" [field]="column.field" [filterService]="filterService" [currentFilter]="filter" [data]="distinctPrimitive(column.field)"></multicheck-filter>
                                    </ng-template>
                                </kendo-grid-column>
                                <kendo-grid-column field='products' title='Products' [width]="150" headerClass="headerStyle" class="rowStyle">
                                    <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter" let-filterService="filterService">
                                        <multicheck-filter [isPrimitive]="true" [field]="column.field" [filterService]="filterService" [currentFilter]="filter" [data]="distinctPrimitive(column.field)"></multicheck-filter>
                                    </ng-template>
                                </kendo-grid-column>
                                <kendo-grid-column field='siliconProgram' title='Silicon Program' [width]="180" headerClass="headerStyle" class="rowStyle">
                                    <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter" let-filterService="filterService">
                                        <multicheck-filter [isPrimitive]="true" [field]="column.field" [filterService]="filterService" [currentFilter]="filter" [data]="distinctPrimitive(column.field)"></multicheck-filter>
                                    </ng-template>
                                </kendo-grid-column>
                                <kendo-grid-column field='rampUp' title='Category' [width]="140" headerClass="headerStyle" class="rowStyle">
                                    <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter" let-filterService="filterService">
                                        <multicheck-filter [isPrimitive]="true" [field]="column.field" [filterService]="filterService" [currentFilter]="filter" [data]="distinctPrimitive(column.field)"></multicheck-filter>
                                    </ng-template>
                                </kendo-grid-column>
                                <kendo-grid-column field='boardTypes' title='Board Types' [width]="180" headerClass="headerStyle" class="rowStyle">
                                    <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter" let-filterService="filterService">
                                        <multicheck-filter [isPrimitive]="true" [field]="column.field" [filterService]="filterService" [currentFilter]="filter" [data]="distinctPrimitive(column.field)"></multicheck-filter>
                                    </ng-template>
                                </kendo-grid-column>
                                <kendo-grid-column field='owner' title='Owner' [width]="150" headerClass="headerStyle" class="rowStyle">
                                    <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter" let-filterService="filterService">
                                        <multicheck-filter [isPrimitive]="true" [field]="column.field" [filterService]="filterService" [currentFilter]="filter" [data]="distinctPrimitive(column.field)"></multicheck-filter>
                                    </ng-template>
                                </kendo-grid-column>
                                <kendo-grid-column field='coOwner' title='Co-owner' [width]="150" headerClass="headerStyle" class="rowStyle">
                                    <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter" let-filterService="filterService">
                                        <multicheck-filter [isPrimitive]="true" [field]="column.field" [filterService]="filterService" [currentFilter]="filter" [data]="distinctPrimitive(column.field)"></multicheck-filter>
                                    </ng-template>
                                </kendo-grid-column>
                                <kendo-grid-column field='createdDate' title='Created Date' [width]="150" headerClass="headerStyle" class="rowStyle">
                                    <ng-template kendoGridCellTemplate let-dataItem>
                                        {{ dataItem.updatedDate | date: 'MM/dd/yyyy hh:mm a'}}
                                    </ng-template>
                                </kendo-grid-column>
                                <kendo-grid-column field='updatedByName' title='Updated By' [width]="150" headerClass="headerStyle" class="rowStyle">
                                    <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter" let-filterService="filterService">
                                        <multicheck-filter [isPrimitive]="true" [field]="column.field" [filterService]="filterService" [currentFilter]="filter" [data]="distinctPrimitive(column.field)"></multicheck-filter>
                                    </ng-template>
                                </kendo-grid-column>
                                <kendo-grid-column field='updatedDate' title='Updated Date' type='date' [width]="180" headerClass="headerStyle" class="rowStyle">
                                    <ng-template kendoGridCellTemplate let-dataItem>
                                        {{ dataItem.updatedDate | date: 'MM/dd/yyyy hh:mm a'}}
                                    </ng-template>
                                </kendo-grid-column>
                                <kendo-grid-column field='revision' title='Revision' [width]="120" headerClass="headerStyle" class="rowStyle">
                                    <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter" let-filterService="filterService">
                                        <multicheck-filter [isPrimitive]="true" [field]="column.field" [filterService]="filterService" [currentFilter]="filter" [data]="distinctPrimitive(column.field)"></multicheck-filter>
                                    </ng-template>
                                </kendo-grid-column>
                                <kendo-grid-column field='validationTeams' title='Validation Teams' [width]="200" headerClass="headerStyle" class="rowStyle">
                                    <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter" let-filterService="filterService">
                                        <multicheck-filter [isPrimitive]="true" [field]="column.field" [filterService]="filterService" [currentFilter]="filter" [data]="distinctPrimitive(column.field)"></multicheck-filter>
                                    </ng-template>
                                </kendo-grid-column>
                                <kendo-grid-column field='emailDistribution' title='Email Distribution' [width]="200" headerClass="headerStyle" class="rowStyle">
                                    <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter" let-filterService="filterService">
                                        <multicheck-filter [isPrimitive]="true" [field]="column.field" [filterService]="filterService" [currentFilter]="filter" [data]="distinctPrimitive(column.field)"></multicheck-filter>
                                    </ng-template>
                                </kendo-grid-column>
                                <kendo-grid-excel fileName="Recipes.xlsx" [fetchData]="allData"></kendo-grid-excel>
                            </kendo-grid>
                            <kendo-contextmenu #gridmenu [items]="contextMenuOptionsForRecipe" (select)="onSelectContextMenuItem($event)">
                            </kendo-contextmenu>
                            <!-- <div style="text-align: right; margin-bottom: 10px;" [hidden]="!isBB">
                                <button kendoButton class="btn btn-outline-primary btn-sm" type="button" (click)="exportToExcel(bbGrid)" [disabled]="isEmpty">
                                    <i class="intelicon-ms-excel" aria-hidden="true"></i> Export to Excel
                                </button>
                            </div> -->
                            <kendo-grid id="bbGrid" #bbGrid="kendoGrid" [data]="gridData" [hidden]="!isBB" [style.height.%]="100"
                                        [resizable]="true" filterable="menu" [rowClass]="rowCallback"
                                        (dataStateChange)="dataStateChange_BBGrid($event)"
                                        [skip]="gridSettings_BBGrid.state.skip"
                                        [sort]="gridSettings_BBGrid.state.sort"
                                        [loading]="requestGridLoading"
                                        [filter]="gridSettings_BBGrid.state.filter"
                                        [sortable]="true"
                                        [pageable]="true" 
                                        [pageSize]="gridSettings_BBGrid.state.take"
                                        [reorderable]="true"
                                        [selectable]="selectableSettings"                                       
                                        kendoGridSelectBy [selectedKeys]="selectedRowKeys"
                                        (cellClick)="onCellClick_BBGrid($event)"
                                        [navigable]="true">
                                       <!-- (filterChange)="filterChange_BBGrid($event)"
                                         (pageChange)="pageChange_BBGrid($event)"
                                        (selectionChange)="gridSelectionChange_BBGrid($event)"
                                    -->
                                <!--Ken Disable this Functionality for Future Enhancements - 13/9/2023 [pageable]="true" [pageSize]="gridSettings.state.take" -->
                                <ng-template kendoGridToolbarTemplate>
                                    <div class="excelheader">
                                        <button kendoGridExcelCommand icon="file-excel" [hidden]="!isBB">Export to Excel</button>
                                        <small [hidden]="isBBTemplate"> * BB Checkbox disabled for Draft, Template & Archived Building Blocks</small>
                                    </div>
                                </ng-template>
                                <kendo-grid-checkbox-column field="isSelected" title="Selected" [width]="120" *ngIf="!isBBTemplate">
                                    <ng-template kendoGridCellTemplate let-dataItem >
                                        <input type="checkbox" (click)="onCheckBoxClick_BB(dataItem, $event)"
                                        [checked]="dataItem.isSelected" [disabled]="dataItem.isDraft || dataItem.isArchived || dataItem.isTemplate"
                                        [ngbTooltip]="'Click on check box to select building block for recipe creation. The building block must NOT be archived, a draft, or a template.'"/>
                                    </ng-template>
                                </kendo-grid-checkbox-column> 
                                <kendo-grid-column field='labs' title='Sites' [width]="180" headerClass="headerStyle" class="rowStyle">
                                    <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter" let-filterService="filterService">
                                        <multicheck-filter [isPrimitive]="true" [field]="column.field" [filterService]="filterService" [currentFilter]="filter" [data]="distinctPrimitive(column.field)"></multicheck-filter>
                                    </ng-template>
                                </kendo-grid-column>
                                <kendo-grid-column field='buildingBlockNumber' title='Building Block Number' [width]="250" headerClass="headerStyle" class="rowStyle">
                                    <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter" let-filterService="filterService">
                                        <multicheck-filter [isPrimitive]="true" [field]="column.field" [filterService]="filterService" [currentFilter]="filter" [data]="distinctPrimitive(column.field)"></multicheck-filter>
                                    </ng-template>
                                </kendo-grid-column>
                                <kendo-grid-column field='buildingBlockName' title='Building Block Name' [width]="250" headerClass="headerStyle" class="rowStyle">
                                    <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter" let-filterService="filterService">
                                        <multicheck-filter [isPrimitive]="true" [field]="column.field" [filterService]="filterService" [currentFilter]="filter" [data]="distinctPrimitive(column.field)"></multicheck-filter>
                                    </ng-template>
                                </kendo-grid-column>
                                <kendo-grid-column field='module' title='Module Name' [width]="160" headerClass="headerStyle" class="rowStyle">
                                    <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter" let-filterService="filterService">
                                        <multicheck-filter [isPrimitive]="true" [field]="column.field" [filterService]="filterService" [currentFilter]="filter" [data]="distinctPrimitive(column.field)"></multicheck-filter>
                                    </ng-template>
                                </kendo-grid-column>
                                <kendo-grid-column field='validationTeams' title='Validation Teams' [width]="200" headerClass="headerStyle" class="rowStyle">
                                    <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter" let-filterService="filterService">
                                        <multicheck-filter [isPrimitive]="true" [field]="column.field" [filterService]="filterService" [currentFilter]="filter" [data]="distinctPrimitive(column.field)"></multicheck-filter>
                                    </ng-template>
                                </kendo-grid-column>
                                <kendo-grid-column field='products' title='Products' [width]="200" headerClass="headerStyle" class="rowStyle">
                                    <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter" let-filterService="filterService">
                                        <multicheck-filter [isPrimitive]="true" [field]="column.field" [filterService]="filterService" [currentFilter]="filter" [data]="distinctPrimitive(column.field)"></multicheck-filter>
                                    </ng-template>
                                </kendo-grid-column>
                                <kendo-grid-column field='boardTypes' title='Board Types' [width]="200" headerClass="headerStyle" class="rowStyle">
                                    <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter" let-filterService="filterService">
                                        <multicheck-filter [isPrimitive]="true" [field]="column.field" [filterService]="filterService" [currentFilter]="filter" [data]="distinctPrimitive(column.field)"></multicheck-filter>
                                    </ng-template>
                                </kendo-grid-column>
                                <kendo-grid-column field='owner' title='Owner' [width]="180" headerClass="headerStyle" class="rowStyle">
                                    <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter" let-filterService="filterService">
                                        <multicheck-filter [isPrimitive]="true" [field]="column.field" [filterService]="filterService" [currentFilter]="filter" [data]="distinctPrimitive(column.field)"></multicheck-filter>
                                    </ng-template>
                                </kendo-grid-column>
                                <kendo-grid-column field='coOwner' title='Co-owner' [width]="180" headerClass="headerStyle" class="rowStyle">
                                    <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter" let-filterService="filterService">
                                        <multicheck-filter [isPrimitive]="true" [field]="column.field" [filterService]="filterService" [currentFilter]="filter" [data]="distinctPrimitive(column.field)"></multicheck-filter>
                                    </ng-template>
                                </kendo-grid-column>
                                <kendo-grid-column field='createdDate' title='Created Date' [width]="150" headerClass="headerStyle" class="rowStyle">
                                    <ng-template kendoGridCellTemplate let-dataItem>
                                        {{ dataItem.updatedDate | date: 'MM/dd/yyyy hh:mm a'}}
                                    </ng-template>
                                </kendo-grid-column>
                                <kendo-grid-column field='updatedByName' title='Updated By' [width]="150" headerClass="headerStyle" class="rowStyle">
                                    <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter" let-filterService="filterService">
                                        <multicheck-filter [isPrimitive]="true" [field]="column.field" [filterService]="filterService" [currentFilter]="filter" [data]="distinctPrimitive(column.field)"></multicheck-filter>
                                    </ng-template>
                                </kendo-grid-column>
                                <kendo-grid-column field='updatedDate' title='Updated Date' type='date' [width]="180" headerClass="headerStyle" class="rowStyle">
                                    <ng-template kendoGridCellTemplate let-dataItem>
                                        {{ dataItem.updatedDate | date: 'MM/dd/yyyy hh:mm a'}}
                                    </ng-template>
                                </kendo-grid-column>
                                <kendo-grid-column field='revision' title='Revision' [width]="150" headerClass="headerStyle" class="rowStyle">
                                    <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter" let-filterService="filterService">
                                        <multicheck-filter [isPrimitive]="true" [field]="column.field" [filterService]="filterService" [currentFilter]="filter" [data]="distinctPrimitive(column.field)"></multicheck-filter>
                                    </ng-template>
                                </kendo-grid-column>
                                <kendo-grid-column field='type' title='Type' [width]="100" headerClass="headerStyle" class="rowStyle">
                                    <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter" let-filterService="filterService">
                                        <multicheck-filter [isPrimitive]="true" [field]="column.field" [filterService]="filterService" [currentFilter]="filter" [data]="distinctPrimitive(column.field)"></multicheck-filter>
                                    </ng-template>
                                </kendo-grid-column>
                                <kendo-grid-column field='siliconProgram' title='Silicon Program' [width]="250" headerClass="headerStyle" class="rowStyle">
                                    <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter" let-filterService="filterService">
                                        <multicheck-filter [isPrimitive]="true" [field]="column.field" [filterService]="filterService" [currentFilter]="filter" [data]="distinctPrimitive(column.field)"></multicheck-filter>
                                    </ng-template>
                                </kendo-grid-column>
                                <kendo-grid-column field='rampUp' title='Category' [width]="150" headerClass="headerStyle" class="rowStyle">
                                    <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter" let-filterService="filterService">
                                        <multicheck-filter [isPrimitive]="true" [field]="column.field" [filterService]="filterService" [currentFilter]="filter" [data]="distinctPrimitive(column.field)"></multicheck-filter>
                                    </ng-template>
                                </kendo-grid-column>
                                <kendo-grid-column field='emailDistribution' title='Email Distribution' [width]="200" headerClass="headerStyle" class="rowStyle">
                                    <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter" let-filterService="filterService">
                                        <multicheck-filter [isPrimitive]="true" [field]="column.field" [filterService]="filterService" [currentFilter]="filter" [data]="distinctPrimitive(column.field)"></multicheck-filter>
                                    </ng-template>
                                </kendo-grid-column>
                                <kendo-grid-excel fileName="BBs.xlsx" [fetchData]="allData">
                                    <kendo-excelexport-column field="labs" title="Site(s)"></kendo-excelexport-column>
                                    <kendo-excelexport-column field="buildingBlockNumber" title="Building Block Number"></kendo-excelexport-column>
                                    <kendo-excelexport-column field="buildingBlockName" title="Building Block Name"></kendo-excelexport-column>
                                    <kendo-excelexport-column field="type" title="Type"></kendo-excelexport-column>
                                    <kendo-excelexport-column field="validationTeams" title="Validation Team(s)"></kendo-excelexport-column>
                                    <kendo-excelexport-column field="siliconProgram" title="Silicon Program"></kendo-excelexport-column>
                                    <kendo-excelexport-column field="description" title="Description"></kendo-excelexport-column>
                                    <kendo-excelexport-column field="products" title="Product(s)"></kendo-excelexport-column>
                                    <kendo-excelexport-column field="boardTypes" title="Board Type(s)"></kendo-excelexport-column>
                                    <kendo-excelexport-column field="rampUp" title="Category"></kendo-excelexport-column>
                                    <kendo-excelexport-column field="module" title="Module"></kendo-excelexport-column>
                                    <kendo-excelexport-column field="ownerName" title="Owner Name"></kendo-excelexport-column>
                                    <kendo-excelexport-column field="coOwner" title="Co-Owner(s)"></kendo-excelexport-column>
                                    <kendo-excelexport-column field="createdDate" title="Created Date"></kendo-excelexport-column>
                                    <kendo-excelexport-column field="updatedByName" title="Updated By"></kendo-excelexport-column>
                                    <kendo-excelexport-column field="updatedDate" title="Updated Date"></kendo-excelexport-column>
                                    <kendo-excelexport-column field="emailDistribution" title="Email Distribution"></kendo-excelexport-column>
                                    <kendo-excelexport-column field="application" title="Application"></kendo-excelexport-column>
                                </kendo-grid-excel>
                            </kendo-grid>
                            <kendo-contextmenu #gridmenu [items]="contextMenuOptionsForBB" (select)="onSelectContextMenuItem($event)">
                            </kendo-contextmenu>
                        </div>
                    </div>
                </kendo-splitter-pane>
                <kendo-splitter-pane [collapsible]="true" [collapsed]="isEmpty" size="37%">
                    <br/>
                    <div *ngIf="isBB && !isBBTemplate"  style="border-width: 5px; border-style: none none solid none; border-color: #0054ae; margin-bottom: 10px;">
                        <div style="margin-bottom: 10px;">
                            <table style="width: 100%;">
                                <tr>
                                    <td style="width: 30%;">
                                       <span style="font-weight: bold;"> Create Recipe From BB Actions: </span>
                                    </td>
                                    <td>
                                        <button class="btn btn-primary btn-sm button" type="button" title="Create Recipe" [disabled]="!isCreateRecipeEnabled" (click)="btnCreateRecipe()">
                                            <i class="intelicon-user-approved-selected-outlined" aria-hidden="true"></i>Create Recipe
                                        </button>
                                    </td>
                                </tr>
                                <tr>
                                    <td style="width: 30%;">
                                        <kendo-label class="m-2" style="display:inline-block" text="Total Building Block Count:" [ngbTooltip]="'Total Building Block Count'"></kendo-label>
                                    </td>
                                    <td>
                                        <kendo-label class="m-2" style="display:inline-block" text="{{selectedBuildingBlockList.length}}" [ngbTooltip]="'The total Number of Building Blocks that you have selected so far'"></kendo-label>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <kendo-label class="m-2" style="display:inline-block" text="Selected Building Blocks" [ngbTooltip]="'Your building block selection listed by Building Block Number'"></kendo-label>
                                    </td>
                                    <td>
                                        <div style="height: 70px; overflow-y:auto;">
                                            <kendo-chiplist selection="single"  (selectedChange)="onChipClick($event)" >
                                                <kendo-chip class="col-4" selection="single" *ngFor="let bb of selectedBuildingBlockList" [label]="bb.buildingBlockNumber"
                                               [ngbTooltip]="'Building Block Number: ' + bb.buildingBlockNumber"  [removable]="true" 
                                               (remove)="onRemoveSelectedBBFromList($event)" >
                                                </kendo-chip>
                                            </kendo-chiplist>
                                        </div>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                    <h4> Active {{createMode}}: '{{idNumberName}}'.</h4> 
                    <br/>
                    <h4> {{createMode}} Actions: </h4>
                    <br/>
                    <div class="pane-content" [hidden]="!isBB">
                        <table class="right-panel-table">
                            <tr>
                                <td class="right-panel-table-box">
                                    <button class="btn btn-primary btn-sm button" type="button" title="Copy Building Block" [hidden]="!enableCopy" (click)="btnActionsClick('copy-bb')">
                                        <i class="intelicon-user-approved-selected-outlined" aria-hidden="true"></i>Copy Building Block
                                    </button>
                                </td>
                                <td class="right-panel-table-box">
                                    <button class="btn btn-primary btn-sm button" type="button" title="{{editOrViewButtonLabel}}" [hidden]="!enableViewOrEdit" (click)="btnActionsClick('edit-bb')">
                                        <i class="intelicon-user-approved-selected-outlined" aria-hidden="true"></i>{{editOrViewButtonLabel}}
                                    </button>
                                </td>
                                <td class="right-panel-table-box">
                                    <button class="btn btn-primary btn-sm button" type="button" title="Delete Building Block" [hidden]="!enableDelete" (click)="btnActionsClick('delete-bb')">
                                        <i class="intelicon-user-approved-selected-outlined" aria-hidden="true"></i>Delete Building Block
                                    </button>
                                </td>
                            </tr>
                            <tr>
                                <!--<td class="right-panel-table-box">
                                    <button class="btn btn-primary btn-sm button" type="button" title="Create Recipe" [hidden]="!isCreateRecipeEnabled" (click)="btnCreateRecipe()">
                                        <i class="intelicon-user-approved-selected-outlined" aria-hidden="true"></i>Create Recipe
                                    </button>
                                </td>-->
                                <td class="right-panel-table-box">
                                    <button class="btn btn-primary btn-sm button" type="button" title="Show PDF" (click)="btnShowBBPdf()" [disabled]='isPdfOptionDisabled'>
                                        <i class="intelicon-user-approved-selected-outlined" aria-hidden="true"></i>Show PDF
                                    </button>
                                </td>
                            </tr>
                        </table>
                        <div>
                            <!--<div [hidden]="!this.hideIndividualBBOptions">
                                <table class="request-detail-table">
                                    <tr>
                                        <td class="right-panel-table-box">
                                            <kendo-label class="m-2" style="display:inline-block" text="Total Building Block Count:" [ngbTooltip]="'Total Building Block Count'"></kendo-label>
                                        </td>
                                        <td class="right-panel-table-box">
                                            <kendo-label class="m-2" style="display:inline-block" text="{{selectedBuildingBlockList.length}}" [ngbTooltip]="'The total Number of Building Blocks that you have selected so far'"></kendo-label>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="right-panel-table-box">
                                            <kendo-label class="m-2" style="display:inline-block" text="Selected Building Blocks" [ngbTooltip]="'Your building block selection listed by Building Block Number'"></kendo-label>
                                        </td>
                                        <td class="right-panel-table-box">
                                            <kendo-chip class="col-4" *ngFor="let bb of selectedBuildingBlockList" [label]="bb.buildingBlockNumber"
                                            [removable]="true" (remove)="onRemoveSelectedBBFromList($event)" [ngbTooltip]="'Building Block Number: ' + bb.buildingBlockNumber">
                                            </kendo-chip>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="right-panel-table-box">
                                            <kendo-label class="m-2" style="display:inline-block" text="Quick Instructions to create a recipe:" [ngbTooltip]="'Quick Instructions to create a recipe'"></kendo-label>
                                        </td>
                                        <td class="right-panel-table-box">
                                            <kendo-label class="m-2" style="display:inline-block" text="Please select building blocks from the main grid by clicking on the 'Selected' checkbox column and when ready click on 'Create Recipe' button above. You will be taken to another page where additional information will be required." [ngbTooltip]="'Instruction Details'"></kendo-label>
                                        </td>
                                    </tr>
                                </table>
                            </div>-->
                            <br/>
                            <h4> {{createMode}} Information: </h4>
                            <br/>
                            <kendo-tabstrip (tabSelect)="onTabSelect($event)">
                                <kendo-tabstrip-tab [title]="'BB Details'" [selected]="true">
                                    <ng-template kendoTabContent>
                                        <div>
                                            <table class="request-detail-table">
                                                <tr>
                                                    <td class="right-panel-table-box">
                                                        <kendo-label class="m-2" style="display:inline-block" text="Template" [ngbTooltip]="'Indicates if currently selected record is a template.'"></kendo-label>
                                                        <kendo-switch [(ngModel)]="isItATemplate" [disabled]="true" [ngModelOptions]="{standalone: true}"></kendo-switch>
                                                    </td>
                                                    <td class="right-panel-table-box">
                                                        <kendo-label class="m-2" style="display:inline-block" text="Draft" [ngbTooltip]="'Indicates if currently selected record is in draft state.'"></kendo-label>
                                                        <kendo-switch [(ngModel)]="isItADraft" [disabled]="true" [ngModelOptions]="{standalone: true}"></kendo-switch>
                                                    </td>
                                                    <td class="right-panel-table-box">
                                                        <kendo-label class="m-2" style="display:inline-block" text="Archived" [ngbTooltip]="'Indicates if currently selected record has been archived.'"></kendo-label>
                                                        <kendo-switch [(ngModel)]="isItArchived" [disabled]="true" [ngModelOptions]="{standalone: true}"></kendo-switch>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="right-panel-table-box">
                                                        <kendo-label [for]="forId" text="Building Block Number" class="label"></kendo-label>
                                                        <input #forId type="text" class="form-control form-control-sm text-box" [(ngModel)]="idNumber" readonly="true" [ngModelOptions]="{standalone: true}" />
                                                    </td>
                                                    <td class="right-panel-table-box">
                                                        <kendo-label [for]="forCreatedDate" text="Created Date" class="label"></kendo-label>
                                                        <input #forCreatedDate type="text" class="form-control form-control-sm text-box" [ngModel]="createdDate | date: 'MM/dd/yyyy hh:mm a'" readonly="true" [ngModelOptions]="{standalone: true}"  />
                                                    </td>
                                                    <td class="right-panel-table-box">
                                                        <kendo-label [for]="forAssignedTech" text="Created By" class="label"></kendo-label>
                                                        <input #forAssignedTech type="text" class="form-control form-control-sm text-box" [(ngModel)]="createdBy" readonly="true" [ngModelOptions]="{standalone: true}" />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="right-panel-table-box">
                                                        <kendo-label [for]="forTransferType" text="Description" class="label"></kendo-label>
                                                        <input #forTransferType type="text" class="form-control form-control-sm text-box" [(ngModel)]="description" readonly="true" [ngModelOptions]="{standalone: true}" />
                                                    </td>
                                                    <td class="right-panel-table-box">
                                                        <kendo-label [for]="forUpdatedDate" text="Silicon Program" class="label"></kendo-label>
                                                        <input #forUpdatedDate type="text" class="form-control form-control-sm text-box" [(ngModel)]="siliconProgram" readonly="true" [ngModelOptions]="{standalone: true}" />
                                                    </td>
                                                    <td class="right-panel-table-box">
                                                        <kendo-label [for]="forIcStatus" text="Module" class="label"></kendo-label>
                                                        <input #forIcStatus type="text" class="form-control form-control-sm text-box" [(ngModel)]="module" readonly="true" [ngModelOptions]="{standalone: true}" />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="right-panel-table-box">
                                                        <kendo-label [for]="forTransferType" text="Parent Building Block Number" class="label"></kendo-label>
                                                        <input #forTransferType type="text" class="form-control form-control-sm text-box" [(ngModel)]="parentBBNumber" readonly="true" [ngModelOptions]="{standalone: true}" />
                                                    </td>
                                                    <td class="right-panel-table-box">
                                                        <kendo-label [for]="forUpdatedDate" text="Parent Building Block Revision" class="label"></kendo-label>
                                                        <input #forUpdatedDate type="text" class="form-control form-control-sm text-box" [(ngModel)]="parentBBRevision" readonly="true" [ngModelOptions]="{standalone: true}" />
                                                    </td>
                                                    <td class="right-panel-table-box">
                                                        <kendo-label [for]="forIcStatus" text="Status" class="label"></kendo-label>
                                                        <input #forIcStatus type="text" class="form-control form-control-sm text-box" [(ngModel)]="status" readonly="true" [ngModelOptions]="{standalone: true}" />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="right-panel-table-box">
                                                        <kendo-label [for]="forStatus" text="Recipe Count" class="label"></kendo-label>
                                                        <input #forStatus type="text" class=" form-control form-control-sm text-box" [(ngModel)]="recipeCount" readonly="true" [ngModelOptions]="{standalone: true}" />
                                                    </td>
                                                    <td class="right-panel-table-box">
                                                        <kendo-label [for]="forMurNumber" text="Modified Date" class="label"></kendo-label>
                                                        <input #forMurNumber type="text" class=" form-control form-control-sm text-box" [ngModel]="updatedDate | date: 'MM/dd/yyyy hh:mm a' " readonly="true" [ngModelOptions]="{standalone: true}"   />
                                                    </td>
                                                    <td class="right-panel-table-box">
                                                        <kendo-label [for]="forIcUpdatedDate" text="Modified By" class="label"></kendo-label>
                                                        <input #forIcUpdatedDate type="text" class=" form-control form-control-sm text-box" [(ngModel)]="updatedBy" readonly="true" [ngModelOptions]="{standalone: true}"/>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="right-panel-table-box" colspan="3">
                                                        <kendo-label [for]="forStatus" text="Check List Details" class="label"></kendo-label>
                                                        <!--<input #forStatus type="text" class=" form-control form-control-sm text-box" [(ngModel)]="checkList" readonly="true" [ngModelOptions]="{standalone: true}" height="50" />--> <!--Text Input of CheckList is updated into table format on 24 July 2023 by Khoo Lay Ken-->

                                                        <div class="container-checklist"> <!--Table Format CheckLists, CSS changes and scrollbar on 17 July 2023 by Khoo Lay Ken -->
                                                            <table class ="table-checklist"> <!--style="width:100%; border: 1px solid black;"-->

                                                                <thead>
                                                                    <tr>
                                                                        <th class="checklist-table" style="text-align: center; background-color: #0067c5; color: white;">Type</th>
                                                                        <th class="checklist-table" style="text-align: center; background-color: #0067c5; color: white;">Name</th>
                                                                        <th class="checklist-table" style="text-align: center; background-color: #0067c5; color: white;">Required</th>
                                                                    </tr>
                                                                </thead>

                                                                <tbody class = "checklist-body">
                                                                    <tr *ngFor="let dt of checkListData">
                                                                        <td class="checklist-table" style = "padding-left:10px;">{{dt.type}}</td>
                                                                        <td class="checklist-table" style = "padding-left:10px;">{{dt.name}}</td>
                                                                        <td class="checklist-table" style = "padding-left:10px;">{{dt.isRequired}}</td>
                                                                    </tr>
                                                                </tbody>

                                                            </table>
                                                        </div>

                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                    </ng-template>
                                </kendo-tabstrip-tab>
                                <kendo-tabstrip-tab [title]="'Recipes with this BB'">
                                    <ng-template kendoTabContent>
                                        <!-- <div style="text-align: right; margin-bottom: 10px;">
                                            <button class="btn btn-outline-primary btn-sm" type="button" (click)="exportToExcel(recipesWithBBGrid)" [hidden]="!searchRowFound">
                                                <i class="intelicon-ms-excel" aria-hidden="true"></i> Export to Excel
                                            </button>
                                        </div> -->
                                        <div>
                                        </div>
                                        <div>
                                            <kendo-label text="Recipes List:" [hidden]="isBBRevisionHistoryOn" class="label"><br/></kendo-label>
                                            <kendo-label text="No recipes consuming this building block exist yet." [hidden]="searchRowFound" class="label"></kendo-label>
                                            <kendo-grid #recipesWithBBGrid="kendoGrid" [hidden]="!searchRowFound" [data]="recipeWithBuildingBlocksGridData" [style.height.%]="100"
                                                        [resizable]="true"
                                                        filterable="menu" [rowClass]="rowCallback"
                                                        (dataStateChange)="dataStateChange($event,'recipewithbbs')"
                                                        [skip]="gridSettings.state.skip"
                                                        [sort]="gridSettings.state.sort"
                                                        [loading]="recipeWithBBGridLoading"
                                                        [filter]="gridSettings.state.filter"
                                                        [sortable]="true"
                                                        [pageable]="true" 
                                                        [pageSize]="gridSettings.state.take"
                                                        [reorderable]="true"
                                                        [selectable]="selectableSettings"
                                                        (filterChange)="searchGridFilterChange($event,'recipewithbbs')"
                                                        (pageChange)="pageChange($event)"
                                                        kendoGridSelectBy
                                                        [navigable]="true">
                                                <ng-template kendoGridToolbarTemplate>
                                                    <button kendoGridExcelCommand icon="file-excel" [hidden]="!searchRowFound">Export to Excel</button>
                                                </ng-template>        
                                                <kendo-grid-column field='labs' title='Sites' [width]="180" headerClass="headerStyle" class="rowStyle">
                                                    <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter" let-filterService="filterService">
                                                        <multicheck-filter [isPrimitive]="true" [field]="column.field" [filterService]="filterService" [currentFilter]="filter" [data]="distinctPrimitive(column.field)"></multicheck-filter>
                                                    </ng-template>
                                                </kendo-grid-column>
                                                <kendo-grid-column field='recipeNumber' title='Recipe Number' [width]="190" headerClass="headerStyle" class="rowStyle">
                                                    <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter" let-filterService="filterService">
                                                        <multicheck-filter [isPrimitive]="true" [field]="column.field" [filterService]="filterService" [currentFilter]="filter" [data]="distinctPrimitive(column.field)"></multicheck-filter>
                                                    </ng-template>
                                                </kendo-grid-column>
                                                <kendo-grid-column field='recipeName' title='Recipe Name' [width]="160" headerClass="headerStyle" class="rowStyle">
                                                    <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter" let-filterService="filterService">
                                                        <multicheck-filter [isPrimitive]="true" [field]="column.field" [filterService]="filterService" [currentFilter]="filter" [data]="distinctPrimitive(column.field)"></multicheck-filter>
                                                    </ng-template>
                                                </kendo-grid-column>
                                                <kendo-grid-column field='products' title='Products' [width]="150" headerClass="headerStyle" class="rowStyle">
                                                    <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter" let-filterService="filterService">
                                                        <multicheck-filter [isPrimitive]="true" [field]="column.field" [filterService]="filterService" [currentFilter]="filter" [data]="distinctPrimitive(column.field)"></multicheck-filter>
                                                    </ng-template>
                                                </kendo-grid-column>
                                                <kendo-grid-column field='siliconProgram' title='Silicon Program' [width]="180" headerClass="headerStyle" class="rowStyle">
                                                    <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter" let-filterService="filterService">
                                                        <multicheck-filter [isPrimitive]="true" [field]="column.field" [filterService]="filterService" [currentFilter]="filter" [data]="distinctPrimitive(column.field)"></multicheck-filter>
                                                    </ng-template>
                                                </kendo-grid-column>
                                                <kendo-grid-column field='rampUp' title='Category' [width]="140" headerClass="headerStyle" class="rowStyle">
                                                    <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter" let-filterService="filterService">
                                                        <multicheck-filter [isPrimitive]="true" [field]="column.field" [filterService]="filterService" [currentFilter]="filter" [data]="distinctPrimitive(column.field)"></multicheck-filter>
                                                    </ng-template>
                                                </kendo-grid-column>
                                                <kendo-grid-column field='owner' title='Owner' [width]="150" headerClass="headerStyle" class="rowStyle">
                                                    <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter" let-filterService="filterService">
                                                        <multicheck-filter [isPrimitive]="true" [field]="column.field" [filterService]="filterService" [currentFilter]="filter" [data]="distinctPrimitive(column.field)"></multicheck-filter>
                                                    </ng-template>
                                                </kendo-grid-column>
                                                <kendo-grid-column field='coOwner' title='Co-owner' [width]="150" headerClass="headerStyle" class="rowStyle">
                                                    <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter" let-filterService="filterService">
                                                        <multicheck-filter [isPrimitive]="true" [field]="column.field" [filterService]="filterService" [currentFilter]="filter" [data]="distinctPrimitive(column.field)"></multicheck-filter>
                                                    </ng-template>
                                                </kendo-grid-column>
                                                <kendo-grid-column field='createdDate' title='Created Date' [width]="150" headerClass="headerStyle" class="rowStyle">
                                                    <ng-template kendoGridCellTemplate let-dataItem>
                                                        {{ dataItem.updatedDate | date: 'MM/dd/yyyy hh:mm a'}}
                                                    </ng-template>
                                                </kendo-grid-column>
                                                <kendo-grid-column field='updatedByName' title='Updated By' [width]="150" headerClass="headerStyle" class="rowStyle">
                                                    <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter" let-filterService="filterService">
                                                        <multicheck-filter [isPrimitive]="true" [field]="column.field" [filterService]="filterService" [currentFilter]="filter" [data]="distinctPrimitive(column.field)"></multicheck-filter>
                                                    </ng-template>
                                                </kendo-grid-column>
                                                <kendo-grid-column field='updatedDate' title='Updated Date' type='date' [width]="180" headerClass="headerStyle" class="rowStyle">
                                                    <ng-template kendoGridCellTemplate let-dataItem>
                                                        {{ dataItem.updatedDate | date: 'MM/dd/yyyy hh:mm a'}}
                                                    </ng-template>
                                                </kendo-grid-column>
                                                <kendo-grid-column field='revision' title='Revision' [width]="120" headerClass="headerStyle" class="rowStyle">
                                                    <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter" let-filterService="filterService">
                                                        <multicheck-filter [isPrimitive]="true" [field]="column.field" [filterService]="filterService" [currentFilter]="filter" [data]="distinctPrimitive(column.field)"></multicheck-filter>
                                                    </ng-template>
                                                </kendo-grid-column>
                                                <kendo-grid-column field='recipeComments' title='Comments' [width]="200" headerClass="headerStyle" class="rowStyle">
                                                    <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter" let-filterService="filterService">
                                                        <multicheck-filter [isPrimitive]="true" [field]="column.field" [filterService]="filterService" [currentFilter]="filter" [data]="distinctPrimitive(column.field)"></multicheck-filter>
                                                    </ng-template>
                                                </kendo-grid-column>
                                                <kendo-grid-column field='emailDistribution' title='Email Distribution' [width]="200" headerClass="headerStyle" class="rowStyle">
                                                    <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter" let-filterService="filterService">
                                                        <multicheck-filter [isPrimitive]="true" [field]="column.field" [filterService]="filterService" [currentFilter]="filter" [data]="distinctPrimitive(column.field)"></multicheck-filter>
                                                    </ng-template>
                                                </kendo-grid-column>
                                                <kendo-grid-excel fileName="Recipes.xlsx" [fetchData]="recipesData"></kendo-grid-excel>
                                            </kendo-grid>
                                        </div>
                                    </ng-template>
                                </kendo-tabstrip-tab>
                                <kendo-tabstrip-tab [title]="'BB Revision History'">
                                    <ng-template kendoTabContent>
                                        <!-- <div style="text-align: right; margin-bottom: 10px;">
                                            <button class="btn btn-outline-primary btn-sm" type="button" (click)="exportToExcel(bbRevHistoryGrid)" [hidden]="!searchRowFound">
                                                <i class="intelicon-ms-excel" aria-hidden="true"></i> Export to Excel
                                            </button>
                                        </div> -->
                                        <div>
                                        </div>
                                        <div>
                                            <kendo-label text="Revision History Information:" class="label"><br></kendo-label>
                                            <kendo-label text="No Building Block Revision History exists for this Building Block." [hidden]="searchRowFound" class="label"></kendo-label>
                                            <kendo-grid #bbRevHistoryGrid="kendoGrid" [hidden]="!searchRowFound" [data]="bbHistoryGridData"
                                                        [style.height.%]="100"
                                                        [resizable]="true"
                                                        filterable="menu" [rowClass]="rowCallback"
                                                        (dataStateChange)="dataStateChange($event,'bbhistory')"
                                                        [pageSize]="gridSettings.state.take"
                                                        [skip]="gridSettings.state.skip"
                                                        [sort]="gridSettings.state.sort"
                                                        [loading]="buildingBlockRevHistoryGridLoading"
                                                        [filter]="gridSettings.state.filter"
                                                        [sortable]="true"
                                                        [pageable]="true"
                                                        [reorderable]="true"
                                                        [selectable]="selectableSettings"
                                                        (filterChange)="searchGridFilterChange($event, 'bbhistory')"
                                                        (pageChange)="pageChange($event)"
                                                        kendoGridSelectBy
                                                        [navigable]="true">
                                                <ng-template kendoGridToolbarTemplate>
                                                    <button kendoGridExcelCommand icon="file-excel" [hidden]="!searchRowFound">Export to Excel</button>
                                                </ng-template>
                                                <kendo-grid-column field='labs' title='Sites' [width]="180" headerClass="headerStyle" class="rowStyle">
                                                    <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter" let-filterService="filterService">
                                                        <multicheck-filter [isPrimitive]="true" [field]="column.field" [filterService]="filterService" [currentFilter]="filter" [data]="distinctPrimitive(column.field)"></multicheck-filter>
                                                    </ng-template>
                                                </kendo-grid-column>
                                                <kendo-grid-column field='buildingBlockNumber' title='Building Block Number' [width]="250" headerClass="headerStyle" class="rowStyle">
                                                    <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter" let-filterService="filterService">
                                                        <multicheck-filter [isPrimitive]="true" [field]="column.field" [filterService]="filterService" [currentFilter]="filter" [data]="distinctPrimitive(column.field)"></multicheck-filter>
                                                    </ng-template>
                                                </kendo-grid-column>
                                                <kendo-grid-column field='buildingBlockName' title='Building Block Name' [width]="250" headerClass="headerStyle" class="rowStyle">
                                                    <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter" let-filterService="filterService">
                                                        <multicheck-filter [isPrimitive]="true" [field]="column.field" [filterService]="filterService" [currentFilter]="filter" [data]="distinctPrimitive(column.field)"></multicheck-filter>
                                                    </ng-template>
                                                </kendo-grid-column>
                                                <kendo-grid-column field='type' title='Type' [width]="160" headerClass="headerStyle" class="rowStyle">
                                                    <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter" let-filterService="filterService">
                                                        <multicheck-filter [isPrimitive]="true" [field]="column.field" [filterService]="filterService" [currentFilter]="filter" [data]="distinctPrimitive(column.field)"></multicheck-filter>
                                                    </ng-template>
                                                </kendo-grid-column>
                                                <kendo-grid-column field='validationTeams' title='Validation Teams' [width]="200" headerClass="headerStyle" class="rowStyle">
                                                    <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter" let-filterService="filterService">
                                                        <multicheck-filter [isPrimitive]="true" [field]="column.field" [filterService]="filterService" [currentFilter]="filter" [data]="distinctPrimitive(column.field)"></multicheck-filter>
                                                    </ng-template>
                                                </kendo-grid-column>
                                                <kendo-grid-column field='siliconProgram' title='Silicon Program' [width]="160" headerClass="headerStyle" class="rowStyle">
                                                    <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter" let-filterService="filterService">
                                                        <multicheck-filter [isPrimitive]="true" [field]="column.field" [filterService]="filterService" [currentFilter]="filter" [data]="distinctPrimitive(column.field)"></multicheck-filter>
                                                    </ng-template>
                                                </kendo-grid-column>
                                                <kendo-grid-column field='description' title='Description' [width]="160" headerClass="headerStyle" class="rowStyle">
                                                    <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter" let-filterService="filterService">
                                                        <multicheck-filter [isPrimitive]="true" [field]="column.field" [filterService]="filterService" [currentFilter]="filter" [data]="distinctPrimitive(column.field)"></multicheck-filter>
                                                    </ng-template>
                                                </kendo-grid-column>
                                                <kendo-grid-column field='products' title='Products' [width]="200" headerClass="headerStyle" class="rowStyle">
                                                    <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter" let-filterService="filterService">
                                                        <multicheck-filter [isPrimitive]="true" [field]="column.field" [filterService]="filterService" [currentFilter]="filter" [data]="distinctPrimitive(column.field)"></multicheck-filter>
                                                    </ng-template>
                                                </kendo-grid-column>
                                                <kendo-grid-column field='boardTypes' title='Board Types' [width]="200" headerClass="headerStyle" class="rowStyle">
                                                    <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter" let-filterService="filterService">
                                                        <multicheck-filter [isPrimitive]="true" [field]="column.field" [filterService]="filterService" [currentFilter]="filter" [data]="distinctPrimitive(column.field)"></multicheck-filter>
                                                    </ng-template>
                                                </kendo-grid-column>
                                                <kendo-grid-column field='rampUp' title='Category' [width]="150" headerClass="headerStyle" class="rowStyle">
                                                    <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter" let-filterService="filterService">
                                                        <multicheck-filter [isPrimitive]="true" [field]="column.field" [filterService]="filterService" [currentFilter]="filter" [data]="distinctPrimitive(column.field)"></multicheck-filter>
                                                    </ng-template>
                                                </kendo-grid-column>
                                                <kendo-grid-column field='module' title='Module' [width]="150" headerClass="headerStyle" class="rowStyle">
                                                    <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter" let-filterService="filterService">
                                                        <multicheck-filter [isPrimitive]="true" [field]="column.field" [filterService]="filterService" [currentFilter]="filter" [data]="distinctPrimitive(column.field)"></multicheck-filter>
                                                    </ng-template>
                                                </kendo-grid-column>
                                                <kendo-grid-column field='ownerName' title='Owner' [width]="150" headerClass="headerStyle" class="rowStyle">
                                                    <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter" let-filterService="filterService">
                                                        <multicheck-filter [isPrimitive]="true" [field]="column.field" [filterService]="filterService" [currentFilter]="filter" [data]="distinctPrimitive(column.field)"></multicheck-filter>
                                                    </ng-template>
                                                </kendo-grid-column>
                                                <kendo-grid-column field='coOwner' title='Co-owner' [width]="150" headerClass="headerStyle" class="rowStyle">
                                                    <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter" let-filterService="filterService">
                                                        <multicheck-filter [isPrimitive]="true" [field]="column.field" [filterService]="filterService" [currentFilter]="filter" [data]="distinctPrimitive(column.field)"></multicheck-filter>
                                                    </ng-template>
                                                </kendo-grid-column>
                                                <kendo-grid-column field='createdDate' title='Created Date' [width]="150" headerClass="headerStyle" class="rowStyle">
                                                    <ng-template kendoGridCellTemplate let-dataItem>
                                                        {{ dataItem.updatedDate | date: 'MM/dd/yyyy hh:mm a'}}
                                                    </ng-template>
                                                </kendo-grid-column>
                                                <kendo-grid-column field='updatedByName' title='Updated By' [width]="150" headerClass="headerStyle" class="rowStyle">
                                                    <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter" let-filterService="filterService">
                                                        <multicheck-filter [isPrimitive]="true" [field]="column.field" [filterService]="filterService" [currentFilter]="filter" [data]="distinctPrimitive(column.field)"></multicheck-filter>
                                                    </ng-template>
                                                </kendo-grid-column>
                                                <kendo-grid-column field='updatedDate' title='Updated Date' type='date' [width]="180" headerClass="headerStyle" class="rowStyle">
                                                    <ng-template kendoGridCellTemplate let-dataItem>
                                                        {{ dataItem.updatedDate | date: 'MM/dd/yyyy hh:mm a'}}
                                                    </ng-template>
                                                </kendo-grid-column>
                                                <kendo-grid-column field='emailDistribution' title='Email Distribution' [width]="200" headerClass="headerStyle" class="rowStyle">
                                                    <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter" let-filterService="filterService">
                                                        <multicheck-filter [isPrimitive]="true" [field]="column.field" [filterService]="filterService" [currentFilter]="filter" [data]="distinctPrimitive(column.field)"></multicheck-filter>
                                                    </ng-template>
                                                </kendo-grid-column>
                                                <kendo-grid-excel fileName="BBRevHistoryDetails.xlsx" [fetchData]="bbRevHistoryData"></kendo-grid-excel>
                                            </kendo-grid>
                                        </div>
                                    </ng-template>
                                </kendo-tabstrip-tab>
                            </kendo-tabstrip>
                            <br/>
                            <div>
                                <br/>
                                <kendo-panelbar *ngIf="!isPdfOptionDisabled">
                                    <kendo-panelbar-item *ngFor="let item of buildingBlockDetailsGrid" title="Building Blocks" [expanded]="false">
                                        <ng-template kendoPanelBarItemTitle>
                                            {{item.buildingBlockName}}
                                        </ng-template>
                                        <ng-template kendoPanelBarContent>
                                            <div>
                                                <a *ngIf="item.content" [href]="fileUrl+encodeURLContent(item.content)" target="_new">Open PDF (new tab)</a>
                                                <div [style.display]="isPdfOptionDisabled ? 'none':'block'">
                                                    <span *ngIf="checkListContentLoading[item.id].loaded">
                                                        <kendo-loader type="converging-spinner" size="medium"> </kendo-loader>
                                                    </span>
                                                    <pdf-viewer style="width: 100%; height: 600px" [src]="{url: fileUrl+encodeURLContent(item.content)}" [render-text]="true" [original-size]="false"
                                                    (after-load-complete)="checkListContentLoading[item.id].loaded=false" (on-progress)="onloadingPdfProgress($event, item.id)" >
                                                    </pdf-viewer>
                                                </div>
                                            </div>
                                        </ng-template>
                                    </kendo-panelbar-item>
                                </kendo-panelbar>
                            </div>
                        </div>
                    </div>
                    <div class="pane-content" [hidden]="isBB">
                        <table class="right-panel-table">
                            <tr>
                                <td class="right-panel-table-box">
                                    <!-- <button class="btn btn-primary btn-sm button" type="button" (click)="btnCreateHsdEs()" [disabled]="isHsdButtonDisabled" [ngClass]="{'intel-blue' : btnToggle, 'red': !btnToggle}" [ngbTooltip]="'After creating HSD-ES articles, you will be expected to click here again to update records in RM.'"> -->
                                    <button class="btn btn-primary btn-sm button" type="button" (click)="btnCreateHsdEs()" [ngClass]="{'intel-blue' : btnToggle}" [disabled]="isHsdButtonDisabled">
                                        <i class="intelicon-user-approved-selected-outlined" aria-hidden="true"></i>{{createHSD}}
                                    </button>
                                </td>
                                <td class="right-panel-table-box">
                                    <!-- <button class="btn btn-primary btn-sm button" type="button" (click)="btnOpenHsdEsArticlesPopupWindow()" [disabled]="isAssignHsdEsToCCDisabled"> -->
                                    <button class="btn btn-primary btn-sm button" type="button" (click)="btnAssignHsdEs()" [disabled]="isAssignHsdEsToCCDisabled">
                                        <i class="intelicon-user-approved-selected-outlined" aria-hidden="true"></i>{{assignHSD}}
                                    </button>
                                </td>
                                <td class="right-panel-table-box">
                                    <button class="btn btn-primary btn-sm button" type="button" title="Show PDF" (click)="btnShowRecipePdf()" [disabled]='isPdfOptionDisabled'>
                                        <i class="intelicon-user-approved-selected-outlined" aria-hidden="true"></i>Show PDF
                                    </button>
                                </td>
                            </tr>
                            <tr>
                                <td class="right-panel-table-box">
                                    <button class="btn btn-primary btn-sm button" type="button" title="{{editOrViewButtonLabel}}" [hidden]="!enableViewOrEdit" (click)="btnActionsClick('edit-recipe')">
                                        <i class="intelicon-user-approved-selected-outlined" aria-hidden="true"></i>{{editOrViewButtonLabel}}
                                    </button>
                                </td>
                                <td class="right-panel-table-box">
                                    <button class="btn btn-primary btn-sm button" type="button" title="Delete Recipe" [hidden]="!enableDelete" (click)="btnActionsClick('delete-recipe')">
                                        <i class="intelicon-user-approved-selected-outlined" aria-hidden="true"></i>Delete Recipe
                                    </button>
                                </td>
                                <td class="right-panel-table-box">
                                    <button class="btn btn-primary btn-sm button" type="button" title="Copy Recipe" [hidden]="!enableCopy" (click)="btnActionsClick('copy-recipe')">
                                        <i class="intelicon-user-approved-selected-outlined" aria-hidden="true"></i>Copy Recipe
                                    </button>
                                </td>
                            </tr>
                        </table>
                        <br/>
                        <h4> {{createMode}} Information: </h4>
                        <br/>
                        <div>
                            <kendo-tabstrip (tabSelect)="onTabSelect($event)">
                                <kendo-tabstrip-tab [title]="'Recipe Details'" [selected]="true">
                                    <ng-template kendoTabContent>
                                        <div>
                                            <table class="request-detail-table">
                                                <tr>
                                                    <td class="right-panel-table-box">
                                                        <kendo-label class="m-2" style="display:inline-block" text="Template" [ngbTooltip]="'Indicates if currently selected record is a template.'"></kendo-label>
                                                        <kendo-switch [(ngModel)]="isItATemplate" [disabled]="true" [ngModelOptions]="{standalone: true}"></kendo-switch>
                                                    </td>
                                                    <td class="right-panel-table-box">
                                                        <kendo-label class="m-2" style="display:inline-block" text="Draft" [ngbTooltip]="'Indicates if currently selected record is in draft state.'"></kendo-label>
                                                        <kendo-switch [(ngModel)]="isItADraft" [disabled]="true" [ngModelOptions]="{standalone: true}"></kendo-switch>
                                                    </td>
                                                    <td class="right-panel-table-box">
                                                        <kendo-label class="m-2" style="display:inline-block" text="Archived" [ngbTooltip]="'Indicates if currently selected record has been archived.'"></kendo-label>
                                                        <kendo-switch [(ngModel)]="isItArchived" [disabled]="true" [ngModelOptions]="{standalone: true}"></kendo-switch>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="right-panel-table-box">
                                                        <kendo-label [for]="forId" text="Recipe Number" class="label"></kendo-label>
                                                        <input #forId type="text" class="form-control form-control-sm text-box" [(ngModel)]="idNumber" readonly="true" [ngModelOptions]="{standalone: true}" />
                                                    </td>
                                                    <td class="right-panel-table-box">
                                                        <kendo-label [for]="forCreatedDate" text="Created Date" class="label"></kendo-label>
                                                        <input #forCreatedDate type="text" class="form-control form-control-sm text-box" [ngModel]="createdDate | date: 'MM/dd/yyyy hh:mm a'" readonly="true" [ngModelOptions]="{standalone: true}"  />
                                                    </td>
                                                    <td class="right-panel-table-box">
                                                        <kendo-label [for]="forAssignedTech" text="Created By" class="label"></kendo-label>
                                                        <input #forAssignedTech type="text" class="form-control form-control-sm text-box" [(ngModel)]="createdBy" readonly="true" [ngModelOptions]="{standalone: true}" />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="right-panel-table-box">
                                                        <kendo-label [for]="forTransferType" text="Parent Recipe Number" class="label"></kendo-label>
                                                        <input #forTransferType type="text" class="form-control form-control-sm text-box" [(ngModel)]="parentRecipeNumber" readonly="true" [ngModelOptions]="{standalone: true}" />
                                                    </td>
                                                    <td class="right-panel-table-box">
                                                        <kendo-label [for]="forUpdatedDate" text="Parent Recipe Revision" class="label"></kendo-label>
                                                        <input #forUpdatedDate type="text" class="form-control form-control-sm text-box" [(ngModel)]="parentRecipeRevision" readonly="true" [ngModelOptions]="{standalone: true}" />
                                                    </td>
                                                    <td class="right-panel-table-box">
                                                        <kendo-label [for]="forIcStatus" text="Status" class="label"></kendo-label>
                                                        <input #forIcStatus type="text" class="form-control form-control-sm text-box" [(ngModel)]="status" readonly="true" [ngModelOptions]="{standalone: true}" />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="right-panel-table-box">
                                                        <kendo-label [for]="forTransferType" text="Description" class="label"></kendo-label>
                                                        <input #forTransferType type="text" class="form-control form-control-sm text-box" [(ngModel)]="description" readonly="true" [ngModelOptions]="{standalone: true}" />
                                                    </td>
                                                    <td class="right-panel-table-box">
                                                        <kendo-label [for]="forUpdatedDate" text="Silicon Program" class="label"></kendo-label>
                                                        <input #forUpdatedDate type="text" class="form-control form-control-sm text-box" [(ngModel)]="siliconProgram" readonly="true" [ngModelOptions]="{standalone: true}" />
                                                    </td>
                                                    <td class="right-panel-table-box">
                                                        <!-- <kendo-label [for]="forIcStatus" text="Ramp-Up" class="label"></kendo-label> -->
                                                        <kendo-label [for]="forIcStatus" text="Category" class="label"></kendo-label>
                                                        <input #forIcStatus type="text" class="form-control form-control-sm text-box" [(ngModel)]="rampUp" readonly="true" [ngModelOptions]="{standalone: true}" />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="right-panel-table-box">
                                                        <kendo-label [for]="forStatus" text="Building Block Count" class="label"></kendo-label>
                                                        <input #forStatus type="text" class=" form-control form-control-sm text-box" [(ngModel)]="recipeCount" readonly="true" [ngModelOptions]="{standalone: true}" />
                                                    </td>
                                                    <td class="right-panel-table-box">
                                                        <kendo-label [for]="forMurNumber" text="Modified Date" class="label"></kendo-label>
                                                        <input #forMurNumber type="text" class=" form-control form-control-sm text-box" [ngModel]="updatedDate | date: 'MM/dd/yyyy hh:mm a'" readonly="true" [ngModelOptions]="{standalone: true}" />
                                                    </td>
                                                    <td class="right-panel-table-box">
                                                        <kendo-label [for]="forIcUpdatedDate" text="Modified By" class="label"></kendo-label>
                                                        <input #forIcUpdatedDate type="text" class=" form-control form-control-sm text-box" [(ngModel)]="updatedBy" readonly="true" [ngModelOptions]="{standalone: true}"/>
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                        <div>
                                            <br/>
                                            <span [hidden]="isRecipeEmpty" style="font-size:1.5rem; font-weight:400;"> Building Blocks in Active {{createMode}}: </span>
                                            <br>
                                            <!-- <div style="text-align: right; margin-bottom: 10px;">
                                                <button kendoButton class="btn btn-outline-primary btn-sm" type="button" (click)="exportToExcel(bbGrid)" [hidden]="isRecipeEmpty">
                                                    <i class="intelicon-ms-excel" aria-hidden="true"></i> Export to Excel
                                                </button>
                                            </div> -->
                                            <span [hidden]="selectedBBMessage" style="color:red; padding-left:5px;"> *BB Required <span style="color:red;" [ngbTooltip]="'At least one BB must be selected to Create HSD-ES/CC or Assign HSD-ES to CC'">(?)</span></span>
                                            <kendo-grid #bbGrid="kendoGrid" [data]="supportGridData" [hidden]="isRecipeEmpty" [style.height.%]="50" [height]="400"
                                                        [resizable]="true" filterable="menu" [rowClass]="rowCallback"
                                                        (dataStateChange)="dataStateChange($event,'buildingblockdetails')"
                                                        [pageSize]="gridSettings.state.take"
                                                        [skip]="gridSettings.state.skip"
                                                        [sort]="gridSettings.state.sort"
                                                        [loading]="isSupportGridDataLoading"
                                                        [filter]="gridSettings.state.filter"
                                                        [sortable]="true"
                                                        [pageable]="true"
                                                        [reorderable]="true"
                                                        [selectable]="selectableSettings"
                                                        (filterChange)="searchGridFilterChange($event,'buildingblockdetails')"
                                                        (pageChange)="pageChange($event)"
                                                        kendoGridSelectBy
                                                        [selectedKeys]="selectedRowKeysBBInActiveRecipe"
                                                        [navigable]="true">
                                                <ng-template kendoGridToolbarTemplate>
                                                    <div style="display: flex">
                                                        <button kendoGridExcelCommand icon="file-excel" [hidden]="isRecipeEmpty">Export to Excel</button>
                                                        <!-- <span [hidden]="selectedBBMessage" style="color:red; padding-left:5px;"> *BB Required <span style="color:red;" [ngbTooltip]="'At least one BB must be selected to Create HSD-ES/CC or Assign HSD-ES to CC'">(?)</span></span> -->
                                                    </div>
                                                </ng-template>
                                                <!-- Lindy added for checkbox selected BB from recipe-->
                                                <kendo-grid-checkbox-column field="isSelected" title="Selected" [width]="120">
                                                    <ng-template kendoGridCellTemplate let-dataItem>
                                                      <input type="checkbox" (click)="onCheckBoxBB_WQ(dataItem,idNumber)"
                                                             [checked]="dataItem.isSelected"
                                                             [ngbTooltip]="'Click on check box to select building block for recipe creation. The building block must NOT be archived, a draft, or a template.'"/>
                                                    </ng-template>
                                                  </kendo-grid-checkbox-column> 
                                                <kendo-grid-column field='labs' title='Sites' [width]="180" headerClass="headerStyle" class="rowStyle">
                                                    <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter" let-filterService="filterService">
                                                        <multicheck-filter [isPrimitive]="true" [field]="column.field" [filterService]="filterService" [currentFilter]="filter" [data]="distinctPrimitive(column.field, 'bbdetails')"></multicheck-filter>
                                                    </ng-template>
                                                </kendo-grid-column>
                                                <kendo-grid-column field='buildingBlockNumber' title='Building Block Number' [width]="250" headerClass="headerStyle" class="rowStyle">
                                                    <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter" let-filterService="filterService">
                                                        <multicheck-filter [isPrimitive]="true" [field]="column.field" [filterService]="filterService" [currentFilter]="filter" [data]="distinctPrimitive(column.field, 'bbdetails')"></multicheck-filter>
                                                    </ng-template>
                                                </kendo-grid-column>
                                                <kendo-grid-column field='buildingBlockName' title='Building Block Name' [width]="250" headerClass="headerStyle" class="rowStyle">
                                                    <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter" let-filterService="filterService">
                                                        <multicheck-filter [isPrimitive]="true" [field]="column.field" [filterService]="filterService" [currentFilter]="filter" [data]="distinctPrimitive(column.field, 'bbdetails')"></multicheck-filter>
                                                    </ng-template>
                                                </kendo-grid-column>
                                                <kendo-grid-column field='type' title='Type' [width]="160" headerClass="headerStyle" class="rowStyle">
                                                    <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter" let-filterService="filterService">
                                                        <multicheck-filter [isPrimitive]="true" [field]="column.field" [filterService]="filterService" [currentFilter]="filter" [data]="distinctPrimitive(column.field, 'bbdetails')"></multicheck-filter>
                                                    </ng-template>
                                                </kendo-grid-column>
                                                <kendo-grid-column field='validationTeams' title='Validation Teams' [width]="200" headerClass="headerStyle" class="rowStyle">
                                                    <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter" let-filterService="filterService">
                                                        <multicheck-filter [isPrimitive]="true" [field]="column.field" [filterService]="filterService" [currentFilter]="filter" [data]="distinctPrimitive(column.field, 'bbdetails')"></multicheck-filter>
                                                    </ng-template>
                                                </kendo-grid-column>
                                                <kendo-grid-column field='siliconProgram' title='Silicon Program' [width]="160" headerClass="headerStyle" class="rowStyle">
                                                    <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter" let-filterService="filterService">
                                                        <multicheck-filter [isPrimitive]="true" [field]="column.field" [filterService]="filterService" [currentFilter]="filter" [data]="distinctPrimitive(column.field, 'bbdetails')"></multicheck-filter>
                                                    </ng-template>
                                                </kendo-grid-column>
                                                <kendo-grid-column field='description' title='Description' [width]="250" headerClass="headerStyle" class="rowStyle">
                                                    <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter" let-filterService="filterService">
                                                        <multicheck-filter [isPrimitive]="true" [field]="column.field" [filterService]="filterService" [currentFilter]="filter" [data]="distinctPrimitive(column.field, 'bbdetails')"></multicheck-filter>
                                                    </ng-template>
                                                </kendo-grid-column>
                                                <kendo-grid-column field='products' title='Products' [width]="200" headerClass="headerStyle" class="rowStyle">
                                                    <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter" let-filterService="filterService">
                                                        <multicheck-filter [isPrimitive]="true" [field]="column.field" [filterService]="filterService" [currentFilter]="filter" [data]="distinctPrimitive(column.field, 'bbdetails')"></multicheck-filter>
                                                    </ng-template>
                                                </kendo-grid-column>
                                                <kendo-grid-column field='boardTypes' title='Board Types' [width]="300" headerClass="headerStyle" class="rowStyle">
                                                    <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter" let-filterService="filterService">
                                                        <multicheck-filter [isPrimitive]="true" [field]="column.field" [filterService]="filterService" [currentFilter]="filter" [data]="distinctPrimitive(column.field, 'bbdetails')"></multicheck-filter>
                                                    </ng-template>
                                                </kendo-grid-column>
                                                <kendo-grid-column field='rampUp' title='Category' [width]="220" headerClass="headerStyle" class="rowStyle">
                                                    <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter" let-filterService="filterService">
                                                        <multicheck-filter [isPrimitive]="true" [field]="column.field" [filterService]="filterService" [currentFilter]="filter" [data]="distinctPrimitive(column.field, 'bbdetails')"></multicheck-filter>
                                                    </ng-template>
                                                </kendo-grid-column>
                                                <kendo-grid-column field='module' title='Module' [width]="180" headerClass="headerStyle" class="rowStyle">
                                                    <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter" let-filterService="filterService">
                                                        <multicheck-filter [isPrimitive]="true" [field]="column.field" [filterService]="filterService" [currentFilter]="filter" [data]="distinctPrimitive(column.field, 'bbdetails')"></multicheck-filter>
                                                    </ng-template>
                                                </kendo-grid-column>
                                                <kendo-grid-column field='ownerName' title='Owner' [width]="210" headerClass="headerStyle" class="rowStyle">
                                                    <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter" let-filterService="filterService">
                                                        <multicheck-filter [isPrimitive]="true" [field]="column.field" [filterService]="filterService" [currentFilter]="filter" [data]="distinctPrimitive(column.field, 'bbdetails')"></multicheck-filter>
                                                    </ng-template>
                                                </kendo-grid-column>
                                                <kendo-grid-column field='coOwner' title='Co-owner' [width]="210" headerClass="headerStyle" class="rowStyle">
                                                    <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter" let-filterService="filterService">
                                                        <multicheck-filter [isPrimitive]="true" [field]="column.field" [filterService]="filterService" [currentFilter]="filter" [data]="distinctPrimitive(column.field, 'bbdetails')"></multicheck-filter>
                                                    </ng-template>
                                                </kendo-grid-column>
                                                <kendo-grid-column field='revision' title='Revision' [width]="150" headerClass="headerStyle" class="rowStyle">
                                                    <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter" let-filterService="filterService">
                                                        <multicheck-filter [isPrimitive]="true" [field]="column.field" [filterService]="filterService" [currentFilter]="filter" [data]="distinctPrimitive(column.field, 'bbdetails')"></multicheck-filter>
                                                    </ng-template>
                                                </kendo-grid-column>
                                                
                                                <kendo-grid-column field='createdDate' title='Created Date' [width]="150" headerClass="headerStyle" class="rowStyle">
                                                    <ng-template kendoGridCellTemplate let-dataItem>
                                                        {{ dataItem.updatedDate | date: 'MM/dd/yyyy hh:mm a'}}
                                                    </ng-template>
                                                </kendo-grid-column>
                                                <kendo-grid-column field='updatedByName' title='Updated By' [width]="210" headerClass="headerStyle" class="rowStyle">
                                                    <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter" let-filterService="filterService">
                                                        <multicheck-filter [isPrimitive]="true" [field]="column.field" [filterService]="filterService" [currentFilter]="filter" [data]="distinctPrimitive(column.field, 'bbdetails')"></multicheck-filter>
                                                    </ng-template>
                                                </kendo-grid-column>
                                                <kendo-grid-column field='updatedDate' title='Updated Date' type='date' [width]="180" headerClass="headerStyle" class="rowStyle">
                                                    <ng-template kendoGridCellTemplate let-dataItem>
                                                        {{ dataItem.updatedDate | date: 'MM/dd/yyyy hh:mm a'}}
                                                    </ng-template>
                                                </kendo-grid-column>
                                                <kendo-grid-column field='emailDistribution' title='Email Distribution' [width]="250" headerClass="headerStyle" class="rowStyle">
                                                    <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter" let-filterService="filterService">
                                                        <multicheck-filter [isPrimitive]="true" [field]="column.field" [filterService]="filterService" [currentFilter]="filter" [data]="distinctPrimitive(column.field, 'bbdetails')"></multicheck-filter>
                                                    </ng-template>
                                                </kendo-grid-column>
                                                <!-- Lindy Add hyperlink to BB file name-->
                                                 <!-- <kendo-grid-column field='content' title='File Name2' [width]="500" headerClass="headerStyle" class="rowStyle">
                                                    <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter" let-filterService="filterService">
                                                        <multicheck-filter [isPrimitive]="true" [field]="column.field" [filterService]="filterService" [currentFilter]="filter" [data]="distinctPrimitive(column.field, 'bbdetails')"></multicheck-filter>
                                                    </ng-template>
                                                </kendo-grid-column> -->
                                                <kendo-grid-column field="content" title="File Name" [width]="500" headerClass="headerStyle" class="rowStyle">
                                                    <ng-template kendoGridCellTemplate let-dataItem>
                                                        <a *ngIf="dataItem.content" [href]="fileUrl + encodeURLContent(dataItem.content)" target="_blank" style="color: #2113e4;">
                                                            {{ dataItem.content }}
                                                        </a>
                                                    </ng-template>
                                                </kendo-grid-column>
                                                 
                                                <kendo-grid-excel fileName="RecipeDetails.xlsx" [fetchData]="bbData">
                                                    <kendo-excelexport-column field="labs" title="Site(s)"></kendo-excelexport-column>
                                                    <kendo-excelexport-column field="buildingBlockNumber" title="Building Block Number"></kendo-excelexport-column>
                                                    <kendo-excelexport-column field="buildingBlockName" title="Building Block Name"></kendo-excelexport-column>
                                                    <kendo-excelexport-column field="type" title="Type"></kendo-excelexport-column>
                                                    <kendo-excelexport-column field="validationTeams" title="Validation Team(s)"></kendo-excelexport-column>
                                                    <kendo-excelexport-column field="siliconProgram" title="Silicon Program"></kendo-excelexport-column>
                                                    <kendo-excelexport-column field="description" title="Description"></kendo-excelexport-column>
                                                    <kendo-excelexport-column field="products" title="Product(s)"></kendo-excelexport-column>
                                                    <kendo-excelexport-column field="boardTypes" title="Board Type(s)"></kendo-excelexport-column>
                                                    <kendo-excelexport-column field="rampUp" title="Category"></kendo-excelexport-column>
                                                    <kendo-excelexport-column field="module" title="Module"></kendo-excelexport-column>
                                                    <kendo-excelexport-column field="ownerName" title="Owner Name"></kendo-excelexport-column>
                                                    <kendo-excelexport-column field="coOwner" title="Co-Owner(s)"></kendo-excelexport-column>
                                                    <kendo-excelexport-column field="createdDate" title="Created Date"></kendo-excelexport-column>
                                                    <kendo-excelexport-column field="updatedByName" title="Updated By"></kendo-excelexport-column>
                                                    <kendo-excelexport-column field="updatedDate" title="Updated Date"></kendo-excelexport-column>
                                                    <kendo-excelexport-column field="emailDistribution" title="Email Distribution"></kendo-excelexport-column>
                                                    <kendo-excelexport-column field="content" title="File Name"></kendo-excelexport-column>
                                                    <kendo-excelexport-column field="application" title="Application"></kendo-excelexport-column>
                                                </kendo-grid-excel>
                                            </kendo-grid>
                                        </div>
                                    </ng-template>
                                </kendo-tabstrip-tab>
                                <kendo-tabstrip-tab [title]="'Recipe Revision History'">
                                    <ng-template kendoTabContent>
                                        <!-- <div style="text-align: right; margin-bottom: 10px;">
                                            <button class="btn btn-outline-primary btn-sm" type="button" (click)="exportToExcel(recipeRevHistoryGrid)"  [hidden]="!searchRowFound">
                                                <i class="intelicon-ms-excel" aria-hidden="true"></i> Export to Excel
                                            </button>
                                        </div> -->
                                        <div>
                                        </div>
                                        <div>
                                            <kendo-label text="Revision History Information:" class="label"><br></kendo-label>
                                            <kendo-label text="No Recipe Revision History exists for this recipe." [hidden]="searchRowFound" class="label"></kendo-label>
                                            <kendo-grid #recipeRevHistoryGrid="kendoGrid" [data]="supportGridData" [hidden]="!searchRowFound" [style.height.%]="50" [height]="400"
                                                        [resizable]="true" filterable="menu" [rowClass]="rowCallback"
                                                        (dataStateChange)="dataStateChange($event,'reciperevisionhistory')"
                                                        [pageSize]="gridSettings.state.take"
                                                        [skip]="gridSettings.state.skip"
                                                        [sort]="gridSettings.state.sort"
                                                        [loading]="isRecipeRevHistoryGridLoading"
                                                        [filter]="gridSettings.state.filter"
                                                        [sortable]="true"
                                                        [pageable]="true"
                                                        [reorderable]="true"
                                                        [selectable]="selectableSettings"
                                                        (filterChange)="searchGridFilterChange($event,'reciperevisionhistory')"
                                                        (pageChange)="pageChange($event)"
                                                        kendoGridSelectBy
                                                        [navigable]="true">
                                                <ng-template kendoGridToolbarTemplate>
                                                    <button kendoGridExcelCommand icon="file-excel" [hidden]="!searchRowFound">Export to Excel</button>
                                                </ng-template>
                                                <kendo-grid-column field='labs' title='Sites' [width]="180" headerClass="headerStyle" class="rowStyle">
                                                    <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter" let-filterService="filterService">
                                                        <multicheck-filter [isPrimitive]="true" [field]="column.field" [filterService]="filterService" [currentFilter]="filter" [data]="distinctPrimitive(column.field)"></multicheck-filter>
                                                    </ng-template>
                                                </kendo-grid-column>
                                                <kendo-grid-column field='recipeNumber' title='Recipe Number' [width]="190" headerClass="headerStyle" class="rowStyle">
                                                    <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter" let-filterService="filterService">
                                                        <multicheck-filter [isPrimitive]="true" [field]="column.field" [filterService]="filterService" [currentFilter]="filter" [data]="distinctPrimitive(column.field)"></multicheck-filter>
                                                    </ng-template>
                                                </kendo-grid-column>
                                                <kendo-grid-column field='recipeName' title='Recipe Name' [width]="160" headerClass="headerStyle" class="rowStyle">
                                                    <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter" let-filterService="filterService">
                                                        <multicheck-filter [isPrimitive]="true" [field]="column.field" [filterService]="filterService" [currentFilter]="filter" [data]="distinctPrimitive(column.field)"></multicheck-filter>
                                                    </ng-template>
                                                </kendo-grid-column>
                                                <kendo-grid-column field='products' title='Products' [width]="150" headerClass="headerStyle" class="rowStyle">
                                                    <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter" let-filterService="filterService">
                                                        <multicheck-filter [isPrimitive]="true" [field]="column.field" [filterService]="filterService" [currentFilter]="filter" [data]="distinctPrimitive(column.field)"></multicheck-filter>
                                                    </ng-template>
                                                </kendo-grid-column>
                                                <kendo-grid-column field='siliconProgram' title='Silicon Program' [width]="180" headerClass="headerStyle" class="rowStyle">
                                                    <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter" let-filterService="filterService">
                                                        <multicheck-filter [isPrimitive]="true" [field]="column.field" [filterService]="filterService" [currentFilter]="filter" [data]="distinctPrimitive(column.field)"></multicheck-filter>
                                                    </ng-template>
                                                </kendo-grid-column>
                                                <kendo-grid-column field='rampup' title='Category' [width]="140" headerClass="headerStyle" class="rowStyle">
                                                    <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter" let-filterService="filterService">
                                                        <multicheck-filter [isPrimitive]="true" [field]="column.field" [filterService]="filterService" [currentFilter]="filter" [data]="distinctPrimitive(column.field)"></multicheck-filter>
                                                    </ng-template>
                                                </kendo-grid-column>
                                                <kendo-grid-column field='owner' title='Owner' [width]="150" headerClass="headerStyle" class="rowStyle">
                                                    <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter" let-filterService="filterService">
                                                        <multicheck-filter [isPrimitive]="true" [field]="column.field" [filterService]="filterService" [currentFilter]="filter" [data]="distinctPrimitive(column.field)"></multicheck-filter>
                                                    </ng-template>
                                                </kendo-grid-column>
                                                <kendo-grid-column field='coOwner' title='Co-owner' [width]="150" headerClass="headerStyle" class="rowStyle">
                                                    <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter" let-filterService="filterService">
                                                        <multicheck-filter [isPrimitive]="true" [field]="column.field" [filterService]="filterService" [currentFilter]="filter" [data]="distinctPrimitive(column.field)"></multicheck-filter>
                                                    </ng-template>
                                                </kendo-grid-column>
                                                <kendo-grid-column field='createdDate' title='Created Date' [width]="150" headerClass="headerStyle" class="rowStyle">
                                                    <ng-template kendoGridCellTemplate let-dataItem>
                                                        {{ dataItem.updatedDate | date: 'MM/dd/yyyy hh:mm a'}}
                                                    </ng-template>
                                                </kendo-grid-column>
                                                <kendo-grid-column field='updatedByName' title='Updated By' [width]="150" headerClass="headerStyle" class="rowStyle">
                                                    <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter" let-filterService="filterService">
                                                        <multicheck-filter [isPrimitive]="true" [field]="column.field" [filterService]="filterService" [currentFilter]="filter" [data]="distinctPrimitive(column.field)"></multicheck-filter>
                                                    </ng-template>
                                                </kendo-grid-column>
                                                <kendo-grid-column field='updatedDate' title='Updated Date' type='date' [width]="180" headerClass="headerStyle" class="rowStyle">
                                                    <ng-template kendoGridCellTemplate let-dataItem>
                                                        {{ dataItem.updatedDate | date: 'MM/dd/yyyy hh:mm a'}}
                                                    </ng-template>
                                                </kendo-grid-column>
                                                <kendo-grid-column field='recipeComments' title='Comments' [width]="200" headerClass="headerStyle" class="rowStyle">
                                                    <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter" let-filterService="filterService">
                                                        <multicheck-filter [isPrimitive]="true" [field]="column.field" [filterService]="filterService" [currentFilter]="filter" [data]="distinctPrimitive(column.field)"></multicheck-filter>
                                                    </ng-template>
                                                </kendo-grid-column>
                                                <kendo-grid-column field='emailDistribution' title='Email Distribution' [width]="200" headerClass="headerStyle" class="rowStyle">
                                                    <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter" let-filterService="filterService">
                                                        <multicheck-filter [isPrimitive]="true" [field]="column.field" [filterService]="filterService" [currentFilter]="filter" [data]="distinctPrimitive(column.field)"></multicheck-filter>
                                                    </ng-template>
                                                </kendo-grid-column>
                                                <kendo-grid-excel fileName="RecipeRevHistory.xlsx" [fetchData]="revHistoryData"></kendo-grid-excel>
                                            </kendo-grid>
                                            <div style="text-align: right; margin-bottom: 10px;" [hidden]="!isBB">
                                                <button kendoButton class="btn btn-outline-primary btn-sm" type="button" (click)="exportToExcel(bbGrid)" [disabled]="isEmpty">
                                                    <i class="intelicon-ms-excel" aria-hidden="true"></i> Export to Excel
                                                </button>
                                            </div>
                                        </div>
                                    </ng-template>
                                </kendo-tabstrip-tab>
                            </kendo-tabstrip>
                            <br/>
                            <h4 *ngIf="!isPdfOptionDisabled"> Combined PDF Document for Active {{createMode}}: </h4>
                            <br/>
                            <a *ngIf="!isPdfOptionDisabled" [href]="recipeDoc.url" target="_new"> Open PDF (new tab) </a>

                            <!-- <button (click)="downloadPDF(recipeDoc.url)" class="btn btn-primary btn-sm button" type="button" style="margin-left:20px; margin-bottom: 10px;">Download PDF</button> -->
                            <kendo-panelbar *ngIf="!isPdfOptionDisabled">
                                <kendo-panelbar-item title="Building Blocks" [expanded]="false">
                                    <ng-template kendoPanelBarItemTitle>
                                        {{combinedPDFDocument}}
                                    </ng-template>
                                    <ng-template kendoPanelBarContent>
                                        <div>
                                            <div [style.display]="isPdfOptionDisabled ? 'none':'block'">
                                                <span *ngIf="isCombinedPdfLoading">
                                                    <kendo-loader type="converging-spinner" size="medium"> </kendo-loader>
                                                </span>
                                                <pdf-viewer style="width: 100%; height: 800px" [src]="{url: recipeDocBinary}" [render-text]="true" [original-size]="false"
                                                (after-load-complete)="checkListContentLoading[recipeId].loaded=false" (on-progress)="onloadingPdfProgress($event, recipeId)" >
                                                </pdf-viewer>
                                            </div>
                                        </div>
                                    </ng-template>
                                </kendo-panelbar-item>
                            </kendo-panelbar>
                        </div>
                    </div>
                </kendo-splitter-pane>
            </kendo-splitter>
        </kendo-splitter-pane>
    </kendo-splitter>
    </form>
    <!-- <kendo-window [minHeight]="500" title="Lab Manager" *ngIf="isWindowRecipeHSDCreate" (close)="closePopupWindow('createcc')" class = "lmPopUp">  -->
        <kendo-window [minHeight]="500" title="Lab Manager" *ngIf="isWindowLMPopUp" (close)="closeLMPopUp()" class = "lmPopUp"> 
        <div>
            <div style="padding-left: 1.5px; font-size:bold; color: red;">Note: All fields are optional</div>
            <div class="m-1">
                <div class="lmPopUpRow">
                    
                      <kendo-formfield class="col-6 fieldFormat" orientation="horizontal">
                        <kendo-label text="Site"> </kendo-label>
                        <kendo-dropdownlist class = "dropdownlist_lm" [data]="lmSiteList" [value]="selectedLMSite" (valueChange)="ddlSiteValueChange($event)"></kendo-dropdownlist>
                      </kendo-formfield>
                      <kendo-formfield class="col-6 fieldFormat" orientation="horizontal">
                        <kendo-label text="Host Name"> </kendo-label>
                        <div style = "display:flex">
                            <kendo-textbox class = "textbox-lm" #txtHostName placeholder="Enter HostName..." [(value)]="lmHostName" (valueChange)="onHostNameChange($event)"></kendo-textbox>
                            <button type="button" class="k-button k-primary" (click)="getLMHostDetails(txtHostName.value)" style = "margin-left:10px; border-radius:2px; ">Retrieve LM Data</button>
                        </div>  
                        </kendo-formfield>
                      <kendo-formfield class="col-6" orientation="horizontal">
                        <kendo-label text="Lab"> </kendo-label>
                        <kendo-dropdownlist class = "dropdownlist_lm" [data]="lmLabList" [value]="lmLab" (valueChange)="ddlLabValueChange($event)"></kendo-dropdownlist>
                      </kendo-formfield>
                      <kendo-formfield class="col-6" orientation="horizontal">
                        <kendo-label text="Group"> </kendo-label>
                        <kendo-textbox class = "textbox-lm" #txtGroup [value]="lmGroup" [disabled]="true" (valueChange)="onGroupChange($event)"></kendo-textbox>
                      </kendo-formfield>
                      <kendo-formfield class="col-6" orientation="horizontal">
                        <kendo-label text="Projects"> </kendo-label>
                        <kendo-dropdownlist class = "dropdownlist_lm" [data]="lmProjectList" textField="name" valueField="projectID" [(value)]="lmProject" (valueChange)="ddlProjectValueChange($event)"></kendo-dropdownlist>
                      </kendo-formfield>
                      <kendo-formfield class="col-6" orientation="horizontal">
                        <kendo-label text="Owner"> </kendo-label>
                        <kendo-dropdownlist class = "dropdownlist_lm" [data]="lmOwnerList" [value]="lmOwner" (valueChange)="ddlOwnerValueChange($event)"></kendo-dropdownlist>                        
                      </kendo-formfield>
                      <kendo-formfield class="col-6" orientation="horizontal">
                          <kendo-label text="Activity"> </kendo-label>
                          <kendo-dropdownlist class = "dropdownlist_lm" [data]="lmActivitiesList" [value]="lmActivity" (valueChange)="ddlActivityValueChange($event)"></kendo-dropdownlist>
                      </kendo-formfield>
                      <kendo-formfield class="col-6" orientation="horizontal">
                        <kendo-label text="Active State"> </kendo-label>
                        <kendo-dropdownlist class = "dropdownlist_lm" [data]="lmActiveStates" [value]="lmActiveState" (valueChange)="ddlActiveStateValueChange($event)"></kendo-dropdownlist>
                      </kendo-formfield>
                      <kendo-formfield class="col-6" orientation="horizontal">
                        <kendo-label text="Station.VT"> </kendo-label>
                        <kendo-dropdownlist class = "dropdownlist_lm" [data]="lmVTNamesList" [value]="lmVTName" (valueChange)="ddlVTeamValueChange($event)"></kendo-dropdownlist>
                      </kendo-formfield>
                      <kendo-formfield class="col-6" orientation="horizontal">
                        <kendo-label text="Focus"> </kendo-label>
                        <kendo-textbox class = "textbox-lm" #txtFocus [value]="lmFocus" (valueChange)="onFocusChange($event)"></kendo-textbox>
                      </kendo-formfield>
                      <kendo-formfield class="col-6" orientation="horizontal">
                        <kendo-label text="Board SN"> </kendo-label>
                        <kendo-textbox class = "textbox-lm" #txtBoardSN [value]="lmBoardSN" (valueChange)="onBoardSNChange($event)"></kendo-textbox>
                      </kendo-formfield>
                      <kendo-formfield class="col-6" orientation="horizontal">
                        <kendo-label text="Location"> </kendo-label>
                        <kendo-textbox class = "textbox-lm" #txtLocation [value]="lmLocation" (valueChange)="onLocationChange($event)"></kendo-textbox>
                      </kendo-formfield>
                      <!-- <kendo-formfield class="col-12" orientation="horizontal">
                        <kendo-label text="Description"> </kendo-label>
                        <kendo-textarea style = "min-width: 100% !important; min-height:100px !important;" [value]="lmDescription"></kendo-textarea>
                      </kendo-formfield> -->
                      <kendo-formfield class="col-6" orientation="horizontal">
                        <kendo-label text="Description"> </kendo-label>
                        <!-- <kendo-textarea style = "min-width: 100% !important;" [value]="lmDescription" ></kendo-textarea> -->
                        <kendo-textbox class = "textbox-lm" #txtDescription [value]="lmDescription" (valueChange)="onDescChange($event)"></kendo-textbox>
                      </kendo-formfield>
                    <kendo-formfield class="col-6" orientation="horizontal">
                        <!-- <div  style = "display:flex; padding-top: 10px;">   -->
                        <div [style.display]="isButtonAssignHSDESToCC ? 'none':'block'">
                            <div style="display:flex; padding-top: 10px;">
                            <kendo-label text="Bulk Ticket" [for]="bulkticket"> </kendo-label>
                            <strong style="padding-left:10px;">
                                <input type="checkbox" #bulkticket kendoCheckBox [(ngModel)]="boolBulkTicket" />
                            </strong>
                        </div>
                    </div>
                    </kendo-formfield>
                      
                    </div>
              </div>           
            <div style = "padding-left:55%;">
                <button type="button" class="k-button k-primary" (click)="SaveAndContinue()">Save and Continue</button>
                <!-- <button type="button" class="k-button" (click)="closePopupWindow('createcc')" primary="true" style = "margin-left:10px;">Close</button> -->
                <button type="button" class="k-button k-primary" (click)="closeLMPopUp()" primary="true" style = "margin-left:10px;">Close</button>
                <button type="button" class="k-button k-primary" (click)="btnResetValueLM()" style = "margin-left:10px;">Reset</button>
            </div>
        
        </div>
    </kendo-window>

    
   
</div>
