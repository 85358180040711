import { HttpErrorResponse, HttpClient } from '@angular/common/http';
import { RM_NotificationService } from './../../common/notificationService';
import { Module } from 'webpack';
import { DateTimePickerComponent } from '@progress/kendo-angular-dateinputs';
import { BuildingBlockModule } from 'app/modules/shared/model/building-block-module';
import { BuildingBlockType } from 'app/modules/shared/model/building-block-type';
import { SearchGateway } from 'app/gateways/search.gateway';
import { Recipe, SelectedBuildingBlock, ParentInfo } from './../shared/model/recipe';
import { BuildingBlock, CheckListItem } from './../shared/model/building-block';
import { AfterViewInit, Component, OnChanges, OnInit, ViewChild } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { BadgeAlign } from '@progress/kendo-angular-indicators';
import { PanelBarSelectEvent, PanelBarCollapseEvent, PanelBarExpandEvent } from '@progress/kendo-angular-layout';
import { FileGateway } from 'app/gateways/file.gateway';
import { GraphUsersGateway } from 'app/gateways/graph-users.gateway';
import { CreateGateway } from 'app/gateways/create.gateway';
import { Subscription, lastValueFrom, takeUntil, Subject } from 'rxjs';
import { appSettings } from '../shared/app-settings';
import { BoardType, BoardTypeInfo } from '../shared/model/board-type';
import { GraphUsers } from '../shared/model/graph-users';
import { Lab, LabInfo } from '../shared/model/lab';
import { ValidationTeam, ValidationTeamInfo } from '../shared/model/validation-team';
import { AddEvent, CancelEvent, CreateFormGroupArgs, DataStateChangeEvent, EditEvent, GridComponent, GridDataResult, GroupKey, RemoveEvent, RowClassArgs, SaveEvent } from '@progress/kendo-angular-grid';
import { range, filter, map } from 'rxjs';
import { ActionName, ListBoxComponent, ListBoxToolbarConfig } from '@progress/kendo-angular-listbox';
import { FileRestrictions, SelectEvent, UploadComponent, UploadEvent } from '@progress/kendo-angular-upload';
import { Exception } from '@microsoft/applicationinsights-web';
import { Person } from '../shared/model/person';
import { dateFieldName } from '@progress/kendo-intl';
import { WindowService } from '@progress/kendo-angular-dialog';
import { toggleFullScreenModeIcon } from '@progress/kendo-svg-icons';
import { isThisTypeNode, UnaryExpression } from 'typescript';
import { ListBoxSelectionEvent } from '@progress/kendo-angular-listbox/selection.service';
import { time, timeStamp } from 'console';
import { ThrottlingUtils } from '@azure/msal-common';
import { ItemArgs } from '@progress/kendo-angular-dropdowns';
import { Product } from '../shared/model/product';
import { ChipRemoveEvent } from '@progress/kendo-angular-buttons';
import { CompositeFilterDescriptor, filterBy, groupBy, GroupDescriptor } from '@progress/kendo-data-query';
import { Config } from '../shared/model/config';
import { EventEmitterService } from 'app/common/event-emitter-service';
import { request } from 'http';
import { ThirdPartyAppGateway } from 'app/gateways/third-party-app.gateway';
import { guid } from '@progress/kendo-angular-common';
import { UserService } from 'app/common/user-service';

interface Item {
  text: string;
  value: string;
  disabled: boolean;
}

@Component({
  selector: 'app-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.scss'],
})
export class CreateComponent implements OnInit, AfterViewInit {

  @ViewChild('selectedRecipeModulesSortable') public selectedRecipeModulesSortable: any;
  @ViewChild('freeTextMode') public freeTextMode: any;
  @ViewChild('programsAutoComplete') public programsAutoComplete: any;
  @ViewChild('addWhere') public addWhere: any;

  public destroy$: Subject<boolean> = new Subject<boolean>();
  private rmAppName: string = 'recipe-management';

  public listItems: Array<Item> = [
    { text: 'At Top', value: 'top', disabled: false  },
    { text: 'Before Selection', value: 'before', disabled: true  },
    { text: 'After Selection', value: 'after', disabled: true  },
    { text: 'At Bottom', value: 'bottom', disabled: false  }
  ];

  public dragItem: any;
  public selectedCheckListRow: any;
  public checklistGridLoading = false; // WH 24Aug2023 - add loader

  public boardPBAIdSearchTextBoxValue: string = '';
  public boardPBAIdErrorMessage: string = 'init';
  public boardPBAIdHasError: boolean = false;
  public isBoardTypeSearchButtonDisabled: boolean = false;
  public boardTypeOpened = false;
  public speedBoardTypeNameSelected = '';

  public productsList: any[] = [];
  public programsAutoCompleteIsLoading: boolean = false;
  public programNamesList: any[] = [];
  public programsList: any[] = [];
  public programsKeyword: string = '';
  public programsOpened = false;
  public speedProductSelected: any = {};

  public productKeyword: string = '';
  public productOpened = false;
  public productAutoCompleteIsLoading: boolean = false;

  public updateRecipes = true;
  public badgeAlign: BadgeAlign = { vertical: 'bottom', horizontal: 'end' };
  public badgeAlignLink: BadgeAlign = { vertical: 'bottom', horizontal: 'end' };
  public panelBadgeAlign: BadgeAlign = { vertical: 'bottom', horizontal: 'start' };

  private observer: IntersectionObserver | undefined;
  recipeModuleExpanded: any = {};

  public submitRequestForm = new UntypedFormGroup({
    owners: new UntypedFormGroup({
      owner: new UntypedFormControl(null, Validators.required),
      coOwner: new UntypedFormControl(null, Validators.required), // new  UntypedFormArray([]),
    }),
    labManager: new UntypedFormGroup({
      column1: new UntypedFormControl(null),
      column2: new UntypedFormControl(null),
    }),
    basicFeatures: new UntypedFormGroup({
      rampUp: new UntypedFormControl(null, Validators.required),
      labs: new UntypedFormControl(null, Validators.required),
      validationTeams: new UntypedFormControl(null, Validators.required),
      boardTypes: new UntypedFormControl(null, Validators.required),
      module: new UntypedFormControl(null),
      siliconProgram: new UntypedFormControl(null, Validators.required),
      products: new UntypedFormControl(null, Validators.required),
      buildingBlockName: new UntypedFormControl(null),
      recipeName: new UntypedFormControl(null),
      type: new UntypedFormControl(null),
      description: new UntypedFormControl(null),
      emailDistribution: new UntypedFormControl(null),
    }),
    blockManager: new UntypedFormGroup({
      content: new UntypedFormControl(null),
    }),
    CheckList: new UntypedFormGroup({
      checkList: new UntypedFormArray([])
    }),
    orderBuildingBlocks: new UntypedFormGroup({
      modules: new UntypedFormArray([]),
      buildingBlocks: new UntypedFormArray([])
    })
  });

  public group = [];
  public expandedGroupKeys: GroupKey[] = [];
  public initiallyExpanded = false;
  public selectedRecipeModules: string[] = [];
  public preSelectedBuildingBlocks: any = {};
  public selectedRecipeBuildingBlocks: any = {};

  public checkListTypes: Array<string> = ['Checklist Item', 'Header']
  public updates: any = {};
  public moduleFilter = '';
  public siliconProgramsFilter = '';
  public typeFilter = '';
  public content = '';
  public base64: any = { url: '' };
  public recipeDoc: any = { url: '' };
  public recipeDocBinary: any = {};
  url = '';
  isPdfLoading = false;
  fileList: any[] = [];
  docxFileList: any[] = [];
  selectedFile: any;
  showUploadCSV = false;
  public currentUser: GraphUsers | null;
  public selectedOwner: GraphUsers | null;
  public ownerData: GraphUsers[] = [];
  public selectedCoowner: GraphUsers | null;
  public coownerData: GraphUsers[] = [];

  public emailDistributionData: GraphUsers[] = [];
  public selectedEmailDist: GraphUsers | null;

  public rmLabList: any[] = [];
  public rmValidationTeams: any[] = [];
  public rmBoardTypes: any[] = [];
  public rmModules: any[] = [];
  public rmSiliconPrograms: string[] = [];
  public rmProducts: any[] = [];
  public rmTypes: any[] = [];

  public bbName = '';
  public loading: any = {};

  public emailDistribution: string[] = [];
  public selectedDistListData: string[] = [];
  private filterSubscription: Subscription | undefined;
  public uploadSaveUrl = '';
  public uploadCSVUrl = '';
  public uploadMode = false;

  public newChecklistItem = '';
  public newEmailItem = '';

  bbRestrictions: FileRestrictions = {
    allowedExtensions: ['.pdf', '.docx'],
  };

  checklistRestrictions: FileRestrictions = {
    allowedExtensions: ['.csv'],
  };
  createMode = 'recipe';
  public bbSteps = {
    'owners': { label: 'Owners', isValid: false, selected: true },
    'basicFeatures': { label: 'Basic Features', isValid: false, selected: false },
    'blockManager': { label: 'Block Management', isValid: false, selected: false },
    'CheckList': { label: 'CheckList', isValid: false, selected: false }
  };

  public recipeSteps = {
    'owners': { label: 'Owner', isValid: false, selected: true },
    'labManager': { label: 'Lab Manager', isValid: true, selected: false },
    'basicFeatures': { label: 'Basic Features', isValid: false, selected: false },
    'orderBuildingBlocks': { label: 'Order Building Blocks', isValid: false, selected: false },
    'documentPreview': { label: 'Document Preview', isValid: true, selected: false }
  };

  formKeys: string[] = [];
  currentStep = 'owners';
  formSteps: any = [];

  public loadedItem: any = {};
  itemId: string | null = '';
  createdByWwid: string | null = '';
  currentAction: string | null = '';

  coOwnerSelectionIndex = -1;
  draftButtonText = '';
  saveButtonText = '';

  public toolbarSettings: ListBoxToolbarConfig = {
    position: 'left',
    tools: ['moveUp', 'moveDown', 'remove'],
  };

  public toolbarSettingsEmail: ListBoxToolbarConfig = {
    position: 'left',
    tools: ['remove'],
  };
  public toolbarSettingsActiveModules: ListBoxToolbarConfig = {
    position: 'left',
    tools: ['moveUp', 'moveDown', 'transferFrom', 'transferTo', 'transferAllTo', 'transferAllFrom']
  };
  emailValid = false;
  selectedDocxFile!: string;
  checkListEditedRowIndex: number | undefined;
  coOwners: GraphUsers[] = [];

  public checkListFormGroup = new UntypedFormGroup({});
  coOwnersChanged = false;
  masterProductsList: Product[] = [];
  baseHeaderVisible: boolean = true;
  rmActiveModules: any[] = [];
  selectedActiveModule = '';
  allBuildingBlocks: any[] = [];
  enteredModule: string = '';
  currentModule: string = '';
  pressed = false;
  dragged = false;
  docList: any[] = [];
  bbNumbers: any[] = []; // WH 25July2023 - pass bbNumbers as doc separator
  checklistList: any[] = [];
  expandedModulePanel: any = {};
  isCombinedPdfLoading = false;

  currentUserConfiguration: Config | undefined;
  readOnly = true;
  currentRole = '';
  currentRoles: any = {};
  itemOwner = false;
  preloads: any;
  revision: number = -1;
  viewOnly = false;
  containsBBTemplate = false;
  itemLoaded = false;
  uploadHeaders: any;
  httpErrorHandler: any;
  disableSaveButtons = false;
  freeTextModeTempValue = false;
  checkListEditMode: boolean[] = [];
  addWhereValue = this.listItems[3].text;
  freeTextModeFromStorage = false;
  // WH - add kendo dialog alert for future use
  public alertDialogOpen = false;
  public alertDialogTitle: string = "";
  public alertDialogMessage: string = "";
  // WH - checklist
  public inEdit = false;
  public toRefresh = false;
  public recipeBbGridState: any;
  // WH - upload doc
  selectedDoc: File | null = null;
  fileTypeError: boolean = false;


  get checkListItems(): UntypedFormArray {
    return (this.submitRequestForm.controls['CheckList'] as UntypedFormGroup).controls['checkList'] as UntypedFormArray;
  }

  //Lindy - export checklist
  getCheckList: any[] = [];

  constructor(public graphUsersGateway: GraphUsersGateway, public createGateway: CreateGateway,
    private thirdPartyAppGateway: ThirdPartyAppGateway,
    public searchGateway: SearchGateway, public formBuilder: UntypedFormBuilder,
    private eventEmitterService: EventEmitterService,
    public notificationService: RM_NotificationService,
    private route: ActivatedRoute,
    private router: Router,
    public fileGateway: FileGateway,
    public userService: UserService,
    private http: HttpClient ) {

    const rawAction = route.snapshot.pathFromRoot[1].url[1].path;
    this.currentAction = rawAction.charAt(0).toUpperCase() + rawAction.slice(1).toLowerCase();;

    this.selectedCoowner = new GraphUsers();
    this.selectedOwner = new GraphUsers();
    this.currentUser = new GraphUsers();
    this.selectedEmailDist = new GraphUsers();

    this.uploadSaveUrl = fileGateway.fileUrl;
    this.uploadCSVUrl = fileGateway.fileUrl + '/uploadchecklist';
    this.preloads = route.snapshot.data['preloads'];

  }

  refreshReadOnly() {
    this.currentRoles['Administrator'] = appSettings.isUserAMemberOf("RM_Admins");

    this.currentRoles['L2 Technician'] = appSettings.isUserAMemberOf("RM_L2_Technicians"); // L2 Owners/CoOwners

    this.currentRoles['L1 Technician'] = appSettings.isUserAMemberOf("RM_L1_Technicians"); // Readonly

    this.currentRoles['Lab Customer'] = appSettings.isUserAMemberOf("RM_Lab_Customers"); // ReadOnly

    this.currentRoles['No Role'] =
      !this.currentRoles['Administrator'] && !this.currentRoles['L1 Technician'] &&
      !this.currentRoles['L2 Technician'] && !this.currentRoles['Lab Customer'];

    this.readOnly = true;
    // if (this.currentRoles['Administrator'] || (this.currentRoles['L2 Technician'] && this.itemOwner)) {
    /*if (this.currentRoles['Administrator'] || (this.currentRoles['L2 Technician'] && this.currentAction === 'Create') || (this.currentRoles['L2 Technician'] && this.currentAction === 'Copy')) {
      this.readOnly = false;
    }
    if (this.currentRoles['Administrator'] || (this.currentRoles['L2 Technician'] && this.itemOwner && this.currentAction === 'Edit')) {
      this.readOnly = false;
    }*/

    if (this.currentRoles['Administrator'] || this.currentRoles['L2 Technician']){
      if(this.currentAction === 'Create' || this.currentAction === 'Copy'){
        this.readOnly = false;
      }
      else if(this.currentAction === 'Edit' && this.itemOwner === true){
        this.readOnly = false;
      }
    }

    this.currentRole = Object.keys(this.currentRoles).filter(key => this.currentRoles[key] === true).join(', ');

    if (this.viewOnly) {
      this.readOnly = true;
    }

    if (this.loadedItem && !this.readOnly) {
      if (Object.keys(this.loadedItem).length > 0) {
        this.readOnly = this.loadedItem['isArchived'];
      }
    }
  }

  // WH keep - run HSD API
  // getUserIdsid() {
  //   // let idsid = 'wtham'
  //   // this.userService.getUserDetails({ idsid, expand: 'personal' }).subscribe(res => {
  //   //   if (res.data) {
  //   //     console.log(res);
  //   //   }
  //   // }, error => {
  //   //   console.log(error);
  //   // })

  //   this.userService.getUserByEmailId('ter.jie.ng@intel.com').subscribe(res => {
  //     if (res.data) {
  //       console.log(res);
  //     }
  //   }, error => {
  //     console.log(error);
  //   })
  // }

  ngOnInit() {
    //   sessionStorage.setItem('appSettings.appUserFullname', 'Rodriguez, Jesus');
    if (sessionStorage.getItem('appSettings.appUserEmailId')) {
      this.eventEmitterService.refreshUserInformationVar = this.eventEmitterService.refreshUserInformationFunction.subscribe((name: string) => {
        this.refreshReadOnly();
      });

      this.loading['rmItem'] = false;
      const userEmail = sessionStorage.getItem('appSettings.appUserEmailId');
      if (userEmail) {
        this.filterSubscription = this.graphUsersGateway.getUsers(userEmail, 10, '', true)
          .subscribe(userData => {
            if (userData.length === 1) {
              // this.uploadHeaders = this.fileGateway.getHeaders().subscribe(headers => {
              //   this.uploadHeaders = headers;
              // });
              this.currentUser = userData[0];
              const path = this.route.pathFromRoot;
              this.route.paramMap.subscribe(params => {
                this.baseHeaderVisible = true;
                if (params.get('mode')) {
                  this.submitRequestForm.reset();
                  this.refreshReadOnly();
                  const incomingMode = params.get('mode');
                  if (incomingMode) {
                    this.createMode = incomingMode.toLowerCase();
                  }

                  switch (this.createMode) {
                    case 'bb-template':
                    case 'bb': {
                      this.clearBB2RecipeCache();
                      //Author: Rajesh
                      //Date:   4 July 2023
                      //Purpose: Clear cache when loading create BB page
                      if(this.currentAction?.toLowerCase() == 'create'){
                          // WH 14Aug2023 - fix bb <-> bb template create form contains checklist entry cache
                          let checkListFa = ((this.submitRequestForm.controls['CheckList']) as UntypedFormGroup).controls['checkList'] as UntypedFormArray;
                          while (checkListFa.length !== 0) {
                            checkListFa.removeAt(0);
                          }
                          checkListFa.clear();
                          this.loadedItem.checkList = [];
                          this.checkListEditMode = [];
                          this.checkListItems.clear();
                          this.checklistList = [];
                        localStorage.removeItem(this.createMode + '_request_form');
                      }

                      //===================== End =========================
                      this.createCheckListFormGroup = this.createCheckListFormGroup.bind(this);
                      (this.submitRequestForm.controls['basicFeatures'] as UntypedFormGroup).controls['buildingBlockName'].addValidators(Validators.required);
                      (this.submitRequestForm.controls['basicFeatures'] as UntypedFormGroup).controls['module'].addValidators(Validators.required);
                      (this.submitRequestForm.controls['basicFeatures'] as UntypedFormGroup).controls['type'].addValidators(Validators.required);

                      (this.submitRequestForm.controls['basicFeatures'] as UntypedFormGroup).controls['recipeName'].clearValidators();
                      (this.submitRequestForm.controls['orderBuildingBlocks'] as UntypedFormGroup).controls['modules'].clearValidators();
                      (this.submitRequestForm.controls['orderBuildingBlocks'] as UntypedFormGroup).controls['buildingBlocks'].clearValidators();

                      this.route.queryParamMap
                        .subscribe(async (querys) => {
                          if (params.get('id')) {
                            this.itemId = params.get('id');
                          }
                          if (this.itemId) {
                            this.loading['rmItem'] = true;

                            if (querys.get('revision')) {
                              if (!isNaN(Number(querys.get('revision')))) {
                                this.revision = Number(querys.get('revision'));
                                this.searchGateway.getBuildingBlockRevHistory(this.itemId).subscribe(bbhResults => {
                                  let buildingBlockResult: any = bbhResults.filter((r) => (r.revision === this.revision))[0];
                                  if (!buildingBlockResult || Object.keys(buildingBlockResult).length === 0) {
                                    this.searchGateway.getBuildingBlocks({ buildingBlockId: this.itemId }).subscribe((currentBuildingBlocksResult: any) => {
                                      if (currentBuildingBlocksResult && Object.keys(currentBuildingBlocksResult).length > 0) {
                                        const currentBuildingBlockResult = currentBuildingBlocksResult[0];
                                        currentBuildingBlockResult['createdBy'] = {
                                          name: currentBuildingBlockResult.createdByName,
                                          email: currentBuildingBlockResult.createdByEmail,
                                          wwid: currentBuildingBlockResult.createdByWwid
                                        }
                                        currentBuildingBlockResult['updatedBy'] = {
                                          name: currentBuildingBlockResult.updatedByName,
                                          email: currentBuildingBlockResult.updatedByEmail,
                                          wwid: currentBuildingBlockResult.updatedByWwid
                                        }
                                        currentBuildingBlockResult['owner'] = {
                                          name: currentBuildingBlockResult.ownerName,
                                          email: currentBuildingBlockResult.ownerEmail,
                                          wwid: currentBuildingBlockResult.ownerWwid
                                        }
                                        this.updateLists(currentBuildingBlockResult, this.preloads);
                                        this.loadedItem = currentBuildingBlockResult;
                                        this.patchRequestForm(currentBuildingBlockResult);
                                        this.itemOwner = this.isOwner(currentBuildingBlockResult);
                                        this.viewOnly = true;
                                        this.refreshReadOnly();
                                        this.checkValidation();
                                        this.notificationService.addInfoNotification('BB Load Successful', `Building Block ${currentBuildingBlockResult.buildingBlockNumber} loaded successfully.`);
                                      }
                                    });
                                  } else {
                                    buildingBlockResult['createdBy'] = {
                                      name: buildingBlockResult.createdByName,
                                      email: buildingBlockResult.createdByEmail,
                                      wwid: buildingBlockResult.createdByWwid
                                    }
                                    buildingBlockResult['updatedBy'] = {
                                      name: buildingBlockResult.updatedByName,
                                      email: buildingBlockResult.updatedByEmail,
                                      wwid: buildingBlockResult.updatedByWwid
                                    }
                                    buildingBlockResult['owner'] = {
                                      name: buildingBlockResult.ownerName,
                                      email: buildingBlockResult.ownerEmail,
                                      wwid: buildingBlockResult.ownerWwid
                                    }
                                    buildingBlockResult.id = buildingBlockResult.buildingBlockId;
                                    this.updateLists(buildingBlockResult, this.preloads);
                                    this.loadedItem = buildingBlockResult;
                                    this.patchRequestForm(buildingBlockResult);
                                    this.itemOwner = this.isOwner(buildingBlockResult);
                                    this.viewOnly = true;
                                    this.refreshReadOnly();
                                    this.checkValidation();
                                    this.notificationService.addInfoNotification('BB Load Successful', `Building Block ${buildingBlockResult.buildingBlockNumber} loaded successfully.`);
                                  }
                                  this.loading['rmItem'] = false;
                                });
                              } else {
                                this.notificationService.addErrorNotification('Building Block does not exist', 'id: ' + this.itemId);
                              }
                            } else {
                              this.createdByWwid = querys.get('createdByWwid');
                              if (this.createdByWwid) {
                                this.createGateway.getBuildingBlock(this.itemId, this.createdByWwid).subscribe((buildingBlockResult) => {
                                  if (buildingBlockResult) {
                                    this.itemOwner = this.isOwner(buildingBlockResult);
                                    switch (this.currentAction?.toLowerCase()) {
                                      case 'copy': {
                                        this.currentAction = 'Create';
                                        if (this.itemId) {
                                          buildingBlockResult.parentBuildingBlock = { id: this.itemId, revision: buildingBlockResult.revision };
                                        }
                                        break;
                                      }
                                      case 'edit': {
                                        break;
                                      }
                                    }
                                    this.updateLists(buildingBlockResult, this.preloads);
                                    this.loadedItem = buildingBlockResult;
                                    this.patchRequestForm(buildingBlockResult);
                                    this.viewOnly = false;
                                    this.checkValidation();
                                    this.notificationService.addInfoNotification('BB Load Successful', `Building Block ${buildingBlockResult.buildingBlockNumber} loaded successfully.`);
                                    this.loading['rmItem'] = false;
                                  }
                                });
                              }
                            }
                          } else {
                            this.loadedItem = new BuildingBlock();
                            this.loadedItem.isTemplate = this.createMode === 'bb-template' ? true : false;
                            this.createMode = 'bb';
                            this.loading['rmItem'] = false;
                          }
                        });
                      this.formSteps = this.bbSteps;
                      this.getFiles();
                      break;
                    }
                    case 'recipe-template':
                    case 'recipe': {

                      //Author: Rajesh
                      //Date:   4 July 2023
                      //Purpose: Clear cache when loading create Recipe page
                      if(this.currentAction?.toLowerCase() == 'create'){
                        localStorage.removeItem(this.createMode + '_request_form');
                      }
                      //===================== End =========================
                      (this.submitRequestForm.controls['basicFeatures'] as UntypedFormGroup).controls['recipeName'].addValidators(Validators.required);
                      (this.submitRequestForm.controls['orderBuildingBlocks'] as UntypedFormGroup).controls['modules'].addValidators(Validators.required);
                      (this.submitRequestForm.controls['orderBuildingBlocks'] as UntypedFormGroup).controls['buildingBlocks'].addValidators(Validators.required);

                      (this.submitRequestForm.controls['basicFeatures'] as UntypedFormGroup).controls['buildingBlockName'].clearValidators();
                      (this.submitRequestForm.controls['basicFeatures'] as UntypedFormGroup).controls['module'].clearValidators();
                      (this.submitRequestForm.controls['basicFeatures'] as UntypedFormGroup).controls['type'].clearValidators();
                      this.route.queryParamMap.subscribe(querys => {
                        console.log(querys)
                        if (params.get('id')) {
                          this.itemId = params.get('id');
                        }
                        if (this.itemId) {
                          this.loading['rmItem'] = true;

                          if (querys.get('revision')) {
                            if (!isNaN(Number(querys.get('revision')))) {
                              this.revision = Number(querys.get('revision'));
                              this.searchGateway.getRecipeRevHistory(this.itemId).subscribe(rhResult => {
                                let recipeResult: any = rhResult.filter((r) => (r.revision === this.revision))[0];
                                if (!recipeResult || Object.keys(recipeResult).length === 0) {
                                  this.searchGateway.getRecipes({ recipeId: this.itemId }).subscribe((currentRecipesResult: any) => {
                                    if (currentRecipesResult && Object.keys(currentRecipesResult).length > 0) {
                                      const currentRecipeResult = currentRecipesResult[0];
                                      currentRecipeResult['createdBy'] = {
                                        name: currentRecipeResult.createdByName,
                                        email: currentRecipeResult.createdByEmail,
                                        wwid: currentRecipeResult.createdByWwid
                                      }
                                      currentRecipeResult['updatedBy'] = {
                                        name: currentRecipeResult.updatedByName,
                                        email: currentRecipeResult.updatedByEmail,
                                        wwid: currentRecipeResult.updatedByWwid
                                      }
                                      currentRecipeResult['owner'] = {
                                        name: currentRecipeResult.ownerName,
                                        email: currentRecipeResult.ownerEmail,
                                        wwid: currentRecipeResult.ownerWwid
                                      }
                                      this.updateLists(currentRecipeResult, this.preloads);
                                      this.loadedItem = currentRecipeResult;

                                      this.patchRequestForm(currentRecipeResult);
                                      this.itemOwner = this.isOwner(currentRecipeResult);
                                      this.viewOnly = true;
                                      this.refreshReadOnly();
                                      this.checkValidation();
                                      this.notificationService.addInfoNotification('Recipe Load Successful', `Recipe ${currentRecipeResult.recipeNumber} loaded successfully.`);
                                      this.loading['rmItem'] = false;
                                    }
                                  });
                                } else {
                                  recipeResult['createdBy'] = {
                                    name: recipeResult.createdByName,
                                    email: recipeResult.createdByEmail,
                                    wwid: recipeResult.createdByWwid
                                  }
                                  recipeResult['updatedBy'] = {
                                    name: recipeResult.updatedByName,
                                    email: recipeResult.updatedByEmail,
                                    wwid: recipeResult.updatedByWwid
                                  }
                                  recipeResult['owner'] = {
                                    name: recipeResult.ownerName,
                                    email: recipeResult.ownerEmail,
                                    wwid: recipeResult.ownerWwid
                                  }
                                  recipeResult.id = recipeResult.recipeId;
                                  this.updateLists(recipeResult, this.preloads);
                                  this.loadedItem = recipeResult;
                                  this.patchRequestForm(recipeResult);
                                  this.itemOwner = this.isOwner(recipeResult);
                                  this.viewOnly = true;
                                  this.refreshReadOnly();
                                  this.checkValidation();
                                  this.notificationService.addInfoNotification('Recipe Load Successful', `Recipe ${recipeResult.recipeNumber} loaded successfully.`);
                                  this.loading['rmItem'] = false;
                                }
                              });
                            } else {
                              this.notificationService.addErrorNotification('Recipe does not exist', 'id: ' + this.itemId);
                            }
                          }
                          else {
                            this.createdByWwid = querys.get('createdByWwid');
                            if (this.createdByWwid) {

                              this.createGateway.getRecipe(this.itemId, this.createdByWwid).subscribe(recipeResult => {
                                if(recipeResult){
                                //Author: Rajesh
                                //Date:   4 July 2023
                                //Purpose: Check item owner for owner/co-owner
                                this.itemOwner = this.isOwner(recipeResult);
                                //===================== Ends =========================
                                switch (this.currentAction?.toLowerCase()) {
                                  case 'copy': {
                                    this.currentAction = 'Create';
                                    if (this.itemId) {
                                      recipeResult.parentRecipe =
                                      {
                                        id: this.itemId,
                                        revision: recipeResult.revision
                                      };
                                    }
                                    break;
                                  }
                                  case 'edit': {

                                    break;
                                  }
                                }

                                this.updateLists(recipeResult, this.preloads);
                                this.loadedItem = recipeResult;
                                this.patchRequestForm(recipeResult);
                                //this.itemOwner = this.isOwner(recipeResult);
                                this.viewOnly = false;

                                this.checkValidation();
                                this.notificationService.addInfoNotification('Recipe Load Successful', `Recipe ${recipeResult.recipeNumber} loaded successfully.`);
                                this.loading['rmItem'] = false;
                                this.itemLoaded = true;
                              }},
                                err => {
                                  this.notificationService.addErrorNotification('getRecipe Error', err.error);
                                  console.log("Error caught at Subscriber " + err.error);
                                });
                            } else {
                              this.notificationService.addErrorNotification('Bad Edit Link','"createByWwid" is required.');
                            }

                          }
                        } else {
                          this.loadedItem = new Recipe();
                          this.loadedItem.isTemplate = this.createMode === 'recipe-template' ? true : false;
                          this.createMode = 'recipe';
                          this.itemLoaded = true;
                          this.loading['rmItem'] = false;
                        }
                      });
                      this.formSteps = this.recipeSteps;
                      break;
                    }
                  }
                  this.formKeys = Object.keys(this.formSteps);
                } else {
                  this.router.navigate(['recipe/create']);
                }
                switch (this.currentAction?.toLowerCase()) {
                  case 'copy': {
                    break;
                  }
                  case 'edit': {
                    break;
                  }
                  case 'create': {
                    if(this.createMode.toLowerCase() == 'recipe'){
                      this.updateLists(this.loadedItem, this.preloads);
                      if (this.currentUser) {
                        var isRedirectFromViewBB = sessionStorage.getItem("IsRedirectFromView");
                        if(isRedirectFromViewBB == "1")
                        {
                          var requestObject =  sessionStorage.getItem("recipe_request_form");
                          if (requestObject != undefined){
                            this.loadedItem = JSON.parse(requestObject);
                            this.loadedItem.isTemplate = this.createMode === 'recipe-template' ? true : false;
                            this.patchRequestFormFromStorage(this.loadedItem);
                            this.checkValidation();
                          }
                        }
                        else{
                          this.initializeForm();
                          this.itemOwner = this.isOwner(this.currentUser);
                          this.clearBB2RecipeCache();
                          this.checkValidation();
                        }
                      }
                    }else{
                      this.updateLists(this.loadedItem, this.preloads);
                      if (this.currentUser) {
                        this.initializeForm();
                        this.itemOwner = this.isOwner(this.loadedItem);
                        this.checkValidation();
                      }
                    }
                    break;
                  }
                }
              });
            } else {
              this.notificationService.addErrorNotification('No Identified User', 'No Identified User');
            }
          });
      }
    }
  }

  ngAfterViewInit() {
    const header = document.querySelector('.headerBlock');
    this.observer = new IntersectionObserver((entry, observer) => {
      this.baseHeaderVisible = entry[0].isIntersecting;
    });
    if (header) {
      this.observer.observe(header);
    }
  }

  createCheckListItemFormGroup = (checkListItem: any) =>
    new UntypedFormGroup({
      id: new UntypedFormControl(checkListItem.id),
      name: new UntypedFormControl(checkListItem.name, Validators.required),
      type: new UntypedFormControl(checkListItem.type, Validators.required),
      isRequired: new UntypedFormControl(checkListItem.isRequired),
      saveMode:  new UntypedFormControl(false),
    });

  deleteCheckListItem(index: number) {
    this.checkListItems.removeAt(index);
  }

  public createCheckListFormGroup(args: CreateFormGroupArgs): UntypedFormGroup {
    const item = args.isNew ? new CheckListItem() : args.dataItem;

    this.checkListFormGroup = this.formBuilder.group({
      id: item.id,
      name: item.name,
      type: item.type,
      isRequired: item.isReqired,
      saveMode: item.saveMode
    });

    this.submitRequestForm.addControl('CheckList', this.checkListFormGroup);
    return this.checkListFormGroup;
  }

  initializeForm() {
    let sessionFormData: any;

    if (this.currentUser) {
      this.createGateway.getConfiguration(this.currentUser.jobTitle).subscribe((config: any) => {
        this.currentUserConfiguration = config;
        if (config) {
          const updatedLabs: any[] = [];
          config['labs'].forEach((item: any) => {
            const foundItem = this.rmLabList.find(listedItem => (listedItem.name === item.name.toUpperCase()))
            if (foundItem) {
              if (!foundItem.isArchived) {
                updatedLabs.push(foundItem);
              }
            }
          });
          const configProducts: any[] = config['products'];
          let updatedSiliconProgram: string | undefined;
          let updatedProducts: any[] = [];

          for (const prod of configProducts) {
            const foundProd = this.masterProductsList.find((pr: any) => pr.productName === prod.name);
            if (foundProd) {
              if (!foundProd.isArchived) {
                if (!updatedSiliconProgram) {
                  updatedSiliconProgram = foundProd.siliconProgramName;
                }
                if (foundProd.siliconProgramName === updatedSiliconProgram) {
                  updatedProducts.push({ name: prod.name, isArchived: false });
                }
              }
            }
          }
          const updatedValidationTeams: any[] = [];
          for (const item of config['validationTeams']) {
            const foundItem = this.rmValidationTeams.find(listedItem => (listedItem.name === item.name))
            if (foundItem) {
              if (!foundItem.isArchived) {
                updatedValidationTeams.push(foundItem);
              }
            }
          }

          sessionFormData = this.submitRequestForm.getRawValue();
          sessionFormData.basicFeatures.labs = updatedLabs;
          sessionFormData.basicFeatures.products = updatedProducts;
          sessionFormData.basicFeatures.siliconProgram = updatedSiliconProgram? updatedSiliconProgram: ''; // WH 02Aug2023 - fix empty owner rootcause
          sessionFormData.basicFeatures.validationTeams = updatedValidationTeams;



          this.submitRequestForm.setValue(sessionFormData);
          this.patchLoadedItemFromForm();
        }
      });
    }
  }

  updateSessionDateFromConfig(sessionFormData: any): any {
    let newSessionData = sessionFormData;

    if (this.currentUserConfiguration) {
      const updatedLabs: any[] = [];
      this.currentUserConfiguration['labs'].forEach((item: any) => {
        const foundItem = this.rmLabList.find(listedItem => (listedItem.name === item.name.toUpperCase()))
        if (foundItem) {
          if (!foundItem.isArchived) {
            updatedLabs.push(foundItem.toUpperCase());
          }
        }
      });
      const configProducts: any[] = this.currentUserConfiguration['products'];
      let updatedSiliconProgram: string | undefined;
      let updatedProducts: any[] = [];

      for (const prod of configProducts) {
        const foundProd = this.masterProductsList.find((pr: any) => pr.productName === prod.name);
        if (foundProd) {
          if (!foundProd.isArchived) {
            if (!updatedSiliconProgram) {
              updatedSiliconProgram = foundProd.siliconProgramName;
            }
            if (foundProd.siliconProgramName === updatedSiliconProgram) {
              updatedProducts.push({ name: prod.name, isArchived: false });
            }
          }
        }
      }
      const updatedValidationTeams: any[] = [];
      for (const item of this.currentUserConfiguration['validationTeams']) {
        const foundItem = this.rmValidationTeams.find(listedItem => (listedItem.name === item.name))
        if (foundItem) {
          if (!foundItem.isArchived) {
            updatedValidationTeams.push(foundItem);
          }
        }
      }

      newSessionData.basicFeatures.labs = updatedLabs;
      newSessionData.basicFeatures.products = updatedProducts;
      newSessionData.basicFeatures.siliconProgram = updatedSiliconProgram;
      newSessionData.basicFeatures.validationTeams = updatedValidationTeams;

    }
    return newSessionData;
  }

  patchRequestFormFromStorage(loadedItem: any) {
    try {
      Object.keys(this.submitRequestForm.controls).forEach(fgName => {
        const fg = this.submitRequestForm.controls[fgName] as UntypedFormGroup;
        Object.keys(fg.controls).forEach(controlName => {
          const formControlActual = fg.get(controlName);
          if (formControlActual) {
            let noOwner = false;
            switch (controlName) {
              case 'owner': {
                if (this.loadedItem['owners']["owner"]) {
                  if (this.loadedItem['owners']["owner"].jobTitle) {
                    this.fillOwner(this.loadedItem['owners']["owner"].jobTitle);
                  } else {
                    noOwner = true;
                  }
                } else {
                  noOwner = true;
                }
                if (this.currentUser && noOwner) {
                  this.fillOwner(this.currentUser.jobTitle);
                }
                break;
              }
              case 'modules': {
                const formControlActual = fg.controls['modules'] as UntypedFormArray;
                if (loadedItem['orderBuildingBlocks']['modules']) {

                  for(const updateItem of loadedItem['orderBuildingBlocks']['modules']){
                    const sourceIndex = this.rmActiveModules.findIndex(i=>i.name.toLowerCase() == updateItem.name.toLowerCase());
                    if(sourceIndex != -1)
                    {
                      this.rmActiveModules[sourceIndex].selected=true;
                    }
                  }
                  let modulesFormGroup;
                  loadedItem['orderBuildingBlocks']['modules'].sort((a: any, b: any) => a.order > b.order ? 1 : -1).forEach(async (m: any) => {
                    modulesFormGroup = this.formBuilder.group({
                      name: [m.name.toLowerCase(), Validators.required],
                      order: [m.order, Validators.required],
                    });
                    formControlActual.push(modulesFormGroup);
                    this.selectedRecipeModules.push(m.name.toLowerCase());
                    //this.recipeModuleExpanded[m.name.toLowerCase()] = false;
                    // this.expandedModulePanel[m.name.toLowerCase()] = false;
                    this.recipeModuleExpanded[m.name.toLowerCase()] = true;
                    this.expandedModulePanel[m.name.toLowerCase()] = true;

                    const getBuildingBlocksResult$ = this.searchGateway.getBuildingBlocks({ module: m.name, isTemplate: false, isDraft: false, isArchive: false });
                    const getBuildingBlocksFinal = await lastValueFrom(getBuildingBlocksResult$);

                    this.selectedRecipeBuildingBlocks[m.name.toLowerCase()] = [];
                    getBuildingBlocksFinal.forEach((bb: any) => {
                      bb.products = this.getJoinedValues(bb.products);
                      bb.validationTeams = this.getJoinedValues(bb.validationTeams);
                      bb.labs = this.getJoinedValues(bb.labs);
                      const loadedBBIndex = loadedItem['orderBuildingBlocks']['buildingBlocks'].findIndex((rbb: any) => bb.id === rbb.buildingBlockId);
                      bb['selected'] = (loadedBBIndex >= 0);
                      if (bb['selected']) {
                        bb.order = this.loadedItem['orderBuildingBlocks']['buildingBlocks'][loadedBBIndex].order;
                        this.selectedRecipeBuildingBlocks[m.name.toLowerCase()].push(bb);
                        this.selectedRecipeBuildingBlocks[m.name.toLowerCase()].sort((a: any, b: any) => a.order > b.order ? 1 : -1);
                        this.showChecklist();
                      }
                    });
                    this.preSelectedBuildingBlocks[m.name.toLowerCase()] = getBuildingBlocksFinal;
                    this.onBBReOrder(false);
                  });
                }
                break;
              }
              default: {
                if (loadedItem[controlName] && (controlName !== '')) {
                  formControlActual.setValue(loadedItem[controlName]);
                }
              }

            }
            formControlActual.markAsDirty();
          }
        });
      });
    } catch (e: any) {
      console.log(e);
    } finally {
      this.refreshReadOnly();
      this.checkValidation();
    }
  }

  patchLoadedItemFromForm() {
    try {
      Object.keys(this.submitRequestForm.controls).forEach(fgName => {
        const fg = this.submitRequestForm.controls[fgName] as UntypedFormGroup;
        Object.keys(fg.controls).forEach(controlName => {
          const formControlActual = fg.get(controlName);
          if (formControlActual) {
            this.loadedItem[controlName] = formControlActual.value;
            // if (this.loadedItem[controlName]) {
            // }
            let noOwner = false;
            switch (controlName) {
              case 'type':
              case 'module': {
                if (formControlActual.value) {
                  this.loadedItem[controlName] = formControlActual.value.name;
                }
                break;
              }
              case 'owner': {
                if (this.loadedItem['owner']) {
                  if (this.loadedItem['owner'].jobTitle) {
                    this.fillOwner(this.loadedItem['owner'].jobTitle);
                  } else {
                    noOwner = true;
                  }
                } else {
                  noOwner = true;
                }
                if (this.currentUser && noOwner) {
                  this.fillOwner(this.currentUser.jobTitle);
                }

                break;
              }
              case 'coOwner': {
                if (this.loadedItem['coOwner'] && this.loadedItem['coOwner'].length > 0) {
                  this.loadedItem['coOwner'].forEach((item: any) => {
                    if (item.jobTitle) {
                      this.fillCoOwner(item.jobTitle);
                    }
                  });
                }
                break;
              }
              case 'content': {
                if (this.loadedItem['content']) {
                  this.getFile(this.loadedItem['content']);
                }
                break;
              }
              case 'modules': {
                const formControlActual = fg.controls['modules'] as UntypedFormArray;
                if (formControlActual.controls.length > 0) {
                  this.loadedItem.modules.sort((a: any, b: any) => a.order > b.order ? 1 : -1);

                  this.loadedItem['buildingBlocks'] =
                    (((this.submitRequestForm.controls['orderBuildingBlocks']) as UntypedFormGroup).controls['buildingBlocks'] as UntypedFormArray).value;

                  if (this.loadedItem['buildingBlocks']) {
                    this.loadedItem['buildingBlocks'].sort((a: any, b: any) => a.order > b.order ? 1 : -1);
                  }
                  console.log(this.loadedItem['buildingBlocks'])
                  formControlActual.controls.forEach(async (fG: any) => {
                    const thisFg = fG as UntypedFormGroup;
                    const m = {
                      name: thisFg.controls['name'].value,
                      order: thisFg.controls['order'].value
                    };

                    await this.moduleCheckChange({ target: { checked: true } }, m); // populates selectedRecipeModules

                    const getBuildingBlocksResult$ = this.searchGateway.getBuildingBlocks({ module: m.name, isTemplate: false, isDraft: false, isArchive: false });
                    const getBuildingBlocksFinal = await lastValueFrom(getBuildingBlocksResult$);

                    this.selectedRecipeBuildingBlocks[m.name] = [];
                    const bbList: any[] = [...getBuildingBlocksFinal];
                    for (let bb of bbList) {
                      bb.products = this.getJoinedValues(bb.products);
                      bb.validationTeams = this.getJoinedValues(bb.validationTeams);
                      bb.labs = this.getJoinedValues(bb.labs);
                      const loadedBBIndex = this.loadedItem['buildingBlocks'].findIndex((rbb: any) => bb.id === rbb.buildingBlockId);
                      if (loadedBBIndex >= 0) {
                        bb.selected = true;
                        bb.order = this.loadedItem['buildingBlocks'][loadedBBIndex].order;
                        this.selectedRecipeBuildingBlocks[m.name].push(bb);
                        this.showChecklist(); // WH - show checklist preview
                      }

                    }

                    this.selectedRecipeBuildingBlocks[m.name].sort((a: any, b: any) => a.order > b.order ? 1 : -1);
                    this.preSelectedBuildingBlocks[m.name] = getBuildingBlocksFinal;
                    this.onBBReOrder(false);

                  });
                }
                break;
              }
              case 'siliconProgram': {
                this.siliconProgramChanged(formControlActual.value, false);
                break;
              }
              case 'recipeName': {
                this.onBlur('basicFeatures', 'recipeName');
              }

            }
          }
        });
      });
    } catch (e: any) {
//      console.log(e);
      this.notificationService.addErrorNotification('Error updating data from form. Try to Clear Local Cache.', e);
    }

    this.refreshReadOnly();
    this.submitRequestForm.markAllAsTouched();
  }

  patchRequestForm(loadedItem: any) {
    try {
      if (loadedItem.revision > 0) {
        const newParentInfo = { id: loadedItem.id, revision: loadedItem.revision - 1 };
        if (this.createMode === 'bb') {
          loadedItem.parentBuildingBlock = newParentInfo;
        }
        if (this.createMode === 'recipe') {
          loadedItem.parentRecipe = newParentInfo;
        }
      }
      Object.keys(this.submitRequestForm.controls).forEach(fgName => {
        const fg = this.submitRequestForm.controls[fgName] as UntypedFormGroup;
        Object.keys(fg.controls).forEach(controlName => {
          const formControlActual = fg.get(controlName);
          if (formControlActual) {
            switch (controlName) {
              case 'owner': {
                if (loadedItem['owner'].wwid) {
                  this.fillOwner(loadedItem['owner'].wwid);
                }
                break;
              }
              case 'coOwner': {
                if (loadedItem['coOwner'].length > 0) {
                  loadedItem['coOwner'].forEach((item: any) => {
                    if (item.wwid) {
                      this.fillCoOwner(item.wwid);
                    }
                  });
                }
                break;
              }
              case 'content': {
                if (loadedItem['content']) {
                  this.getFile(loadedItem['content']);
                }
                break;
              }
               case 'checkList': {
                const formControlActual = fg.controls['checkList'] as UntypedFormArray;
                if (loadedItem['checkList']) {
                  let checkListUpdated = false;
                  for (let cl of loadedItem['checkList']) {
                    if (!cl.id) {
                      cl.id = crypto.randomUUID();
                      checkListUpdated = true;
                    }
                    this.checkListFormGroup = this.formBuilder.group({
                      id: [cl.id],
                      name: [cl.name], //, Validators.required],
                      type: [cl.type], //, Validators.required],
                      isRequired: [cl.isRequired],
                      saveMode: [true]
                    });

                    formControlActual.push(this.checkListFormGroup);
                  }
                  if (checkListUpdated) {
                    this.updates['checkList'] =
                    {
                      path: 'checkList',
                      op: 'replace',
                      value: this.loadedItem.checkList
                    };
                  }
                }

                break;
              }
              case 'modules': {
                const formControlActual = fg.controls['modules'] as UntypedFormArray;
                if (loadedItem['modules']) {
                  let modulesFormGroup;
                  loadedItem['modules'].sort((a: any, b: any) => a.order > b.order ? 1 : -1).forEach(async (m: any) => {
                    modulesFormGroup = this.formBuilder.group({
                      name: [m.name, Validators.required],
                      order: [m.order, Validators.required],
                    });

                    formControlActual.push(modulesFormGroup);

//                    await this.moduleCheckChange({ target: { checked: true } }, m); // populates selectedRecipeModules

                    this.selectedRecipeModules.push(m.name);
                    //this.recipeModuleExpanded[m.name] = false;
                    //this.expandedModulePanel[m.name] = false;
                    this.recipeModuleExpanded[m.name] = true;
                    this.expandedModulePanel[m.name] = true;

                    const getBuildingBlocksResult$ = this.searchGateway.getBuildingBlocks({ module: m.name, isTemplate: false, isDraft: false, isArchive: false });
                    const getBuildingBlocksFinal = await lastValueFrom(getBuildingBlocksResult$);

                    this.selectedRecipeBuildingBlocks[m.name] = [];
                    getBuildingBlocksFinal.forEach((bb: any) => {
                      bb.products = this.getJoinedValues(bb.products);
                      bb.validationTeams = this.getJoinedValues(bb.validationTeams);
                      bb.labs = this.getJoinedValues(bb.labs);
                      const loadedBBIndex = loadedItem['buildingBlocks'].findIndex((rbb: any) => bb.id === rbb.buildingBlockId);
                      bb['selected'] = (loadedBBIndex >= 0);
                      if (bb['selected']) {
                        bb.order = this.loadedItem['buildingBlocks'][loadedBBIndex].order; // WH - 21Aug2023 add order property to BB (only available in checked BB)
                        this.selectedRecipeBuildingBlocks[m.name].push(bb);
                        this.selectedRecipeBuildingBlocks[m.name].sort((a: any, b: any) => a.order > b.order ? 1 : -1); // WH - 21Aug2023 sort by BB order
                        this.showChecklist(); // WH - show checklist preview
                      }
                    });
                    this.preSelectedBuildingBlocks[m.name] = getBuildingBlocksFinal;
                    this.onBBReOrder(false);

                  });
                }
                break;
              }
              case 'buildingBlocks': {
                const formControlActual = fg.controls['buildingBlocks'] as UntypedFormArray;
                if (loadedItem[controlName]) {
                  let bbFormGroup;
                  loadedItem[controlName].forEach(async (bb: any) => {

                    bbFormGroup = this.formBuilder.group({
                      buildingBlockId: [bb.buildingBlockId, Validators.required],
                      moduleName: [bb.moduleName, Validators.required],
                      order: [bb.order, Validators.required],
                      checkList: [bb.checkList]
                    });
                    formControlActual.push(bbFormGroup);
                  });
                }
                break;
              }
              case 'siliconProgram': {
                if (loadedItem[controlName]) {
                  formControlActual.setValue(loadedItem[controlName]);
                  this.siliconProgramChanged(loadedItem[controlName], loadedItem['products'].length === 0);
                }
                break;
              }
              case 'type':
              case 'module': {
                if (loadedItem[controlName]) {
                  formControlActual.setValue({ name: loadedItem[controlName] })
                }
                break;
              }
              case 'recipeName': {
                let products = [];
                let boardTypes = [];
                let validationTeams = [];
                if (this.loadedItem.products && this.loadedItem.products.length > 0) {
                  products = this.loadedItem.products.map((p: any) => p.name);
                }
                if (this.loadedItem.boardTypes && this.loadedItem.boardTypes.length > 0) {
                  boardTypes = this.loadedItem.boardTypes.map((bt: any) => bt.name);
                }
                if (this.loadedItem.validationTeams && this.loadedItem.validationTeams.length > 0) {
                  validationTeams = this.loadedItem.validationTeams.map((vt: any) => vt.name);
                }
                if (this.loadedItem.recipeName !== products.join('_') + '_' + boardTypes.join('_') + '_' + validationTeams.join('_')) {
                  this.freeTextModeTempValue = true;
                } else {
                  this.freeTextModeTempValue = false;
                }
                formControlActual.setValue(loadedItem[controlName]);
                break;
              }
              default: {
                if (loadedItem[controlName] && (controlName !== '')) {
                  formControlActual.setValue(loadedItem[controlName]);
                }
              }

            }
            formControlActual.markAsDirty();
          }
        });
      });
    } catch (e: any) {
      console.log(e);
    } finally {
      this.refreshReadOnly();
      this.checkValidation();
    }
  }

  updateLists(loadedItem: any, snapshotData: any) {

    this.rmLabList = snapshotData['rmLabList'];
    this.rmValidationTeams = snapshotData['rmValidationTeams'];
    this.rmBoardTypes = snapshotData['rmBoardTypes'];
    this.rmModules = snapshotData['rmModules'];
    if (loadedItem['modules']) {
      this.loading['rmActiveModules'] = true;
      this.rmActiveModules = snapshotData['rmActiveModules'].map((module: any) => (
        {
          name: module.name,
          selected: false
        }
      ));
      this.rmActiveModules.forEach(am => {
        am.selected = loadedItem['modules'].findIndex((mod: { name: string, selected: boolean }) => mod.name.toLowerCase() === am.name.toLowerCase()) >= 0
      });
      this.loading['rmActiveModules'] = false;
    }
    this.masterProductsList = snapshotData['masterProductsList'];
    this.rmSiliconPrograms = snapshotData['rmSiliconPrograms'];

    this.rmTypes = snapshotData['rmTypes'];
    this.ownerData = snapshotData['rmOwners'];
    this.coownerData = snapshotData['rmOwners'];

    this.currentUserConfiguration = snapshotData['configData'];
  }

  getAllBuildingBlocks(module: string) {
    this.searchGateway.getBuildingBlocks({ module: module, isTemplate: false, isDraft: false, isArchive: false }).subscribe((result: BuildingBlock[]) => {
      result.forEach((bb: BuildingBlock) => {
        bb.products = this.getJoinedValues(bb.products);
        bb.validationTeams = this.getJoinedValues(bb.validationTeams);
        bb.labs = this.getJoinedValues(bb.labs);
      });
      this.allBuildingBlocks = [...result.filter((bb) => (bb.module))];
    });
  }

  public ngOnDestroy() {
    this.clearBB2RecipeCache();
  }

  public update(isDraft: boolean): void {

    this.loadedItem.isDraft = isDraft;
    this.loadedItem.isArchived = false;

    let updatedBy = new Person();
    if (this.currentUser) {
      updatedBy.name = this.currentUser?.displayName;
      updatedBy.wwid = this.currentUser?.jobTitle,
      updatedBy.email = this.currentUser?.mail

      this.loadedItem.updatedBy = updatedBy;
      this.loadedItem.updatedDate = new Date();
    }

    const apiUpdates: any = [];

    Object.keys(this.updates).forEach(key => {
      delete this.updates[key].value['isArchived'];
      // const keyValue = this.updates[key].value;
      // const isArchivedIndex = Object.keys(keyValue).findIndex(item => item === 'isArchived')
      // if (isArchivedIndex > -1) {
      //   Object.keys(keyValue).splice(isArchivedIndex);
      // }
      apiUpdates.push(this.updates[key]);

    });

    apiUpdates.push({ path: 'updatedBy', op: 'replace', value: JSON.parse(JSON.stringify(this.loadedItem.updatedBy)) });
    apiUpdates.push({ path: 'isDraft', op: 'replace', value: this.loadedItem.isDraft });
    apiUpdates.push({ path: 'isArchived', op: 'replace', value: this.loadedItem.isArchived });
    this.disableSaveButtons = true;
    switch (this.createMode) {
      case 'bb': {
        this.createGateway.updateBuildingBlock(this.loadedItem, apiUpdates, this.updateRecipes).subscribe((result: any) => {
          if (result) {
            console.log(result);
            if (result.id && this.loadedItem.buildingBlockNumber === result.buildingBlockNumber){
              this.reloadPage();
            } else {
              this.toRefresh = true;
              let url = `#/bb/edit/${result.id}?createdByWwid=${this.createdByWwid}`;
              window.open(url);
              this.alertDialogTitle = 'New BB Created'
              this.alertDialogMessage = this.loadedItem.buildingBlockNumber
                + ' is used by Recipe with different owner, so new '
                + result.buildingBlockNumber + ' is created and opened in new tab';
              this.alertDialogOpen = true;
            }

          }
        },
          (error: any) => {
            this.disableSaveButtons = false;
            this.loading['rmItem'] = false;
            this.notificationService.addErrorNotification(`Problem Updating Building Blocks`, error.message);
          });
        break;
      }
      case 'recipe': {
        console.log(this.loadedItem)
        this.createGateway.updateRecipe(this.loadedItem, apiUpdates).subscribe((result: any) => {
          if (result) {
            if (result.id) {
              this.clearBB2RecipeCache();
              this.reloadPage();
            }
          }
        },
          (error: any) => {
            this.disableSaveButtons = false;
            this.loading['rmItem'] = false;
            this.notificationService.addErrorNotification(`Problem Updating Recipe`, error.message);
          });
        break;
      }
    }
  }

  public create(isDraft: boolean): void {
    let proceed = true;
    if (this.containsBBTemplate) {
      proceed = false;
      if (!this.loadedItem.isTemplate) {
        // if (confirm('Recipe Template contains Building Block Template(s) that will be automatically converted. Continue?')) {
        //   proceed = true;
        // }
      }
    }

    if (proceed) {
      let item: any;

      this.loading['rmItem'] = true;

      this.disableSaveButtons = true;
      if (this.createMode == 'bb') {
        item = new BuildingBlock();
      } else {
        item = new Recipe();
      }

      item.isDraft = isDraft;
      item.isArchived = false;
      item.isTemplate = this.loadedItem.isTemplate;

      let createdBy = new Person();
      if (this.currentUser) {
        createdBy.name = this.currentUser?.displayName;
        createdBy.wwid = this.currentUser?.jobTitle,
          createdBy.email = this.currentUser?.mail

        item.createdBy = createdBy;
        item.createdDate = new Date();
        item.updatedBy = createdBy;
        item.updatedDate = new Date();
      }


      // #region Owners
      const owners = this.submitRequestForm.controls['owners'] as UntypedFormGroup;

      item.owner = new Person();
      item.owner.email = owners.controls['owner'].value.mail;
      item.owner.wwid = owners.controls['owner'].value.jobTitle;
      item.owner.name = owners.controls['owner'].value.displayName;
      /*
          this.coOwners.forEach(co => {
            item.coOwner.push({
              name: co.displayName,
              email: co.mail,
              wwid: co.jobTitle
            });
          });
      */
      if (owners.controls['coOwner'].value) {
        item.coOwner = owners.controls['coOwner'].value.map((coOwner: any) => { return { email: coOwner.mail, wwid: coOwner.jobTitle, name: coOwner.displayName } });
      }
      //#endregion

      // #region basicFeaturs
      const basicFeatures = this.submitRequestForm.controls['basicFeatures'] as UntypedFormGroup;

      item.rampUp = basicFeatures.controls['rampUp'].value;

      if (basicFeatures.controls['labs'].value) {
        item.labs = basicFeatures.controls['labs'].value.map((formItem: any) => { return { name: formItem.name } });
      }

      if (basicFeatures.controls['validationTeams'].value) {
        item.validationTeams = basicFeatures.controls['validationTeams'].value.map((formItem: any) => { return { name: formItem.name } });
      }

      if (basicFeatures.controls['boardTypes'].value) {
        item.boardTypes = basicFeatures.controls['boardTypes'].value.map((formItem: any) => { return { name: formItem.name } });
      }

      if (basicFeatures.controls['module'].value) {
        if (basicFeatures.controls['module'].value.name) {
          item.module = basicFeatures.controls['module'].value.name;
        }
      }

      item.siliconProgram = basicFeatures.controls['siliconProgram'].value;

      if (basicFeatures.controls['products'].value) {
        item.products = basicFeatures.controls['products'].value.map((formItem: any) => { return { name: formItem.name } });
      }

      const thisDate = new Date();
      item.revision = 0;

      if (basicFeatures.controls['type'].value) {
        if (basicFeatures.controls['type'].value.name) {
          item.type = basicFeatures.controls['type'].value.name;
        }
      }

      // if (basicFeatures.controls['emailDistribution'].value) {
      //   item.emailDistribution = [...basicFeatures.controls['emailDistribution'].value];
      // }

      if(this.loadedItem['emailDistribution']) { // 25May2023 WH - fix basicFeatures.controls['emailDistribution'].value get NULL issue
        item.emailDistribution = this.loadedItem['emailDistribution'];
      }

      item.description = basicFeatures.controls['description'].value;
      // #endregion

      const blockManager = this.submitRequestForm.controls['blockManager'] as UntypedFormGroup;
      item.content = blockManager.controls['content'].value ? blockManager.controls['content'].value : '';


      const orderBuildingBlocksFg = this.submitRequestForm.controls['orderBuildingBlocks'] as UntypedFormGroup;
      if (orderBuildingBlocksFg) {
        const modulesFa = orderBuildingBlocksFg.controls['modules'] as UntypedFormArray;
        modulesFa.controls.forEach((fg: any) => {
          const thisFg = fg as UntypedFormGroup;
          item.modules.push({
            name: thisFg.controls['name'].value,
            order: thisFg.controls['order'].value
          });
        });

        const buildingBlocksFa = orderBuildingBlocksFg.controls['buildingBlocks'] as UntypedFormArray;
        buildingBlocksFa.controls.forEach((fg: any) => {
          const thisFg = fg as UntypedFormGroup;
          item.buildingBlocks.push({
            buildingBlockId: thisFg.controls['buildingBlockId'].value,
            order: thisFg.controls['order'].value,
            moduleName: thisFg.controls['moduleName'].value,
            checkList: thisFg.controls['checkList'].value
          });
        });
      }

      const checklistFg = this.submitRequestForm.controls['CheckList'] as UntypedFormGroup;
      if (checklistFg) {
        const checklistFa = checklistFg.controls['checkList'] as UntypedFormArray;
        checklistFa.controls.forEach((fg: any) => {
          const thisFg = fg as UntypedFormGroup;
          item.checkList.push({
            name: thisFg.controls['name'].value,
            isRequired: thisFg.controls['isRequired'].value ? true : false,
            type: thisFg.controls['type'].value
          });
        }); // ? checkList.controls['CheckList'].value : [];
      }

      switch (this.createMode) {
        case 'bb': {

          item.buildingBlockName = basicFeatures.controls['buildingBlockName'].value;
          item.parentBuildingBlock = this.loadedItem.parentBuildingBlock;
          this.createGateway.createBuildingBlock(item, true).subscribe((result: any) => {
            if (result) {
              if (result.id) {
                const url = this.createMode + '/edit/' + result.id;
                this.router.navigate(
                  [url],
                  { queryParams: { createdByWwid: result.createdBy.wwid } }
                );
              }
            }
          },
            (error: any) => {
              this.disableSaveButtons = false;
              this.loading['rmItem'] = false;
              this.notificationService.addErrorNotification(`Problem Creating Building Block`, error.message);
            });
          break;
        }
        case 'recipe': {
          item.recipeName = basicFeatures.controls['recipeName'].value;
          item.parentRecipe = this.loadedItem.parentRecipe;
          this.createGateway.createRecipe(item, true).subscribe((result: any) => {
            if (result) {
              if (result.id) {
                if (this.currentAction !== 'Edit') {
                  const url = this.createMode + '/edit/' + result.id;
                  this.router.navigate(
                    [url],
                    { queryParams: { createdByWwid: result.createdBy.wwid } }
                  ).then(nav => {
                    this.clearBB2RecipeCache();
                    this.reloadPage(); // fix stuck at create action page after create recipe from template
                  }, err => {
                    console.log(err);
                  });
                }
              }
            }
          },
            (error: any) => {
              this.disableSaveButtons = false;
              this.loading['rmItem'] = false;
              this.notificationService.addErrorNotification(`Problem Creating Recipe`, error.message);
            });
          break;
        }
      }
    }


  }

  linkToCopy() {
    const url = `${this.createMode}/copy/${this.loadedItem.id}`;
    this.router.navigate(
      [url],
      { queryParams: { createdByWwid: this.loadedItem.createdBy.wwid } }
    );
  }

  delete() {
    if (confirm(`Archive this ${this.createMode === 'bb' ? 'Building Block' : 'Recipe'}?`)) {
      this.loading['rmItem'] = true;
      this.filterSubscription = this.createGateway.deleteBuildingBlock(this.loadedItem)
        .subscribe(result => {
          this.reloadPage();
        });
    }
  }

  padTo2Digits(num: any) {
    return num.toString().padStart(2, '0');
  }

  coOwnerSelectionChanged(event: ListBoxSelectionEvent) {
    this.coOwnerSelectionIndex = event.index;
  }

  coOwnerListAction(event: ActionName) {
    if (event === 'remove') {
      this.coOwnersChanged = true;
    }

    this.onBlur('owners', 'coOwner');
    /*     if (this.coOwnerSelectionIndex >= -1) {
          const index: number = this.coOwnerSelectionIndex; // this.coOwnerListbox.selectedIndex;

          if (event === 'remove' && index >= 0) {
            this.coOwners.splice(index, 1);
          }
        } */
  }

  public addCoOwner() {
    if (this.selectedCoowner) {
      this.coOwnersChanged = true;
      this.coOwners.push(this.selectedCoowner);

      const fg = this.submitRequestForm.controls['owners'] as UntypedFormGroup;
      const control = fg.controls['coOwner'] as UntypedFormControl;
      control.setValue(this.coOwners);

      this.selectedCoowner = null;
      this.coownerData = [];
      this.onBlur('owners', 'coOwner');
    }
  }

  public addEmailDist() {

    if (this.selectedEmailDist) {
      if (!this.loadedItem['emailDistribution']) {
        this.loadedItem['emailDistribution'] = [];
      }
      this.loadedItem['emailDistribution'].push(this.selectedEmailDist.mail);

      // const fg = this.submitRequestForm.controls['owners'] as UntypedFormGroup;
      // const control = fg.controls['coOwner'] as UntypedFormControl;
      // control.setValue(this.coOwners);

      this.updates['emailDistribution'] = // WH - 22Aug2023 fix remove email recipient not capture by save
      {
        path: 'emailDistribution',
        op: 'replace',
        value: this.loadedItem.emailDistribution? this.loadedItem.emailDistribution: []
      };

      this.selectedEmailDist = null;
      this.emailDistributionData = [];
      this.onBlur('basicFeatures', 'emailDistribution');
    }
  }

  public onBlur(groupName: string, controlName: string): void {
    if (this.currentAction === 'Edit' && !this.loading['rmItem']) {
      const fg = this.submitRequestForm.controls[groupName] as UntypedFormGroup;

      if (fg) {
        const control = fg.controls[controlName] as UntypedFormControl;
        if (control) {
          let controlValue;

          switch (controlName) {
            case 'labs': {
              controlValue = control.value.map((formItem: any) => {
                return { name: formItem.name }
              });
              break;
            }
            case 'boardTypes': {
              controlValue = control.value.map((formItem: any) => { return { name: formItem.name } });
              break;
            }
            case 'validationTeams': {
              controlValue = control.value.map((formItem: any) => { return { name: formItem.name } });
              break;
            }
            case 'module':
            case 'type': {
              if (control.value) {
                if (control.value.name) {
                  controlValue = control.value.name;
                }
              }
              break;
            }
            case 'modules': {
              const formArray = fg.controls[controlName] as UntypedFormArray;
              controlValue = formArray.controls.map((formGroup: any) => {
                return {
                  name: formGroup.controls['name'].value,
                  order: formGroup.controls['order'].value
                }
              });
              break;
            }
            case 'buildingBlocks': {
              const formArray = fg.controls[controlName] as UntypedFormArray;
              controlValue = formArray.controls.map((formGroup: any) => {
                return {
                  buildingBlockId: formGroup.controls['buildingBlockId'].value,
                  order: formGroup.controls['order'].value,
                  moduleName: formGroup.controls['moduleName'].value,
                  checkList: formGroup.controls['checkList']? formGroup.controls['checkList'].value: []
                }
              });
              break;
            }
            // case 'products': {
            //   controlValue = control.value.map((formItem: any) => { return { name: formItem } });
            //   break;
            // }
            case 'emailDistribution': {
              controlValue = [...control.value];
              break;
            }
            case 'owner': {
              controlValue = {
                name: control.value.displayName,
                email: control.value.mail,
                wwid: control.value.jobTitle
              }
              this.selectedOwner = control.value;
              break;
            }
            case 'coOwner': {
              controlValue = control.value.map((formItem: any) => {
                return {
                  name: formItem.displayName,
                  email: formItem.mail,
                  wwid: formItem.jobTitle
                }
              });
              break;
            }
            case 'checkList': {
              /*const formArray = fg.controls[controlName] as UntypedFormArray;
              controlValue = formArray.controls.map((formGroup: any) => {
                return {
                  id: [formGroup.controls['id'].value],
                  name: formGroup.controls['name'].value,
                  type: formGroup.controls['type'].value,
                  isRequired: formGroup.controls['isRequired'].value ? true : false,
                  saveMode: true
                }
              }); */
              controlValue = this.loadedItem.checkList;
              break;
            }
            default: {
              controlValue = control.value;
            }
          }

          if (this.loadedItem[controlName] !== controlValue) {
            this.updates[controlName] =
            {
              path: controlName,
              op: 'replace',
              value: controlValue
            };
          }
        }
      }
    }
    if (this.createMode === 'recipe') {
      const fg = this.submitRequestForm.controls['basicFeatures'] as UntypedFormGroup;

      let products = [];
      let boardTypes = [];
      let validationTeams = [];
      if (fg.controls['products'].value && fg.controls['products'].value.length > 0) {
        products = fg.controls['products'].value.map((prod: any) => prod.name);
      }
      if (fg.controls['boardTypes'].value && fg.controls['boardTypes'].value.length > 0) {
        boardTypes = fg.controls['boardTypes'].value.map((prod: any) => prod.name);
      }
      if (fg.controls['validationTeams'].value && fg.controls['validationTeams'].value.length > 0) {
        validationTeams = fg.controls['validationTeams'].value.map((prod: any) => prod.name);
      }

      switch (controlName) {
        case 'recipeName': {
          if (fg.controls['recipeName'].value) {
            if (fg.controls['recipeName'].value !== products.join('_') + '_' + boardTypes.join('_') + '_' + validationTeams.join('_')) {
              if (this.freeTextMode) {
                this.freeTextMode.nativeElement.checked = true;
              }
              this.freeTextModeTempValue = true;
            }
          }
          break;
        }
      }

      if ((products.length > 0) && (boardTypes.length > 0) && (validationTeams.length > 0)) {
        if (!this.freeTextModeTempValue) {
          const newValue = products.join('_') + '_' + boardTypes.join('_') + '_' + validationTeams.join('_');
          fg.controls['recipeName'].setValue(newValue);
          this.updates['recipeName'] =
          {
            path: 'recipeName',
            op: 'replace',
            value: newValue
          };
        }

        if (this.freeTextMode) {
          if (!this.freeTextMode.nativeElement.checked) {
            const newValue = products.join('_') + '_' + boardTypes.join('_') + '_' + validationTeams.join('_');
            fg.controls['recipeName'].setValue(newValue);
            this.updates['recipeName'] =
            {
              path: 'recipeName',
              op: 'replace',
              value: newValue
            };
          }
        }
      } else {
        if (!this.freeTextModeTempValue) {
          fg.controls['recipeName'].setValue('');
        };

        if (this.freeTextMode) {
          if (!this.freeTextMode.nativeElement.checked) {
            fg.controls['recipeName'].setValue('');
          }
        }
      }

    }
    this.checkValidation();
  }

  freeTextModeChanged(value: any) {
    if (value.target.checked === false) {
      if (confirm('Reset recipe name to auto-build and reset current value?')) {
        this.freeTextMode.nativeElement.checked = value.target.checked;
        this.freeTextModeTempValue =  value.target.checked;
        this.onBlur('basicFeatures', 'freeTextMode');
      } else {
        value.target.checked = true;
        this.freeTextMode.nativeElement.checked = true;
        this.freeTextModeTempValue =  true;
      }
    } else {
      this.freeTextMode.nativeElement.checked = value.target.checked;
      this.freeTextModeTempValue =  value.target.checked;
      this.onBlur('basicFeatures', 'freeTextMode');
    }
  }

  public handleEmailAddress(value: string): void {
    const regExp = new RegExp(/^(([^<>()\[\]\\.,;:\s@']+(\.[^<>()\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    this.emailValid = regExp.test(value);
  }

  checkValidation() {
    this.submitRequestForm.updateValueAndValidity();
    localStorage.setItem(this.createMode + '_request_form', JSON.stringify(this.submitRequestForm.getRawValue()));

    this.formSteps['owners'].isValid = (this.submitRequestForm.controls['owners'] as UntypedFormGroup).valid;
    this.formSteps['basicFeatures'].isValid = (this.submitRequestForm.controls['basicFeatures'] as UntypedFormGroup).valid;
    switch (this.createMode) {
      case 'bb': {
        this.formSteps['blockManager'].isValid = (this.submitRequestForm.controls['blockManager'] as UntypedFormGroup).valid;
        this.formSteps['CheckList'].isValid = (this.submitRequestForm.controls['CheckList'] as UntypedFormGroup).valid;
        break;
      }
      case 'recipe': {
        this.formSteps['orderBuildingBlocks'].isValid = (this.submitRequestForm.controls['orderBuildingBlocks'] as UntypedFormGroup).valid;
        break;
      }
    }
  }

  public onOwnerValueChange(value: GraphUsers): void {
    this.onBlur('owners', 'owner');
    this.checkValidation();
  }
  /*
    public onCoownerValueChange(value: GraphUsers[]): void {
      this.coOwnersChanged = true;
      this.coOwners = value;

      const fg = this.submitRequestForm.controls['owners'] as UntypedFormGroup;
      const control = fg.controls['coOwner'] as UntypedFormControl;
      control.setValue(this.coOwners);

      this.selectedCoowner = null;
      this.coownerData = [];
      this.onBlur('owners', 'coOwner');
    }
   */
  public onEmailDistValueChange(value: GraphUsers): void {
    this.selectedEmailDist = value;
  }

  public graphOwnerUsers(requesterInput: string): void {
    if (requesterInput.length >= 3) {
      const validEmailItems = [];
      this.loading['owner'] = true;

      if (requesterInput.indexOf(' ') > 0) {
        const copiedValues = requesterInput.split(' ');

        for (const copiedValue of copiedValues) {
          if (copiedValue.indexOf('@') > 0) {
            const validEmail = copiedValue.replace(/[&\/\\#,+()$~%;'':*?<>{}]/g, '')
            validEmailItems.push(validEmail);
          }
        }
      }
      if (validEmailItems.length > 0) {
        validEmailItems.forEach(email =>
          this.filterSubscription = this.graphUsersGateway.getGroupMembers(['RM_Admins', 'RM_L1_Technicians'])
            .subscribe(ownerData => {
              if (ownerData.length > 0) {
                this.ownerData = ownerData;
                this.selectedOwner = ownerData[0];

                sessionStorage.setItem('Owner', JSON.stringify(ownerData));
              }
            })
        );
      }
      else {
        this.filterSubscription = this.graphUsersGateway.getGroupMembers(['RM_Admins', 'RM_L1_Technicians'])
          .subscribe(ownerData => {
            if (ownerData.length > 0) {
              this.ownerData = ownerData;
              this.selectedOwner = ownerData[0];

              sessionStorage.setItem('Owner', JSON.stringify(ownerData));
            }
          });
      }
    }
  }

  public graphCoownerUsers(requesterInput: string): void {
    if (requesterInput.length >= 3) {
      this.filterSubscription = this.graphUsersGateway.getGroupMembers(['RM_Admins', 'RM_L1_Technicians'])
        .subscribe(coownerData => {
          if (coownerData.length > 0) {
            this.coownerData = coownerData.filter(user => user.displayName.includes(requesterInput));
            this.selectedCoowner = coownerData[0];

            sessionStorage.setItem('coOwner', JSON.stringify(coownerData));
          }
        })
    }
  }

  public graphEmailDistribution(requesterInput: string): void {
    if (requesterInput.length >= 3) {
      const validEmailItems = [];

      if (requesterInput.indexOf(' ') > 0) {
        const copiedValues = requesterInput.split(' ');

        for (const copiedValue of copiedValues) {
          if (copiedValue.indexOf('@') > 0) {
            const validEmail = copiedValue.replace(/[&\/\\#,+()$~%;'':*?<>{}]/g, '')
            validEmailItems.push(validEmail);
          }
        }
      }
      if (validEmailItems.length > 0) {
        validEmailItems.forEach(email =>
          this.filterSubscription = this.graphUsersGateway.getUsers(email, 10, '', true)
            .subscribe(emailDistributionData => {
              if (emailDistributionData.length > 0) {
                this.emailDistributionData = emailDistributionData;
                this.selectedCoowner = emailDistributionData[0];
              }
            })
        );
      }
      else {
        this.filterSubscription = this.graphUsersGateway.getUsers(requesterInput, 10, '', true)
          .subscribe(emailDistributionData => {
            if (emailDistributionData.length > 0) {
              this.emailDistributionData = emailDistributionData;
              this.selectedCoowner = emailDistributionData[0];
            }
          });
      }
    }
  }

  public fillOwner(searchTerm: string): void {

    const fgPropertiesOwners = this.submitRequestForm.controls['owners'] as UntypedFormGroup;
    // if (this.filterSubscription) {
    //   this.filterSubscription.unsubscribe();
    // }

    if (searchTerm) {

      this.filterSubscription = this.graphUsersGateway.getUsers(searchTerm, 1, '', true)
        .subscribe(ownerData => {
          if (ownerData.length > 0) {
            //            this.ownerData = ownerData;
            //            this.selectedOwner = ownerData[0];
            this.loadedItem['owner'] = ownerData[0];
            fgPropertiesOwners.controls['owner'].setValue(ownerData[0]);
            fgPropertiesOwners.controls['owner'].markAsDirty();
            sessionStorage.setItem('owner', JSON.stringify(ownerData[0]));

            this.itemOwner = this.isOwner(this.loadedItem);
            fgPropertiesOwners.updateValueAndValidity();
            this.checkValidation();
          }
        });
    }
  }

  public coOwnerDisabled(itemArgs: { dataItem: any; index: number }): boolean {
    return itemArgs.dataItem.accountEnabled === "False";
  }
  public coOwnerChanged() {
    const fgPropertiesOwners = this.submitRequestForm.controls['owners'] as UntypedFormGroup;
    this.coOwners = fgPropertiesOwners.controls['coOwner'].value;
    if (this.coOwners.length < 3) {
      this.coownerData.forEach(cod => {
        cod.accountEnabled = "True";
      })
    } else {
      this.coownerData.forEach(cod => {
        if (this.coOwners.findIndex((co: any) => (co.id === cod.id)) === -1) {
          cod.accountEnabled = "False";
        }
      })
    }
  }

  public fillCoOwner(searchTerm: string): void {
    if (searchTerm) {
      const fgPropertiesOwners = this.submitRequestForm.controls['owners'] as UntypedFormGroup;

      this.filterSubscription = this.graphUsersGateway.getUsers(searchTerm, 1, '', true).subscribe({
        next: (coownerData) => {
          if (coownerData.length > 0) {
            this.coOwners.push(...coownerData);
            this.coOwners = [...new Map(this.coOwners.map((m) => [m.id, m])).values()]; // 29May2023 WH - fix duplicate co-owner value
            fgPropertiesOwners.controls['coOwner'].setValue(this.coOwners);
            fgPropertiesOwners.controls['coOwner'].markAsDirty();

            this.coOwnerChanged();
          }
        },
        error: (e) => {
          if (e.status === 404) {
            this.notificationService.addWarningNotification(e.error, `Co-Owner with WWID: ${searchTerm} is not a valid co-owner`);
          } else {
            this.notificationService.addErrorNotification(`fillCoOwner(${searchTerm}) - ${this.httpErrorHandler.getHttpError(e.status)}`, e.message);
          }
        },
        complete: () => {
          fgPropertiesOwners.updateValueAndValidity();
          this.checkValidation();
        }
      });
    }
  }

  formStepValid(stepLabel: string) {
    return this.formSteps[stepLabel].IsValid;
  }
  setCurrentStep(isTrue: boolean, key: string) {
    this.formSteps[key].selected = isTrue;
  }


  createPdfFromBlob(pdf: Blob, base64: any) {
    const reader = new FileReader();
    reader.addEventListener('load', () => {
      base64 = reader.result;
    }, false);

    if (pdf) {
      reader.readAsDataURL(pdf);
    }
  }

  createCombinedPdfFromBlob(pdf: Blob) {
    const reader = new FileReader();
    reader.addEventListener('load', () => {
      this.recipeDocBinary = reader.result;
    }, false);

    if (pdf) {
      reader.readAsDataURL(pdf);
    }
  }

  getPdfFileFromControl(control: any) {

    if (control) {
      if (control.text) {
        this.getPdfFile(control.text);
      }
    }
  }

  getFileFromControl(control: any) {

    if (control) {
      if (control.text) {
        this.getFile(control.text);
      }
    }
    this.onBlur('blockManager', 'content');
  }

  getFile(filename: string) {
    if (filename) {
      this.selectedFile = filename;


      (this.submitRequestForm.controls['blockManager'] as UntypedFormGroup).controls['content'].setValue(filename);
      this.base64 = {
        url: this.fileGateway.getUrl('', this.selectedFile),
        httpHeaders: this.createGateway.getHeader().headers,
        withAuthentication: true
      }

      this.isPdfLoading = true;
      this.fileGateway.getFile(this.selectedFile).subscribe({
        next: (data: number | Blob) => {
          if (this.isPdfLoading) {
            if (data instanceof Blob) {
              this.createPdfFromBlob(data, this.base64);
              if (this.docxFileList) {
                const docxIndex = this.docxFileList.findIndex((docName: any) => (docName.text === this.selectedFile.replace(/.pdf/, '.docx')));
                this.selectedDocxFile = '';
                if (docxIndex >= 0) {
                  this.selectedDocxFile = this.docxFileList[docxIndex].text;
                }
              }
            }
          }
          //            this.isPdfLoaded = false;
        },
        error: (err: any) => {
          //            this.isPdfLoaded = false;
          console.log(err);
        }
      });
    }
  }

  getCombinedPdf() {
    // WH 25July2023 - pass bbNumbers as doc separator --
    for (let i = 0; i < this.docList.length; i++){
      if (this.docList[i] === '' || this.docList[i] === undefined) {
        if (this.bbNumbers[i]) {
          this.bbNumbers.splice(i, 1);
        }
      }
    } // --


    if (this.docList.filter(d => d != '').length > 0) {
      const filteredDocs = this.docList.filter(d => d != '');
      if (this.createGateway.header) {
        let recipeId;
        if (!this.loadedItem.id) {
          recipeId = "NA";
        } else {
          recipeId = this.loadedItem.id;
        }
        this.recipeDoc = {
          // url: this.fileGateway.getUrlforFiles('combinedPdfs', filteredDocs), // backup
          // WH 25Jul2023 - pass bbNumbers as doc separator
          // WH 31Oct2023 - pass recipeId, if no value will pass NA e.g. on create recipe
          url: this.fileGateway.getUrlforFiles('combinedPdfs', filteredDocs, this.bbNumbers, recipeId),
          httpHeaders: this.createGateway.getHeader().headers,
          withCredentials: true
        }
        this.isCombinedPdfLoading = true;

      } else {
        this.notificationService.addWarningNotification('Cannot retrieve PDF', 'Unable to retrieve combined PDF. Authentication header unavailable.');

      }
    } else {
      this.isCombinedPdfLoading = false;
    }
  }

  getPdfFile(filename: string) {

    if (filename) {
      this.selectedFile = filename;
      (this.submitRequestForm.controls['blockManager'] as UntypedFormGroup).controls['content'].setValue(filename);
      this.base64 = {
        url: this.fileGateway.getUrl('PDF', this.selectedFile),
        httpHeaders: this.createGateway.getHeader().headers,
        withCredentials: true
      }

      this.isPdfLoading = true;
      //  this.fileGateway.getPdfFile(name).subscribe({
      this.fileGateway.getPdfFile(this.selectedFile).subscribe({
        next: (data) => {
          if (this.isPdfLoading) {
            this.createPdfFromBlob(data, this.base64);
          }
          //            this.isPdfLoaded = false;
        },
        error: (err: any) => {
          //            this.isPdfLoaded = false;
          console.log(err);
        }
      });
    }
  }

  getFiles(): void {
    this.fileGateway.getFiles().subscribe({
      next: (data) => {
        this.fileList = data.filter((doc: any) => doc.includes('.pdf')).map((item: any) => ({ text: item }));
        this.docxFileList = data.filter((doc: any) => doc.includes('.docx')).map((item: any) => ({ text: item }));
      },
      error: (err: any) => {
        console.log('getFiles() error message : ' + err);
      }
    });
  }

  getLoadingKeys() {
    return Object.keys(this.loading);
  }

  public onPanelSelect(event: PanelBarSelectEvent): void {
    this.formSteps[event.item.title].selected = !event.item.selected;
  }

  public onPanelCollapse(event: PanelBarCollapseEvent): void { // WH 23Aug2023 - checklist editor issue
    if (this.checkListEditMode[-1] && event.item.title === 'CheckList') {
      this.checkListEditMode[-1] = !this.checkListEditMode[-1];

      const checkListFG = this.submitRequestForm.controls['CheckList'] as UntypedFormGroup;
      const checkListFA = checkListFG.controls['checkList'] as UntypedFormArray;
      checkListFA.controls.splice(-1);
      this.formSteps['CheckList'].isValid = true;
      // checkListFA.updateValueAndValidity();
    }
  }

  public onPanelExpand(event: PanelBarExpandEvent): void {
    // if (this.checkListEditMode[-1] && event.item.title === 'CheckList') {
    //   this.checkListEditMode[-1] = !this.checkListEditMode[-1];
    //   const checkListFG = this.submitRequestForm.controls['CheckList'] as UntypedFormGroup;
    //   const checkListFA = checkListFG.controls['checkList'] as UntypedFormArray;
    //   checkListFA.controls.splice(-1);
    //   this.formSteps['CheckList'].isValid = true;
    //   // checkListFA.updateValueAndValidity();
    // }
  }

  beforeUpload(data: any) {

  }

  onDocSelected(event: any) {
    // Reset error state
    this.fileTypeError = false;

    const selectedDocs: FileList = event.target.files;
    if (selectedDocs.length > 0) {
      const selectedFileType = selectedDocs[0].type;
      if (
        selectedFileType !== 'application/pdf' &&
        selectedFileType !== 'application/msword' &&
        selectedFileType !== 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
      ) {
        // Reset the file input and display an error message
        this.selectedDoc = null;
        this.fileTypeError = true;
      } else {
        this.selectedDoc = selectedDocs[0];
      }

    } else {
      console.error('Please select only one file.');
    }
  }

  uploadDoc() {
    if (this.selectedDoc) {

      const pdfFound = this.fileList.findIndex(file => file.text === this.selectedDoc?.name) >= 0;
      const docxFound = this.docxFileList.findIndex(file => file.text === this.selectedDoc?.name) >= 0;

      const originalFileName = this.selectedDoc.name;
      const newFileName = this.appendTimestampToFileName(originalFileName);

      const formData = new FormData();

      if (pdfFound || docxFound) { // Duplicate doc found scenario
        if (confirm('File with this name (' + this.selectedDoc.name + ') exists. Rename automatically?')) {
          // Rename the file before appending it to FormData
          const renamedFile = new File([this.selectedDoc], newFileName, { type: this.selectedDoc.type });
          this.selectedDoc = renamedFile;
        }
      }
      // Append the file to FormData
      formData.append('file', this.selectedDoc);

      // Send the FormData to your API endpoint using HttpClient
      this.http.post(this.uploadSaveUrl, formData)
        .subscribe(response => {
          // Handle the response from the server
          console.log(response);
          // Show the doc
          this.uploadComplete(this.selectedDoc);
        });
    } else {
      console.error('Please select a file before uploading.');
    }
  }

  // original upload logic, comment out together with UI
  // public docDupCheck(e: UploadEvent): void {
  //   if (e.files.length === 1) {
  //     const pdfFound = this.fileList.findIndex(file => file.text === e.files[0].name) >= 0;
  //     const docxFound = this.docxFileList.findIndex(file => file.text === e.files[0].name) >= 0
  //     if (pdfFound || docxFound) {
  //       if (!confirm('File with this name (' + e.files[0].name + ') exists. Rename automatically?')) {
  //         e.preventDefault();
  //       }
  //       else {
  //         const file = e.files[0];
  //         const originalFileName = e.files[0].name;
  //         const newFileName = this.appendTimestampToFileName(originalFileName);
  //         e.files[0].name = newFileName;
  //       }
  //     }
  //   } else {
  //     if (e.files.length > 1) {
  //       this.notificationService.addWarningNotification('Too many files selected.','');
  //     }
  //   }
  // }

  appendTimestampToFileName(fileName: string): string {
    const currentDate = new Date();
    const timestamp = `${currentDate.getFullYear()}${(currentDate.getMonth() + 1).toString().padStart(2, '0')}${currentDate.getDate().toString().padStart(2, '0')}_${currentDate.getHours().toString().padStart(2, '0')}${currentDate.getMinutes().toString().padStart(2, '0')}${currentDate.getSeconds().toString().padStart(2, '0')}`;

    const [name, extension] = fileName.split('.');
    const newFileName = `${name}_${timestamp}.${extension}`;

    return newFileName;
  }

  uploadComplete(data: any) {
    //    this.selectedFile = data.files[0].name;
    const fg = this.submitRequestForm.controls['blockManager'] as UntypedFormGroup;
    // fg.controls['content'].setValue(data.files[0].name); // original backup - for kendo-upload
    fg.controls['content'].setValue(data.name); // WH - switch upload element handling

    setTimeout(() => {
      this.getFiles();
    }, 2000);

    // Note: replace because there is always a pdf copy when upload success, point to the pdf
    let filename;
    // filename = data.files[0].name.replace(/.docx/, '.pdf'); // original backup - for kendo-upload
    filename = data.name.replace(/.docx/, '.pdf'); // WH - switch upload element handling
    this.getFile(filename);
    this.uploadMode = false;
    this.selectedDoc = null; // WH - switch upload element handling
    this.onBlur('blockManager', 'content');
    // this.checkValidation();
  }

  editDocument() {
    window.location.href = 'ms-word:ofe|u|' + this.fileGateway.fileUrl + '/' + this.selectedDocxFile;
  }

  cancelDownload() {
    this.selectedFile = '';
    this.isPdfLoading = false;
    const fg = this.submitRequestForm.controls['blockManager'] as UntypedFormGroup;
    fg.controls['content'].reset();
    this.base64 = { url: '' };
    this.checkValidation();
  }

  csvFileSelected(event: SelectEvent) {
    // this.checklistGridLoading = true;
    if (this.loadedItem.checkList.length > 0) {
      if (!confirm('Overwrite current checkList?')) {
        event.preventDefault();
      }
    }
  }

  trimDoubleQuotes(input: string): string {
    console.log(input.charAt(0));
    // Check if the string has double quotes at the first position
    if (input.charAt(0) === '"') {
        input = input.substring(1);
    }

    // Check if the string has double quotes at the last position
    if (input.charAt(input.length - 1) === '"') {
        input = input.substring(0, input.length - 1);
    }

    return input;
}

  uploadCSVComplete(data: any) {
    //    this.selectedFile = data.files[0].name;
    let checkList = data.response.body.map((item: any) => ({ name: item, isRequired: false }));
    // WH 24Aug2023 - filter the empty row
    checkList = checkList.filter((element: any) => {
      return element.name.trim() !== '';
    });
    // trim double quote from item with comma
    checkList = checkList.map((element: any) => {
      console.log(this.trimDoubleQuotes(element.name));
      element.name = this.trimDoubleQuotes(element.name);
      return element;
    });

    checkList.forEach((item: any) => {
        switch (item.name.trim().substring(0, 2)) {
          case 'H:': {
            item.id = crypto.randomUUID();
            item.type = 'Header';
            item.name = item.name.substring(2).trim();
            break;
          }
          case 'R:': {
            item.id = crypto.randomUUID();
            item.type = 'CheckList Item';
            item.name = item.name.substring(2).trim();
            item.isRequired = true;
            break;
          }
          default: {
            item.id = crypto.randomUUID();
            item.type = 'CheckList Item';
          }
        }
    });

    this.loadedItem.checkList = checkList;

    const checkListFG = this.submitRequestForm.controls['CheckList'] as UntypedFormGroup;
    const checkListFA = checkListFG.controls['checkList'] as UntypedFormArray;

    checkList.forEach((item: any) => {
      const checkListFormGroup = this.formBuilder.group({
        id: [item.id],
        name: [item.name, Validators.required],
        type: [item.type, Validators.required],
        isRequired: [item.isRequired]
      });
      checkListFA.controls.push(checkListFormGroup);
    });

    this.showUploadCSV = false;
    this.onBlur('CheckList', 'checkList');

    this.updates['checkList'] =
    {
      path: 'checkList',
      op: 'replace',
      value: this.loadedItem.checkList
    };
  }

  addNewEmailItem() {
    if (!this.loadedItem['emailDistribution']) {
      this.loadedItem['emailDistribution'] = [];
    }

    this.loadedItem['emailDistribution'].push(this.newEmailItem);

    const fg = this.submitRequestForm.controls['basicFeatures'] as UntypedFormGroup;
    fg.controls['emailDistribution'].setValue(this.loadedItem['emailDistribution']);
    this.newEmailItem = '';
    this.checkValidation();
  }

  expandAll() {
    Object.keys(this.formSteps).forEach(key => {
      this.formSteps[key].selected = true;
    });
  }
  collapseAll() {
    Object.keys(this.formSteps).forEach(key => {
      this.formSteps[key].selected = false;
    });
  }

  private closeCheckListEditor(
    grid: GridComponent,
    rowIndex = this.checkListEditedRowIndex
  ): void {
    grid.closeRow(rowIndex);
    this.checkListEditedRowIndex = undefined;
    this.checkListFormGroup = new UntypedFormGroup({});
  }

  public addNewCheckListItem({ sender}: AddEvent) {
    const checkListFa = ((this.submitRequestForm.controls['CheckList']) as UntypedFormGroup).controls['checkList'] as UntypedFormArray;
    this.checkListFormGroup = this.formBuilder.group({
      id: [crypto.randomUUID()],
      name: ['', Validators.required],
      type: ['CheckList Item', Validators.required],
      isRequired: [true],
      saveMode: true
    });

    this.checkListEditMode[-1] = true;
    checkListFa.push(this.checkListFormGroup);
    sender.addRow(this.checkListFormGroup);
    this.formSteps['CheckList'].isValid = false; // WH - fix incorrect checklist validation
  }

  public editCheckListItem({ sender, rowIndex, dataItem }: EditEvent): void {
    this.inEdit = true; // WH 14Sep2023 - add new var to control the Add new button in checklist editor
    this.closeCheckListEditor(sender);
    this.checkListFormGroup = this.createCheckListItemFormGroup(dataItem);
    this.checkListEditedRowIndex = rowIndex;
    sender.editRow(rowIndex, this.checkListFormGroup);
  }

  public cancelCheckListItem({ sender, rowIndex }: CancelEvent): void {
    this.inEdit = false; // WH 14Sep2023 - add new var to control the Add new button in checklist editor
    this.checkListEditMode[rowIndex] = false;
    this.closeCheckListEditor(sender, rowIndex);

    // WH - start - remove empty row added by 'Add New' and fix incorrect checklist validation
    const checkListFG = this.submitRequestForm.controls['CheckList'] as UntypedFormGroup;
    const checkListFA = checkListFG.controls['checkList'] as UntypedFormArray;
    if (rowIndex === -1) {
      checkListFA.controls.splice(-1);
    }
    checkListFA.updateValueAndValidity();
    // WH - end - remove empty row added by 'Add New' and fix incorrect checklist validation
    this.onBlur('CheckList', 'checkList');
  }

  public removeCheckListItem({ dataItem, rowIndex }: RemoveEvent): void {
    if (confirm('Delete Checklist item?')) {
      const index: any = this.loadedItem.checkList.findIndex(
        (item: any) => item.name === dataItem.name
      );
      this.loadedItem.checkList.splice(index, 1);

      const checkListFG = this.submitRequestForm.controls['CheckList'] as UntypedFormGroup;
      const checkListFA = checkListFG.controls['checkList'] as UntypedFormArray;
      checkListFA.controls.splice(index, 1);
      this.onBlur('CheckList', 'checkList');
      //Author: Rajesh
      //Date: 6 July 2023
      //Purpose: The checklist update is currently not reflected as expected. Hence, capture the CL changes and pass it to update API.
      //PR#: 21562
      this.updates['checkList'] =
                    {
                      path: 'checkList',
                      op: 'replace',
                      value: this.loadedItem.checkList
                    };
    }
  }

  addWhereChanged() {
    this.addWhereValue = this.addWhere.dataItem.text;
  }

  public checkListItemDisabled(itemArgs: { dataItem: Item; index: number }) {
    return itemArgs.dataItem.disabled;
  }

  clearCheckList() {
    if (confirm('Clear Checklist?')) {
      const checkListFG = this.submitRequestForm.controls['CheckList'] as UntypedFormGroup;
      const checkListFA = checkListFG.controls['checkList'] as UntypedFormArray;
      this.loadedItem.checkList = [];
      checkListFA.clear();
      this.checkListEditMode = [];
      this.onBlur('CheckList', 'checkList');
    }
  }

  public saveCheckListItem(sa: SaveEvent): void {
    this.inEdit = false; // WH 14Sep2023 - add new var to control the Add new button in checklist editor
    const sender = sa.sender;
    const rowIndex = sa.rowIndex;
    const formGroup = sa.formGroup;
    const isNew = sa.isNew;

    const checkListItem =
    {
      id: formGroup.value.id,
      name: formGroup.value.name,
      isRequired: formGroup.value.isRequired ? true : false,
      type: formGroup.value.type,
    };

    // formGroup.controls['type'].setValue(formGroup.value.type.value);
    // formGroup.value.type = formGroup.value.type.value;

    const checkListFG = this.submitRequestForm.controls['CheckList'] as UntypedFormGroup;
    const checkListFA = checkListFG.controls['checkList'] as UntypedFormArray;

    if (isNew) {
      switch (this.addWhere.dataItem.value) {
        case 'top' : {
          this.loadedItem.checkList.splice(0, 0, checkListItem);
          break;
        }
        case 'before' : {
          this.loadedItem.checkList.splice(this.selectedCheckListRow.index, 0, checkListItem);
          break;
        }
        case 'after': {
          this.loadedItem.checkList.splice(this.selectedCheckListRow.index + 1, 0, checkListItem);
          break;
        }
        case 'bottom': {
          this.loadedItem.checkList.push(checkListItem);
          break;
        }
      }
    } else {
      Object.assign(this.loadedItem.checkList[rowIndex], checkListItem);
      Object.assign(checkListFA.controls[rowIndex], formGroup);
    }

    sender.closeRow(rowIndex);
    this.onBlur('CheckList', 'checkList');

    if (sa.dataItem['saveMode']) {
      const checkListFa = ((this.submitRequestForm.controls['CheckList']) as UntypedFormGroup).controls['checkList'] as UntypedFormArray;
      this.checkListFormGroup = this.formBuilder.group({
        id: crypto.randomUUID(),
        name: ['', Validators.required],
        type: ['CheckList Item', Validators.required],
        isRequired: [true],
        saveMode: [true]
      });
      checkListFa.push(this.checkListFormGroup);
      sender.addRow(this.checkListFormGroup);
      // WH - fix incorrect checklist validation
      if (rowIndex === -1) {
        this.formSteps['CheckList'].isValid = false;
      }

    } else {
      this.checkListEditMode[rowIndex] = false;
    }

    //Author: Rajesh
    //Date: 6 July 2023
    //Purpose: The checklist update is currently not reflected as expected. Hence, capture the CL changes and pass it to update API.
    //PR#: 21562
    this.updates['checkList'] =
    {
      path: 'checkList',
      op: 'replace',
      value: this.loadedItem.checkList
    };
  }

  checkListSelected(selection: any) {
    this.selectedCheckListRow = selection.selectedRows[0];

    this.listItems[2].disabled = false;
    this.listItems[1].disabled = false;
  }

  public move(index: number, moveBy: number) {

    const row1 = {};
    Object.assign(row1, this.loadedItem['checkList'][index + moveBy])
    Object.assign(this.loadedItem['checkList'][index + moveBy], this.loadedItem['checkList'][index]);
    Object.assign(this.loadedItem['checkList'][index], row1);

    const checkListFG = this.submitRequestForm.controls['CheckList'] as UntypedFormGroup;
    const checkListFA = checkListFG.controls['checkList'] as UntypedFormArray;

    const control1 = new UntypedFormGroup({});
    Object.assign(control1, checkListFA.controls[index + moveBy]);
    Object.assign(checkListFA.controls[index + moveBy], checkListFA.controls[index]);
    Object.assign(checkListFA.controls[index], control1);


    this.onBlur('CheckList', 'checkList');


  }

  public moveSelectedRecipeBuildingBlocks(module: any, index: number, moveBy: number) {

    const row1 = {};
    Object.assign(row1, this.selectedRecipeBuildingBlocks[module][index + moveBy])
    Object.assign(this.selectedRecipeBuildingBlocks[module][index + moveBy], this.selectedRecipeBuildingBlocks[module][index]);
    Object.assign(this.selectedRecipeBuildingBlocks[module][index], row1);

    const orderBuildingBlocksFG = this.submitRequestForm.controls['orderBuildingBlocks'] as UntypedFormGroup;
    const buildingBlocksFA = orderBuildingBlocksFG.controls['buildingBlocks'] as UntypedFormArray;
    buildingBlocksFA.clear();
    buildingBlocksFA.clearValidators();

    let orderNumber = 0;
    this.selectedRecipeModules.forEach((mod: any) => {
      const bbs = this.selectedRecipeBuildingBlocks[mod]
      bbs.forEach((bb: any) => {
        let bbFormGroup: UntypedFormGroup = this.formBuilder.group({
          buildingBlockId: [bb.id, Validators.required],
          moduleName: [mod, Validators.required],
          order: [orderNumber, Validators.required]
        });
        buildingBlocksFA.push(bbFormGroup);
        orderNumber++;
      });
    });
    orderBuildingBlocksFG.updateValueAndValidity();
    // this.onBlur('orderBuildingBlocks', 'buildingBlocks'); // WH 21Aug2023 - comment out because onBBReOrder() will run the same
    this.onBBReOrder(true); // WH 21Aug2023 - add this fx call to refresh combined doc sequence
    this.showChecklist(); // WH 21Aug2023 - refresh checklist preview when reorder BB sequence
  }

  public rowCallback = (context: RowClassArgs) => {
    if (!context.dataItem.name || !context.dataItem.type) {
      return { required: true };
    }

    switch (context.dataItem.type) {
      case 'Header':
        return { header: true };
      default:
        return {};
    }
  };

  public siliconProgramChanged(value: any, clearProductValue = true) {

    // const basicFeaturesFG = this.submitRequestForm.controls['basicFeatures'] as UntypedFormGroup;
    // const siliconProgram = basicFeaturesFG.controls['siliconProgram'] as UntypedFormControl;

    // const selection = siliconProgram.value;

    // WH test START - multi silicon program enhancement
    // let productResults: any[] = [];
    // if (value.length > 0) {
    //   value.map((element: any) => {
    //     console.log(element);
    //     const matchingItems = [...this.masterProductsList.filter((item) => item.siliconProgramName == element)];
    //     matchingItems.forEach((product) => {
    //       if (product) {
    //         productResults.push({ name: product.productName});
    //       }
    //     })
    //   });
    //   this.rmProducts = productResults;
    // }
    // WH test END

    // ORI - single silicon program
    const filteredList = [...this.masterProductsList.filter(item => item.siliconProgramName == value)];
    this.rmProducts = filteredList.map((item: any) => { return { name: item.productName } }); //, isArchived: !item.isArchived ? false : true
    // ORI
    if (clearProductValue) {
      (this.submitRequestForm.controls['basicFeatures'] as UntypedFormGroup).controls['products'].reset();
    }

    this.onBlur('basicFeatures', 'products');
  }

  public listItemDisabled(item: ItemArgs) {
    return item.dataItem.isArchived; // disable the 3rd item
  }

  //Lindy - export checklist excel
  public exportExcel() {

    const getCheckList = this.loadedItem.checkList;
    let csvData = '';

    getCheckList.forEach((item: any) => {
      if (item.type.toLowerCase() === 'header') {
        item.checkListCombine = 'H:' + item.name;
      }
      else if (item.type.toLowerCase() === 'checklist item') {
        if (item.isRequired == true) {
          item.checkListCombine = 'R:' + item.name;
        }
        else {
          item.checkListCombine = item.name;
        }
      }
      else {
        console.error("error");
        return;
      }
      const escapedField = `"${item.checkListCombine.replace(/"/g, '""')}"`;
      csvData += `${escapedField}\n`;
    });

    //CSV download
    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.setAttribute('download', 'checklist.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

  }

  /*   public onRemoveCoOwner(e: ChipRemoveEvent): void {
      console.log('Remove event arguments: ', e);
      const index = this.coOwners.findIndex(co => co.displayName === e.sender.label);
      this.coOwners.splice(index, 1);

      const fg = this.submitRequestForm.controls['owners'] as UntypedFormGroup;
      const control = fg.controls['coOwner'] as UntypedFormControl;
      control.setValue(this.coOwners);

      this.onBlur('owners', 'coOwner');
    } */

  public onRemoveEmailDist(e: ChipRemoveEvent): void {
    console.log('Remove event arguments: ', e);
    const index = this.loadedItem['emailDistribution']
      .indexOf(e.sender.label);
    this.loadedItem['emailDistribution'].splice(index, 1);
    this.updates['emailDistribution'] = // WH - 22Aug2023 fix remove email recipient not capture by save
    {
      path: 'emailDistribution',
      op: 'replace',
      value: this.loadedItem.emailDistribution? this.loadedItem.emailDistribution: []
    };
  }

  public buttonClearCache() {
    this.clearBB2RecipeCache();
    this.clearCache();
  }
  public clearCache() {
    localStorage.removeItem(this.createMode + '_request_form');
    window.location.reload();
  }
  public clearBB2RecipeCache() {
    sessionStorage.removeItem('recipe_request_form');
    sessionStorage.setItem("IsRedirectFromView", "0");
    //this.submitRequestForm.reset();
    this.selectedRecipeModules = [];
    this.recipeDoc.url = '';
    if(this.formSteps.length>0){
      this.formSteps['orderBuildingBlocks'].isValid = false;
      this.formSteps['documentPreview'].isValid = false;
      this.formSteps['orderBuildingBlocks'].isSelected = false;
      this.formSteps['documentPreview'].isSelected = false;
    }
  }

  public activeModuleSelectionChange(item: any) {
    this.selectedActiveModule = item;
  }

  public getJoinedValues(values: any) {
    return values.map((item: any) => item.name).join(', ');
  }

  public async moduleCheckChange(value: any, module: any) {

    let foundAM = -1;
    if (module.name) {
      foundAM = this.rmActiveModules.findIndex((rm: any) => (rm.name.toLowerCase() === module.name.toLowerCase()));
    }
    if (foundAM >= 0) {
      this.rmActiveModules[foundAM].selected = value.target.checked;

      if (value.target.checked) {
        this.selectedRecipeModules.push(module.name);
        //this.recipeModuleExpanded[module.name] = false;
        //this.expandedModulePanel[module.name] = false;
        this.recipeModuleExpanded[module.name] = true;
        this.expandedModulePanel[module.name] = true;

        const bb$ = this.searchGateway.getBuildingBlocks({ module: module.name, isDraft: false, isTemplate: false, isArchived: false });
        const bbList = lastValueFrom(bb$);
        const result = (await bbList);
        const newResult: any[] = [...result];
        for (const bb of newResult) {
          bb.products = this.getJoinedValues(bb.products);
          bb.validationTeams = this.getJoinedValues(bb.validationTeams);
          bb.labs = this.getJoinedValues(bb.labs);
          bb['selected'] = false;

          if (bb.isTemplate && !this.containsBBTemplate) {
            this.loadedItem.isTemplate = true;
            this.containsBBTemplate = true;
            this.notificationService.addWarningNotification('Template contains Building Block Template', 'Update with non-template BB.');
          }
        }
        this.preSelectedBuildingBlocks[module.name] = [...newResult];

      } else {
        // uncheck module scenario
        const foundRM = this.selectedRecipeModules.findIndex((rm: any) => (rm === module.name));
        if (foundRM > -1) {
          // WH - remove selected BB also when module of the BB is unselect, prevent reselect module BB show not selected issue
          if (this.selectedRecipeBuildingBlocks[module.name]) {
            // console.log(this.selectedRecipeBuildingBlocks); // check before
            this.selectedRecipeBuildingBlocks[module.name].splice(0); // WH - remove all BB under this unselected module
            this.onBBReOrder(false); // WH 26July2023 - fix after module changed, combinedPdf not refreshing
            // console.log(this.selectedRecipeBuildingBlocks); // check after
          }
          this.selectedRecipeModules.splice(foundRM, 1);
        }
      }
      this.selectedRecipeModules = this.selectedRecipeModules.slice(0);
      this.updateRecipeModuleForm();
      this.showChecklist(); // WH - show checklist preview

      this.checkRecipeEmptyBB(); // WH 12Sep2023 - prevent user create or save recipe without bb
    }
    this.onBlur('orderBuildingBlocks', 'modules');
  }

  updateRecipeModuleForm() {
    //   let modules = ((this.submitRequestForm.controls['orderBuildingBlocks'] as UntypedFormGroup).controls['modules']) as  UntypedFormArray;
    (((this.submitRequestForm.controls['orderBuildingBlocks'] as UntypedFormGroup).controls['modules']) as UntypedFormArray).clearValidators();
    (((this.submitRequestForm.controls['orderBuildingBlocks'] as UntypedFormGroup).controls['modules']) as UntypedFormArray) = new UntypedFormArray([]);

    let orderNumber = 0;
    this.selectedRecipeModules.forEach((rm: any) => {
      const recipeModuleGroup = this.formBuilder.group({
        name: new UntypedFormControl(rm, Validators.required),
        order: new UntypedFormControl(orderNumber, Validators.required),
      });

      (((this.submitRequestForm.controls['orderBuildingBlocks'] as UntypedFormGroup).controls['modules']) as UntypedFormArray).push(recipeModuleGroup);
      orderNumber++;
    });
    this.submitRequestForm.controls['orderBuildingBlocks'].updateValueAndValidity();
  }

  public bbCheckChange(value: any, moduleName: string, index: any) {
    this.preSelectedBuildingBlocks[moduleName][index].selected = value.target.checked;
    const selectedBB = this.preSelectedBuildingBlocks[moduleName][index];

    if (value.target.checked) {
      if (!this.selectedRecipeBuildingBlocks[moduleName]) {
        this.selectedRecipeBuildingBlocks[moduleName] = [];
      }
      this.selectedRecipeBuildingBlocks[moduleName].push(selectedBB);
      if (selectedBB.isTemplate) {
        this.containsBBTemplate = true;
        this.loadedItem.isTemplate = true;
      }
    } else {
      const foundRM = this.selectedRecipeBuildingBlocks[moduleName].findIndex((bb: any) => (bb.id === selectedBB.id));
      if (foundRM > -1) {
        this.selectedRecipeBuildingBlocks[moduleName].splice(foundRM, 1);
      }
    }

    //    this.selectedRecipeModules = this.selectedRecipeModules.slice(0);
    this.selectedRecipeBuildingBlocks[moduleName].slice(0);

    this.onBBReOrder(true);
    this.showChecklist(); // WH - show checklist preview

    // WH 12Sep2023 - prevent user create or save recipe without bb
    if (this.selectedRecipeBuildingBlocks[moduleName].length === 0) {
      this.formSteps['orderBuildingBlocks'].isValid = false;
      this.submitRequestForm.controls['orderBuildingBlocks'].setErrors({'incorrect': true});
      this.alertDialogTitle = 'BB Selection'
      this.alertDialogMessage = 'Please select at least one Building Block (BB) in order to create/save Recipe. (Uncheck the module if no BB is selected)';
      this.alertDialogOpen = true;
    }
  }

  updateRecipeBBForm() {
    // let buildingBlocks = (((this.submitRequestForm.controls['orderBuildingBlocks'] as UntypedFormGroup).controls['buildingBlocks']) as  UntypedFormArray);
    (((this.submitRequestForm.controls['orderBuildingBlocks'] as UntypedFormGroup).controls['buildingBlocks']) as UntypedFormArray).clearValidators();
    (((this.submitRequestForm.controls['orderBuildingBlocks'] as UntypedFormGroup).controls['buildingBlocks']) as UntypedFormArray) = new UntypedFormArray([]);

    let orderNumber = 0;
    this.selectedRecipeModules.forEach((module) => {
      if (this.selectedRecipeBuildingBlocks[module]) {
        this.selectedRecipeBuildingBlocks[module].forEach((bb: any) => {
          const recipeModuleGroup = this.formBuilder.group({
            buildingBlockId: new UntypedFormControl(bb.id, Validators.required),
            order: new UntypedFormControl(orderNumber, Validators.required),
            moduleName: new UntypedFormControl(bb.module, Validators.required),
            checkList: new UntypedFormControl(bb.checkList)
          });
          (((this.submitRequestForm.controls['orderBuildingBlocks'] as UntypedFormGroup)
            .controls['buildingBlocks']) as UntypedFormArray).push(recipeModuleGroup);
          orderNumber++;
        });
      }
    });
    this.submitRequestForm.controls['orderBuildingBlocks'].updateValueAndValidity();

  }

  // WH 26July2023 - pass bbNumbers as doc separator
  showChecklist() {
    this.checklistList = [];
    this.selectedRecipeModules.forEach((module) => {
      if (this.selectedRecipeBuildingBlocks[module]) {
        // this.checklistList = this.checklistList.concat(this.selectedRecipeBuildingBlocks[module].map(
        //     (bb: any) => (bb.checkList)
        //   ));
        this.checklistList.push({
          moduleName: module,
          bb: this.selectedRecipeBuildingBlocks[module].map((bb:any) => bb)
          // bbNumber: this.selectedRecipeBuildingBlocks[module].map((bb:any) => bb.buildingBlockNumber),
          // bbChecklist: this.selectedRecipeBuildingBlocks[module].map((bb:any) => bb.checkList)
          // bbList: {
            // bbNumber: (this.selectedRecipeBuildingBlocks[module].map(
            //   (bb: any) => (bb.buildingBlockNumber)
            // )),
            // checklist: (this.selectedRecipeBuildingBlocks[module].map(
            //   (bb: any) => (bb.checkList)
            // ))
          // }
        });
      }
    });
  }

  public onBBReOrder(runBlur: boolean) {

    this.updateRecipeBBForm();
    this.docList = [];
    this.bbNumbers = [];
    let orderNumber = 0;
    let newModulesList: any[] = [];
    this.selectedRecipeModules.forEach((module) => {
      if (this.selectedRecipeBuildingBlocks[module]) {
        this.docList = this.docList.concat(this.selectedRecipeBuildingBlocks[module].map((block: any) => (block.content)));
        this.bbNumbers = this.bbNumbers.concat(this.selectedRecipeBuildingBlocks[module].map((bb: any) => (bb.buildingBlockNumber)));
      }
      newModulesList.push({ name: module, order: orderNumber })
      orderNumber++ // WH - missing module order increment
    });
    this.loadedItem.modules = newModulesList;
    this.getCombinedPdf();
    this.showChecklist(); // WH 21Aug2023 - refresh checklist preview when reorder BB sequence

    this.updates['modules'] = // WH 21Aug2023 - module sequence update is currently not reflected as expected. Hence, capture the CL changes and pass it to update API.
    {
      path: 'modules',
      op: 'replace',
      value: this.loadedItem.modules
    };

    this.checkRecipeEmptyBB();

    if (runBlur) {
      this.onBlur('orderBuildingBlocks', 'buildingBlocks');
    }
  }

  reloadPage() {
    window.location.reload();
  }

  isOwner(itemResult: any): boolean {
    let retValue = false;
    if (this.currentUser) {
      const isItemOwner = (itemResult.owner.wwid != undefined)?(itemResult.owner.wwid === this.currentUser.jobTitle):(itemResult.owner.jobTitle === this.currentUser.jobTitle);
      //const isItemOwner = (itemResult.owner.jobTitle === this.currentUser.jobTitle);

      let isItemCoOwner = false;
      if (itemResult.coOwner) {
        isItemCoOwner = (itemResult.coOwner.findIndex((co: any) => co.wwid === this.currentUser?.jobTitle) >= 0);
      }
      retValue = isItemOwner || isItemCoOwner;
    }
    return retValue;
  }

  public productOpen(): void {
    this.productOpened = true;
  }
  public productClose(): void {
    if (this.speedProductSelected) {
      this.createProduct();
    }
    this.productOpened = false;
  }

  public productValueChange(value: any): void {
    this.productKeyword = value;

    // If user selected option from returned programs list, then populate the products drop-down.
    if (this.productKeyword) {
      // Retrieve products data based on program IDs.

      this.thirdPartyAppGateway.getProducts([value], true).pipe(takeUntil(this.destroy$)).subscribe({
        next: (result) => {
          this.productsList = JSON.parse(JSON.stringify(result));
        },
        error: (e) => {
          alert('Products Search Error\n\n' + e.error?.toString());
        }
      });
    }
  }

  public programsValueChange(value: any): void {
    this.programsKeyword = value.trim();

    // If user selected option from returned programs list, then populate the products drop-down.
    if (this.programsList.length > 0 && this.programsList.find(element => element.programName.toUpperCase() == this.programsKeyword.toUpperCase()) != undefined) {
      // Get all program IDs first.
      let products: any[] = [];
      this.programsList.filter(element => element.programName.toUpperCase() == this.programsKeyword.toUpperCase()).forEach(item => {
        products.push(item.programID);
      });

      this.speedProductSelected = '';
      // Retrieve products data based on program IDs.
      this.thirdPartyAppGateway.getProducts(products, true).pipe(takeUntil(this.destroy$)).subscribe({
        next: (result) => {
          this.productsList = JSON.parse(JSON.stringify(result)).map((item: any) => ({productID: item.productID, productName: item.productName, siliconProgramName: this.programsKeyword}));
        },
        error: (e) => {
          alert('Products Search Error\n\n' + e.error?.toString());
        }
      });
    }
    else {
      this.productsList = [];
      this.searchPrograms();
    }
  }

  public searchPrograms(): void {
    this.programsAutoCompleteIsLoading = true;
    this.thirdPartyAppGateway.getSiliconPrograms(this.programsKeyword, true).pipe(takeUntil(this.destroy$)).subscribe({
      next: (result) => {
        let programs: any[] = [];
        this.programsList = JSON.parse(JSON.stringify(result));
        this.programsList.forEach(item => {
          programs.push(item.programName);
        });
        this.programNamesList = programs.filter((n, i) => programs.indexOf(n) === i).sort();
      },
      error: (e) => {
        this.programsAutoCompleteIsLoading = false;
        alert('Programs Search Error\n\n' + e.error?.toString());
      },
      complete: () => {
        this.programsAutoCompleteIsLoading = false;
        this.programsAutoComplete.toggle();
      }
    });
  }

  public programsClose(): void {
    this.programsOpened = false;
  }

  public programsAdd(): void {
    if (this.speedProductSelected) {
      this.createProduct();
    }
    this.programsOpened = false;
  }

  public programsOpen(): void {
    this.programsOpened = true;
    this.speedProductSelected = {};
  }

  private createProduct(): void {
    const item: any = {};
    item.createdBy = new Person();
    item.createdBy = {
      name: this.currentUser?.displayName ? this.currentUser?.displayName : '',
      email: this.currentUser?.mail ? this.currentUser?.mail : '',
      wwid: this.currentUser?.jobTitle ? this.currentUser?.jobTitle : ''
    };
    item.application = this.rmAppName;

    item.productId = this.speedProductSelected.productID;
    item.productName = this.speedProductSelected.productName;
    item.siliconProgramName = this.speedProductSelected.siliconProgramName;

    this.createGateway.createProduct(item, true).pipe(takeUntil(this.destroy$)).subscribe({
      next: async (result) => {
        const p$ = this.searchGateway.getProducts(true);
        const pList = await lastValueFrom(p$);
        this.masterProductsList = pList;
        this.rmSiliconPrograms = [... new Set(pList.map(item => (item.siliconProgramName)))].sort((a, b) => (a < b ? -1 : 1));
        this.loadedItem.siliconProgram = result.siliconProgramName;

        const fgSp = this.submitRequestForm.controls['basicFeatures'] as UntypedFormGroup;
        const formControlActualSp = fgSp.get('siliconProgram');
        formControlActualSp?.setValue(result.siliconProgramName);

        this.loadedItem.products = [{name: result.productName, isArchived: false}];

        const fg = this.submitRequestForm.controls['basicFeatures'] as UntypedFormGroup;
        const formControlActual = fg.get('products');
        formControlActual?.setValue(this.loadedItem.products);
        formControlActual?.updateValueAndValidity();
      },
      error: (e) => {
        alert('Create Product Error\n\n' + e.error?.toString());
      },
      complete: () => {
      }
    });
  }

  public searchBoardType(): void {
    this.boardPBAIdHasError = false;
    this.isBoardTypeSearchButtonDisabled = true;
    // this.boardPBAIdValue = this.boardPBAIdSearchTextBoxValue;
    // this.boardTypeNameSelected = '';

    this.loading['speedBoardTypes'] = true;
    this.thirdPartyAppGateway.getBoardTypes(this.boardPBAIdSearchTextBoxValue, true).pipe(takeUntil(this.destroy$)).subscribe({
      next: (result) => {
        const btIndex = this.rmBoardTypes.findIndex(bt => bt.name === result.boardTypeName);
        if (btIndex < 0) {
          this.speedBoardTypeNameSelected = JSON.parse(JSON.stringify(result)).boardTypeName;
        } else {
          this.speedBoardTypeNameSelected = '';
          this.boardPBAIdHasError = true;
          this.boardPBAIdErrorMessage = "Board Type already exists in Recipe Management";
        }
      },
      error: (e) => {
        this.isBoardTypeSearchButtonDisabled = false;
        if (e.error?.status !== 404) {
          this.notificationService.addErrorNotification('Board Type Search Error', e.error?.toString());
        }
        else {
          this.boardPBAIdHasError = true;
          this.boardPBAIdErrorMessage = "This PBA ID was not found.";
          this.loading['speedBoardTypes'] = false;
        }
      },
      complete: () => {
        this.isBoardTypeSearchButtonDisabled = false;
        this.loading['speedBoardTypes'] = false;
      }
    });
  }

  public boardPBAIdTextBoxOnChange(value: string): void {
    this.boardPBAIdSearchTextBoxValue = value;
  }

  public boardTypeClose(): void {
    if (this.speedBoardTypeNameSelected) {
      this.createBoardType();
    }
    this.boardTypeOpened = false;
  }

  public boardTypeOpen(): void {
    this.speedBoardTypeNameSelected = '';
    this.boardTypeOpened = true;
  }

  private createBoardType(): void {
    const item: any = {};
    item.createdBy = new Person();
    item.createdBy = {
      name: this.currentUser?.displayName ? this.currentUser?.displayName : '',
      email: this.currentUser?.mail ? this.currentUser?.mail : '',
      wwid: this.currentUser?.jobTitle ? this.currentUser?.jobTitle : ''
    };
    item.application = this.rmAppName;

    item.boardPBAId = this.boardPBAIdSearchTextBoxValue;
    item.boardTypeName = this.speedBoardTypeNameSelected;

    this.createGateway.createBoardType(item, true).pipe(takeUntil(this.destroy$)).subscribe({
      next: async (result) => {
        const bt$ = this.searchGateway.getBoardTypes(true);
        const btList = lastValueFrom(bt$);
        this.rmBoardTypes = (await btList).map((formItem: any) => { return { name: formItem.boardTypeName, isArchived: !formItem.isArchived ? false : true } });
        if (!this.loadedItem.boardTypes || this.loadedItem.boardTypes.length === 0) {
          this.loadedItem.boardTypes = [];
        }
        this.loadedItem.boardTypes.push({name: result.boardTypeName, isArchived: false});

        const fg = this.submitRequestForm.controls['basicFeatures'] as UntypedFormGroup;
        const formControlActual = fg.get('boardTypes');
        formControlActual?.setValue(this.loadedItem.boardTypes);
        formControlActual?.updateValueAndValidity();
      },
      error: (e) => {
        this.notificationService.addErrorNotification('Create new Board Type Error', e.statusText);
      },
      complete: () => {
      }
    });
  }

  collapseAllRecipeModules() {
    for(let srm in this.selectedRecipeModules) {
      //this.recipeModuleExpanded[srm] = false;
      this.recipeModuleExpanded[srm] = true;
    }
  }

  public alertDialogClose() {
    // this.alertDialogOpen = false;
    if (this.toRefresh) {
      this.toRefresh = false;
      this.reloadPage();
    } else {
      this.alertDialogOpen = false;
    }
  }

  public alertDialogAction(refresh?: boolean) {
    // this.alertDialogOpen = false;
    if (this.toRefresh) {
      this.toRefresh = false;
      this.reloadPage();
    } else {
      this.alertDialogOpen = false;
    }
  }

  public checkRecipeEmptyBB() { // WH 12Sep2023 - prevent user create or save recipe without bb
    console.log(this.selectedRecipeModules);
    if (this.selectedRecipeModules.length > 0) {
      let count = 0;
      this.selectedRecipeModules.map((moduleName:string) => {
          if(!this.selectedRecipeBuildingBlocks[moduleName] || this.selectedRecipeBuildingBlocks[moduleName].length === 0) {
            count++;
          }
        });

        if (count > 0) {
        this.formSteps['orderBuildingBlocks'].isValid = false;
        this.submitRequestForm.controls['orderBuildingBlocks'].setErrors({'incorrect': true});
        } else {
        this.formSteps['orderBuildingBlocks'].isValid = true;
        }
    } else {
      this.formSteps['orderBuildingBlocks'].isValid = false;
      this.submitRequestForm.controls['orderBuildingBlocks'].setErrors({'incorrect': true});
    }
  }

  // WH - 03Oct2023 - handle sortChange
  recipeModuleGridSortChange(event: any, moduleName: string) {
      const sortDir: boolean = event[0].dir === 'desc'? true: false;
      const sortField = event[0].field;
      // console.log(this.preSelectedBuildingBlocks[moduleName]);
      this.sortArrayOfObjectByPropertyName(this.preSelectedBuildingBlocks[moduleName], sortField, sortDir);
  }

  // WH - 03Oct2023 - generic fx for array sort
  private sortArrayOfObjectByPropertyName(items: any[], propertyName: string, isDescendingOrder: boolean = false) {
    items.sort((a: any, b: any) => {
        if (a[propertyName] < b[propertyName]) {
            return isDescendingOrder ? 1 : -1;
        }
        if (a[propertyName] > b[propertyName]) {
            return isDescendingOrder ? -1 : 1;
        }
        return 0;
    });
  }

  // WH - 03Oct2023 - handle filterChange
  async recipeModuleGridFilterChange(filter: CompositeFilterDescriptor, moduleName: string) {
    // console.log(this.preSelectedBuildingBlocks[moduleName]);
    // console.log('selectedRecipeBuildingBlocks length: ' + this.selectedRecipeBuildingBlocks[moduleName].length);
    // console.log(this.selectedRecipeBuildingBlocks[moduleName]);
    // console.log('preSelectedBuildingBlocks length: ' + this.preSelectedBuildingBlocks[moduleName].length);

    if(filter.filters.length === 0) { // if clear filter, reload the preSelectedBuildingBlocks
      const getBuildingBlocksResult$ = this.searchGateway.getBuildingBlocks({ module: moduleName, isTemplate: false, isDraft: false, isArchive: false });
      const getBuildingBlocksFinal = await lastValueFrom(getBuildingBlocksResult$);
      getBuildingBlocksFinal.forEach((bb: any) => {
        bb.products = this.getJoinedValues(bb.products);
        bb.validationTeams = this.getJoinedValues(bb.validationTeams);
        bb.labs = this.getJoinedValues(bb.labs);
        // get the checked row and restore them
        const loadedBBIndex = this.selectedRecipeBuildingBlocks[moduleName].findIndex((rbb: any) => bb.id === rbb.id);
        bb['selected'] = (loadedBBIndex >= 0);
        if (bb['selected']) {
          this.preSelectedBuildingBlocks[moduleName].map((each: any) => {
            if (each.id === bb.id) {
              each['selected'] = bb['selected'];
            }
          })
        }
      });
      this.preSelectedBuildingBlocks[moduleName] = getBuildingBlocksFinal;
    } else {
      this.preSelectedBuildingBlocks[moduleName] = filterBy(this.preSelectedBuildingBlocks[moduleName], filter);
    }
  }
}


